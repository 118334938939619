import { useState, useEffect } from 'react';
import axios from '../../../../utilis/axios';
import moment from 'moment'


const Earnings = () => {

  const [invoices, setInvoices] = useState([]);
  const [totalEarning, setTotalEarning] = useState()

  useEffect(() => {
    fetchInvoices();
  }, [])

  const fetchInvoices = async () => {
    try {
      const res = await axios.get('/api/instructor/invoices');
      // console.log(res.data)
      if (res.data) {
        setInvoices(res.data)
        setTotalEarning(res.data.reduce((a, b) => a + parseInt(b.amount), 0))
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card shadow-sm border">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 class="text-uppercase text-muted mb-2">Total</h6>
                  <span class="h2 mb-0">${totalEarning}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="card shadow-sm border">
        <div className="card-header">
          <h4 class="card-header-title">
            Payout
          </h4>
        </div>
        <div className="table-responsive">
          <table class="table table-sm table-nowrap card-table">
            <thead>
              <tr>
                {/* <th>Invoice ID</th> */}
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody class="font-size-base">
              {invoices.map(item => (
                <EarningRow
                  // invoiceId = {item.receipt_id}
                  date={moment(item.date).format('Do MMM YY')}
                  amount={item.amount}
                  status={item.status}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Earnings;


const EarningRow = ({ invoiceLink, invoiceId, date, amount, status }) => {
  return (
    <tr>
      {/* <td><div>{invoiceId}</div></td> */}
      <td>{date}</td>
      <td>${amount}</td>
      <td><span class="badge badge-secondary">{status}</span></td>
    </tr>
  )
}