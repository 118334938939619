import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import axios from '../../../../utilis/axios';
import cogoToast from 'cogo-toast'
import Swal from 'sweetalert2'
import moment from 'moment'

const DeleteSession = ({show, setShow, data, sessions, setSessions}) => {
    
    const [loading1, setLoading1] = useState(false);
    const [comment, setComment] = useState('')

    const deleteSession = async () => {
        const response = await Swal.fire({
            title: 'Are you sure you want to delete the session',
            text: 'This action cannot be undone, once deleted',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if(response.isConfirmed){
            try{
                setLoading1(true)
                await axios.delete(`/api/admin/session/${data._id}`, {comment});
                const newSessions = [...sessions];
                const index = newSessions.findIndex(item => item._id === data._id);
                newSessions.splice(index, 1)
                setSessions(newSessions)
                
                cogoToast.success("Meeting successfully deleted")
                setShow(false);
                setLoading1(false)
                
            } catch(err){
                setLoading1(false)
                console.log(err);
            }
        }
    }
    
    
    
    
    const deleteCompletedSession = async () => {
        const response = await Swal.fire({
            title: 'Are you sure you want to delete the session',
            text: 'This action cannot be undone, once deleted',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if(response.isConfirmed){
            try{
                setLoading1(true)
                const res = await axios.delete(`/api/admin/session/completed/${data._id}`, {comment});
                console.log(res.data)
                const newSessions = [...sessions];
                const index = newSessions.findIndex(item => item._id === data._id);
                if(!res.data){
                    newSessions.splice(index, 1)
                } else {
                    newSessions[index] = res.data
                }
                setSessions(newSessions)
                cogoToast.success("Meeting successfully deleted")
                setShow(false);
                setLoading1(false)
                
            } catch(err){
                setLoading1(false)
                console.log(err);
            }
        }
    }

    return (
        <Modal show = {show} onHide = {() => setShow(false)} centered>
            <div className = 'modal-content' >
                <div className = 'modal-body '>
                <div class = 'close pointer' onClick = {() => setShow(false)}>&times;</div>
                    <div class = 'mb-4 text-center px-lg-5 '>
                        <div className = 'h2 mb-1'>Delete Session</div>
                    </div>

                    <div class = 'form-group mt-4'>
                        <label>Comments</label>
                        <textarea className = 'form-control' value = {comment} onChange = {e => setComment(e.target.value)}/>
                    </div>

                    {(data.status.value === 'scheduled' && moment(data.date_time).diff(moment(), 'hours') > 0) && <div class = "">
                        <button disabled = {loading1} class = "btn btn-block btn-outline-danger mt-3" onClick = {(e) => deleteSession(e)}>
                            {loading1 && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                            Delete Session
                        </button>
                    </div>}

    
                    {(data.status.value === 'completed' || moment(data.date_time).diff(moment(), 'hours') < 0) && <div class = "">
                        <button disabled = {loading1} class = "btn btn-block btn-outline-danger mt-3 mb-4" onClick = {(e) => deleteCompletedSession(e)}>
                            {loading1 && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                            Delete Completed Session
                        </button>
                    </div>}

                </div>
            </div>
        </Modal>
    )
}

export default DeleteSession


