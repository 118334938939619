import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import Swal from 'sweetalert2'
import axios from 'axios';
import cogoToast from 'cogo-toast';
import moment from 'moment';


const Enrollment = ({show, setShow, enrollmentId, enrollments, setEnrollments, setSelectedEnrollmentEdit, setShowEdit}) => {

    const [enrollment, setEnrollment] = useState()
    const [paymentDetails, setPaymentDetails] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const index = enrollments.findIndex(item => item._id === enrollmentId)
        setEnrollment(enrollments[index])
    }, [enrollmentId, enrollments, show])

    useEffect(() => {
        if(enrollment && enrollment.payment_details) setPaymentDetails(enrollment.payment_details)
    }, [enrollment])



    const editEnrollment = () => {
        setSelectedEnrollmentEdit(enrollmentId)
        setShowEdit(true)
        setShow(false)
    }



    const deleteEnrollment = async () => {
        try{
            const response = await Swal.fire({
                title: 'Are you sure you want to delete the enrollment',
                text: 'This action cannot be undone. Once deleted, all the scheduled meeting and requests will also be deleted.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            if(response.isConfirmed){
                setLoading(true)
                await axios.delete(`/api/admin/enrollment/${enrollmentId}`)
                cogoToast.success("Enrollment successfully deleted")
                const newEnrollments = [...enrollments];
                const index = newEnrollments.findIndex(item => item._id === enrollmentId);
                newEnrollments.splice(index, 1);
                setEnrollments(newEnrollments);
                setShow(false);
                setLoading(false)
            }
        } catch(err){
            setLoading(false)
            
        }
    }
    
    
 
    

 
    return (
        <RightModal show = {show} setShow = {setShow}>
            {(enrollment && enrollment.student) && <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2'>Enrollment - {enrollment.enrollmentId}</div>
                </div>

                <div class = "row mt-5">
                    <div class = "col-5 small">Student:</div>
                    <div class = "col-7">{enrollment.student.firstName + ' ' + enrollment.student.lastName}</div>
                </div>
                <div class = "row mt-4">
                    <div class = "col-5 small">Instructor:</div>
                    <div class = "col-7">{enrollment.instructor.firstName + ' ' + enrollment.instructor.lastName}</div>
                </div>
                <div class = "row mt-4">
                    <div class = "col-5 small">Instrument:</div>
                    <div class = "col-7">{enrollment.course.instrument}</div>
                </div>
                
                <div class = "row mt-4">
                    <div class = "col-5 small">Cordinator:</div>
                    <div class = "col-7">{enrollment.cordinator}</div>
                </div>

                {/* {enrollment.type === 'paid' && <div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Total Payment:</div>
                        <div class = "col-7">{enrollment.totalPaid1}</div>
                    </div>
                </div>} */}

                <div class = "mt-5">
                    <div class = "h2">Payment Details</div>
                </div>

                {(enrollment.payment_details && paymentDetails) && enrollment.payment_details.filter(item => item.status !== 'not-paid').map(item => (
                    <PaymentDetails 
                        details = {item} 
                    />
                ))}


                <div>
                    <div class='h2 mt-6'>Comment History</div>
                    <ul>
                        {enrollment?.comments?.map((item, index) => <li key = {index}>{item}</li>)}
                    </ul>
                </div>


            
                <div class = "row mt-5">
                    <div class = "col-6">
                        <div class = "btn btn-block btn-outline-primary" onClick = {() => editEnrollment()}>Edit</div>
                    </div>
                    <div class = "col-6">
                        <div class = "btn btn-block btn-primary" onClick = {() => deleteEnrollment()}>
                        {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                            Delete
                        </div>
                    </div>
                </div>
            </div>}
        </RightModal>
    )
}

export default Enrollment;



const PaymentDetails = ({details}) => {

  
    return (
        <div class = 'card'>
            <div class = 'card-body pb-2'>

                {keyMetrics.map((item, index) => <div className = 'row mb-3' key = {index}>
                    <div className = 'col-7 small'>
                        {item.label}
                    </div>
                    <div className = 'col-5 text-right small'>
                        { item.label =="Payment Date" ? moment(details[item.value]).format("DD-MM-YYYY hh:mm a") : details[item.value]}
                    </div>
                </div>)}
            </div>
        </div>
    )
}







// const getKeyValues = (data) => {
//     return {
//         price_per_class: data.price_per_class,
//         num_classes: data.num_classes,
//         classes_completed: data.classes_completed,
//         classes_left: data.classes_left,
//         classes_scheduled: data.classes_scheduled,
//         classes_requested: data.classes_requested,
//         classes_late_charged: data.classes_late_charged,
//     }
// }


const keyMetrics = [
    {label: 'Payment ID', value: 'receipt'},
    {label: 'Session Bought', value: 'num_classes'},
    {label: 'Session Left', value: 'classes_left'},
    {label: 'Session Completed', value: 'classes_completed'},
    {label: 'Session Scheduled', value: 'classes_scheduled'},
    {label: 'Session Requested', value: 'classes_requested'},
    {label: 'Session Cancelled', value: 'classes_canceled'},
    {label: 'Session Late Charged', value: 'classes_late_charged'},
    {label: 'Complimentary Classes', value: 'complimentary_sessions'},
    {label: 'Total Price', value: 'total_price'},
    {label: 'Price per class', value: 'price'},
    {label: 'Status', value: 'status'},
    {label: 'Pending Amount', value: 'pending_amount'},
    {label: 'Payment Date', value: 'payment_date'},
]






/*
import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import Swal from 'sweetalert2'
import axios from 'axios';
import cogoToast from 'cogo-toast';


const Enrollment = ({show, setShow, data, enrollments, setEnrollments}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [enrollment, setEnrollment] = useState(getKeyValues(data))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        setEnrollment(getKeyValues(data))
        // eslint-disable-next-line 
    }, [show, isEditing])

    useEffect(() => {
        setIsEditing(false);
    }, [show])

    useEffect(() => {
        setError(false)
    }, [enrollment])

    const validate = () => {
        let newError = false
        if(enrollment.price_per_class < 0) newError = true
        if(enrollment.num_classes < 0) newError = true
        if(enrollment.classes_completed < 0) newError = true
        if(enrollment.classes_left < 0) newError = true
        if(enrollment.classes_scheduled < 0) newError = true
        if(enrollment.classes_requested < 0) newError = true
        if(enrollment.classes_late_charged < 0) newError = true
        setError(newError)
        return newError
    }


    const deleteEnrollment = async () => {
        if(validate()) return
        try{
            const response = await Swal.fire({
                title: 'Are you sure you want to delete the enrollment',
                text: 'This action cannot be undone. Once deleted, all the scheduled meeting and requests will also be deleted.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            if(response.isConfirmed){
                setLoading(true)
                await axios.delete(`/api/admin/enrollment/${data._id}`)
                cogoToast.success("Enrollment successfully deleted")
                const newEnrollments = [...enrollments];
                const index = newEnrollments.findIndex(item => item._id === data._id);
                newEnrollments.splice(index, 1);
                setEnrollments(newEnrollments);
                setShow(false);
                setLoading(false)
            }
        } catch(err){
            setLoading(false)
            
        }
    }
    
    
    const saveChanges = async () => {
        if(validate()) return
        try{
            const response = await Swal.fire({
                title: 'Are you sure you want to edit the enrollment',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, edit it!'
            });
            if(response.isConfirmed){
                setLoading(true)
                await axios.put(`/api/admin/enrollment/${data._id}`, enrollment)
                cogoToast.success("Enrollment successfully edited")
                const newEnrollments = [...enrollments];
                const index = newEnrollments.findIndex(item => item._id === data._id);
                newEnrollments[index].price_per_class = enrollment.price_per_class
                newEnrollments[index].num_classes = enrollment.num_classes
                newEnrollments[index].classes_completed = enrollment.classes_completed
                newEnrollments[index].classes_left = enrollment.classes_left
                newEnrollments[index].classes_scheduled = enrollment.classes_scheduled
                newEnrollments[index].classes_requested = enrollment.classes_requested
                newEnrollments[index].classes_late_charged = enrollment.classes_late_charged
                setEnrollments(newEnrollments);
                setLoading(false)
                setShow(false)
            }
        } catch(err){
            setLoading(false)
            
        }
    }
    

 
    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2'>Enrollment - {data.enrollmentId}</div>
                </div>

                <div class = "row mt-5">
                    <div class = "col-5 small">Student:</div>
                    <div class = "col-7">{data.student.firstName + ' ' + data.student.lastName}</div>
                </div>
                <div class = "row mt-4">
                    <div class = "col-5 small">Instructor:</div>
                    <div class = "col-7">{data.instructor.firstName + ' ' + data.instructor.lastName}</div>
                </div>
                <div class = "row mt-4">
                    <div class = "col-5 small">Instrument:</div>
                    <div class = "col-7">{data.course.title}</div>
                </div>

                {data.type === 'paid' && <div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Total Payment:</div>
                        <div class = "col-7">{data.totalPaid1}</div>
                    </div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Price Per Class:</div>
                        <div class = "col-7">
                            {isEditing ? <input class="form-control form-control-sm" type = "number" value ={enrollment.price_per_class} onChange = {e => setEnrollment(prev => ({...prev, price_per_class: e.target.value}))}/> : 
                            data.price_per_class1}
                        </div>
                    </div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Class Bought:</div>
                        <div class = "col-7">
                            {isEditing ? <input class="form-control form-control-sm" type = "number" value ={enrollment.num_classes} onChange = {e => setEnrollment(prev => ({...prev, num_classes: e.target.value}))}/> : 
                            data.num_classes}
                        </div>
                    </div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Class Completed:</div>
                        <div class = "col-7">
                            {isEditing ? <input class="form-control form-control-sm" type = "number" value ={enrollment.classes_completed} onChange = {e => setEnrollment(prev => ({...prev, classes_completed: e.target.value}))}/> : 
                            data.classes_completed}
                        </div>
                    </div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Class Left:</div>
                        <div class = "col-7">
                            {isEditing ? <input class="form-control form-control-sm" type = "number" value ={enrollment.classes_left} onChange = {e => setEnrollment(prev => ({...prev, classes_left: e.target.value}))}/> : 
                            data.classes_left}
                        </div>
                    </div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Class Scheduled:</div>
                        <div class = "col-7">
                            {isEditing ? <input class="form-control form-control-sm" type = "number" value ={enrollment.classes_scheduled} onChange = {e => setEnrollment(prev => ({...prev, classes_scheduled: e.target.value}))}/> : 
                            data.classes_scheduled}
                        </div>
                    </div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Class Requested:</div>
                        <div class = "col-7">
                            {isEditing ? <input class="form-control form-control-sm" type = "number" value ={enrollment.classes_requested} onChange = {e => setEnrollment(prev => ({...prev, classes_requested: e.target.value}))}/> : 
                            data.classes_requested}
                        </div>
                    </div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Class Late Charged:</div>
                        <div class = "col-7">
                            {isEditing ? <input class="form-control form-control-sm" type = "number" value ={enrollment.classes_late_charged} onChange = {e => setEnrollment(prev => ({...prev, classes_late_charged: e.target.value}))}/> : 
                            data.classes_late_charged}
                        </div>
                    </div>
                </div>}

                {error && <div class = 'd-block invalid-feedback mt-5 mb-n3'>You cant enter negative values</div>}

                
                {isEditing ? <div class = "row mt-5">
                    <div class = "col-6">
                        <div class = "btn btn-block btn-outline-primary" onClick = {() => setIsEditing(false)}>Cancel</div>
                    </div>
                    <div class = "col-6">
                        <div class = "btn btn-block btn-primary" onClick = {() => saveChanges()}>
                            {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                            Save
                        </div>
                    </div>
                </div> : <div class = "row mt-5">
                    <div class = "col-6">
                        <div class = "btn btn-block btn-outline-primary" onClick = {() => setIsEditing(true)}>Edit</div>
                    </div>
                    <div class = "col-6">
                        <div class = "btn btn-block btn-primary" onClick = {() => deleteEnrollment()}>
                        {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                            Delete
                        </div>
                    </div>
                </div>}
            </div>
        </RightModal>
    )
}

export default Enrollment;







const getKeyValues = (data) => {
    return {
        price_per_class: data.price_per_class,
        num_classes: data.num_classes,
        classes_completed: data.classes_completed,
        classes_left: data.classes_left,
        classes_scheduled: data.classes_scheduled,
        classes_requested: data.classes_requested,
        classes_late_charged: data.classes_late_charged,
    }
}*/