import React, { useState, useEffect, useContext } from 'react'
import AnimateHeight from 'react-animate-height'
import { Link } from 'react-router-dom'
import { VscAccount } from 'react-icons/vsc'
import { useHistory } from 'react-router-dom'
import { Store } from '../../../store'
import { fetchUser, logoutSuccess } from '../../../store/actions/auth'
import { logoutUser } from '../../../services/auth'
import { BiBell } from 'react-icons/bi'
import moment from 'moment'
import axios from '../../../utilis/axios'
import NavMobile from './NavMobile'
import { AiOutlineWhatsApp } from 'react-icons/ai'
// import MelodyLogo from './../../../assets/MelodyMentorsLogo.svg'
import MelodyLogo from '../../../assets/MelodyMentorsLogo.svg'



const Nav = (props) => {

  const { state } = useContext(Store)
  const history = useHistory();
  const [pathname, setPathname] = useState(window.location.pathname);
  const [width, setWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname)
      if (window.innerWidth > 991 && collapsed === true) setCollapsed(false)
      else if (window.innerWidth < 990 && collapsed === false) setCollapsed(true);
    })
    // eslint-disable-next-line
  }, [history])

  useEffect(() => {
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth)
  }, [])

  const checkWidth = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (width > 991 && collapsed === true) setCollapsed(false)
    else if (width < 990 && collapsed === false) setCollapsed(true)
    // eslint-disable-next-line
  }, [width])

  return (
    <>
      <div class="mr-20 mb-20 d-flex align-items-center hover1" style={{ position: 'fixed', right: '10px', bottom: '10px', zIndex: '100000000' }}>
        <div class="bg-light px-2 py-2 mr-2 hover-show border shadow" style={{ borderRadius: '40px' }}>Chat with us</div>
        {!pathname.includes('dashboard') && <a href="https://wa.me/918045683666" target="_blank" rel="noreferrer" className="btn btn-circle shadow" style={{ padding: '0.5rem', backgroundColor: 'rgb(37, 211, 102)' }}>
          <AiOutlineWhatsApp class="display-3 mb-0 text-white tooltip-on-hover" />
        </a>}
      </div>
      <div class='d-block d-lg-none'>
        <NavMobile />
      </div>
      <div className='navbar navbar-expand-lg navbar-light d-none d-lg-block' style={{ zIndex: '100' }} id="top">
        <div className="container px-lg-5">
          <button class="navbar-toggler mr-auto collapsed" type="button" onClick={() => setCollapsed(prev => !prev)}>
            <span class="navbar-toggler-icon"></span>
          </button>
          <Link to="/" class="navbar-brand mr-lg-5 d-lg-none my-auto mx-auto d-block" >
          {/* <img src={MelodyLogo} alt="" class='navbar-brand-img' style={{ maxHeight: '46px' }} /> */}
          <img src="https://dhunguru-static-data-logos.s3.ap-south-1.amazonaws.com/logo.png" alt="" class='navbar-brand-img' style={{ maxHeight: '46px' }} />
          </Link>
          {(state.user && state.user.isLoggedIn) ? (state.user.data.role === 'student' ? <StudentNav /> : (state.user.data.role === 'instructor' ? <InstructorNav /> : <AdminNav />)) : <SignInNav />}
          <div class={`navbar-collapse mr-lg-auto order-lg-first collapse show`}>
            <AnimateHeight height={collapsed ? '0' : 'auto'} duration={300}>
              <ul class="navbar-nav mr-lg-auto mt-2 pt-3 pt-lg-0 mt-lg-0">
                <Link to="/" class="navbar-brand mr-lg-5 d-lg-inline-block my-auto mx-auto d-none" >
                  <img src="https://dhunguru-static-data-logos.s3.ap-south-1.amazonaws.com/logo.png" alt="" class='navbar-brand-img' />
                  {/* <img src={MelodyLogo} alt="Logo" style={{ height:'46px' }}  class='' /> */}
                </Link>
                <li class="nav-item">
                  <Link class={`nav-link px-lg-3 ${pathname === '/' ? 'text-primary' : ''}`} to="/" >Home</Link>
                </li>
                <li class="nav-item">
                  <Link class={`nav-link px-lg-3 ${pathname === '/instructors' ? 'text-primary' : ''}`} to="/instructors" >Find your guru</Link>
                </li>
                <li class="nav-item">
                  <Link class={`nav-link px-lg-3 ${pathname.includes('how-it-works') ? 'text-primary' : ''}`} to="/how-it-works">How it works</Link>
                </li>
                {(state.user && state.user.data.role === 'student') ? '' : <li class="nav-item">
                  <Link class={`nav-link px-lg-3 ${pathname.includes('become-a-guru') ? 'text-primary' : ''}`} to="/become-a-guru">Become a Guru</Link>
                </li>}

                {(state.user && state.user.data.role === 'student') && <li class="nav-item dropdown">
                  <Link class={`nav-link px-lg-3 ${pathname.includes('my-dashboard') ? 'text-primary' : ''}`} to="/my-dashboard">My Dashboard</Link>
                  <ul class="dropdown-menu">
                    <li><Link to="/my-dashboard/schedule" class="dropdown-item ">Manage Your Sessions</Link></li>
                    {(state.user && state.user.data.role === 'student' && state.user.data.isRecordingAllowed) && <li><Link to="/my-dashboard/classes" class="dropdown-item ">Session Recordings</Link></li>}
                    <li><Link to="/my-dashboard/resources" class="dropdown-item ">Find Your Notes</Link></li>
                    <li><Link to="/my-dashboard/messages" class="dropdown-item ">Messages</Link></li>
                    <li><Link to="/my-dashboard/billing" class="dropdown-item ">Get More Sessions</Link></li>
                    <li><Link to="/my-dashboard/refer-a-friend" class="dropdown-item ">Refer Your Friends</Link></li>
                  </ul>
                </li>}
              </ul>
            </AnimateHeight>
          </div>
        </div>
      </div>
    </>
  )
}

export default Nav;



const StudentNav = () => {


  const { state, dispatch } = useContext(Store);
  const handleLogout = async () => {
    try {
      logoutSuccess(dispatch);
      await logoutUser();
    } catch (e) {
      console.error(e);
    }
  }


  return (
    <div className="navbar-user ml-auto">
      <div className="dropdown mr-4 d-none d-lg-flex">
        <div className="navbar-user-link show-notif" role="button" >
          <span class={`icon ${state.user.data.notifications.filter(item => !item.isRead).length > 0 && 'active'} pointer`}>
            <BiBell className='h2 mb-0' />
          </span>
          <NotificationDropDown />
        </div>
      </div>
      <div className="mr-3 text-muted">
        Hi {state.user.data.firstName}
      </div>
      <div className="dropdown">
        <Link to="/my-dashboard/profile" class="avatar avatar-sm dropdown-toggle" role="button" >
          <img src={state.user.data.avatar} alt="..." class="avatar-img rounded-circle" />
        </Link>
        <div className="dropdown-menu dropdown-menu-right">
          <Link to="/my-dashboard/profile" class="dropdown-item">Profile</Link>
          <Link to="/my-dashboard/security" class="dropdown-item">Security</Link>
          <Link to="/my-dashboard/invoices" class="dropdown-item">Invoices</Link>
          <Link to="/my-dashboard/notifications" class="dropdown-item">Notifications</Link>
          <Link to="/my-dashboard/refer-a-friend" class="dropdown-item">Refer Your Friends</Link>
          <hr class="dropdown-divider" />
          <div className="dropdown-item pointer" onClick={handleLogout}>Logout</div>
        </div>
      </div>
    </div>
  )
}


const InstructorNav = () => {
  const { state, dispatch } = useContext(Store);
  const handleLogout = async () => {
    try {
      logoutSuccess(dispatch);
      await logoutUser();
    } catch (e) {
      console.error(e);
    }
  }


  return (
    <div className="navbar-user ml-auto">
      <Link to="/instructor" class="btn btn-primary btn-sm mr-4">
        Open Dashboard
      </Link>
      <div className="dropdown mr-4 d-none d-lg-flex">
        <div className="navbar-user-link show-notif" role="button" >
          <span class={`icon ${state.user.data.notifications.filter(item => !item.isRead).length > 0 && 'active'} pointer`}>
            <BiBell className='h2 mb-0' />
          </span>
          <NotificationDropDown />
        </div>
      </div>
      <div className="dropdown">
        <Link to="/" class="avatar avatar-sm dropdown-toggle" role="button" >
          <img src={state.user.data.avatar} alt="..." class="avatar-img rounded-circle" />
        </Link>
        <div className="dropdown-menu dropdown-menu-right">
          <Link to="/instructor/account/profile" class="dropdown-item">Profile</Link>
          <Link to="/instructor/account/notifications" class="dropdown-item">Notifications</Link>
          <hr class="dropdown-divider" />
          <div className="dropdown-item pointer" onClick={handleLogout}>Logout</div>
        </div>
      </div>
    </div>)
}



const AdminNav = () => {


  return (
    <div className="navbar-user ml-auto">
      <Link to="/admin" class="btn btn-primary btn-sm mr-4">
        Open Dashboard
      </Link>
    </div>)
}


const SignInNav = () => {
  return (
    <div className="navbar-user ml-auto">
      <div className="dropdown mr-3 d-none d-lg-flex">
        <Link to="/login" class="btn  btn-outline-primary">
          Login
        </Link>
      </div>
      <div className="">
        <Link to="/signup" class="btn  btn-primary d-flex align-items-center" >
          <VscAccount className='h3 mr-2 mb-0' />
          Sign up
        </Link>
      </div>
    </div>
  )
}



const NotificationDropDown = () => {

  const { state, dispatch } = useContext(Store);
  const [notifications, setNotifications] = useState(sampleNotif)

  useEffect(() => {
    setNotifications(state.user.data.notifications.sort((a, b) => new Date(b.date) - new Date(a.date)))
  }, [state.user.data.notifications])


  const readingNotifications = async () => {
    try {
      const newUser = { ...state.user.data, notifications: state.user.data.notifications.map(item => ({ ...item, isRead: true })) }
      fetchUser(newUser, dispatch)
      await axios.post('/api/notifications/read')

    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div class={`dropdown-menu dropdown-menu-right dropdown-menu-card show-notif-hover`} onMouseEnter={() => readingNotifications()}>
      <div className="card-header">
        <h5 class="card-header-title">Notifications</h5>
        {/* <a href="#!" class="small">View all</a> */}
      </div>
      <div className="card-body">
        <div className="list-group list-group-flush list-group-activity my-n3" >
          {notifications.map(item => <Link to={item.url} class="list-group-item text-reset" >
            <div className="row">
              <div className="col-auto">
                <div className="avatar avatar-sm">
                  <img src={item.avatar} alt="..." class="avatar-img rounded-circle" />
                </div>
              </div>
              <div className="col ml-n2">
                <div className="small">
                  {item.description}
                </div>
                <small class="text-muted">{moment(item.date).fromNow()}</small>
              </div>
            </div>
          </Link>)}
        </div>
      </div>
    </div>
  )
}


const sampleNotif = [{
  title: '',
  url: '',
  time: '',
  avatar: '',
}]