import React, {useState, useRef, useContext} from 'react'
import {Store} from '../../../../store'
import DashboardLayout from '../index'
import DetailedProfile from './AdditionalInfo'
import Documents from './Documents'
import Basics from './Basics'
import axios from '../../../../utilis/axios'
import { saveInstructorProfile, fetchUser } from '../../../../store/actions/auth'
import cogoToast from 'cogo-toast'
import instructor_content from '../../../../content/instructor_content.json'
import { cleanPhone } from '../../../../utilis/validation'

const Profile = (props) => {

    const avatarRef = useRef()
    const {state, dispatch} = useContext(Store)
    const [tab, setTab] = useState('basics')
    const [profile, setProfile] = useState({...state.user.data.instructor_profile})
    const [user, setUser] = useState({phone: state.user.data.phone, firstName: state.user.data.firstName, lastName: state.user.data.lastName, title: state.user.data.title, countryCode: state.user.data.countryCode, country: state.user.data.country});
    const [documents, setDocuments] = useState({aadhaar: [], education: [], panCard: [], cancelledCheque: []})
    const [loading, setLoading] = useState('')


    const saveProfile = async () => {
        try{
            setLoading(true);
           
            if(avatarRef.current && avatarRef.current.files.length > 0){
                const formData = new FormData();
                formData.append('avatar', avatarRef.current.files[0]);
                const res = await axios.post('/api/instructor/profile/avatar/', formData)
                fetchUser({...state.user.data, avatar: res.data}, dispatch)
            }
            
            const profile1 = {...profile}
            delete profile1.documents
            const res = await axios.put(`/api/instructor/profile/user`, {profile: profile1, user: {...user, phone: cleanPhone(user.phone)}})
            saveInstructorProfile({...state.user.data, ...user}, res.data.profile, dispatch)
            cogoToast.success(instructor_content.profile.toast.success)

            setLoading(false);

        } catch(err){
            console.log(err)
            setLoading(false)
            throw err;
        }
    }


    const uploadFiles = async () => {
        try{
            setLoading(true);
            const formData = new FormData();
            formData.append('aadhaar', documents.aadhaar[0])  
            formData.append('education', documents.education[0])  
            formData.append('panCard', documents.panCard[0])  
            formData.append('cancelledCheque', documents.cancelledCheque[0])  
            const res = await axios.post(`/api/instructor/profile/upload/doucments/`, formData)
            saveInstructorProfile(state.user.data, res.data, dispatch)
            cogoToast.success(instructor_content.profile.toast.files)
            setLoading(false);

        } catch(err){
            console.log(err)
            setLoading(false);
        }
    }
 

    return (
        <DashboardLayout showTitle = {false} {...props}>
            <div className = 'mt-4 px-4 px-lg-0'>
                <div className = 'row justify-content-center'>

                    <div className = 'col-12 col-lg-10 col-xl-8'>
                        <div className = 'header mt-md-5'>
                            <div className = 'header-body'>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 class="header-pretitle">Overview</h6>
                                        <h1 class="header-title">My Profile</h1>
                                    </div>
                                </div>
                                <AccountNav tab = {tab} setTab = {setTab}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className = "row justify-content-center px-4 px-lg-0">
                <div className = 'col-12 col-lg-10 col-xl-8'>
                    {tab === 'detailed_profile' && <DetailedProfile 
                        profile = {profile}
                        loading = {loading}
                        setProfile = {setProfile}
                        saveProfile = {saveProfile}
                    />}
                    {tab === 'basics' && <Basics 
                        profile = {profile}
                        loading = {loading}
                        setProfile = {setProfile}
                        saveProfile = {saveProfile}
                        avatarRef = {avatarRef}
                        user = {user}
                        setUser = {setUser}
                    />}
                    {tab === 'documents' && <Documents 
                        loading = {loading}
                        documents = {documents}
                        setDocuments = {setDocuments}
                        uploadFiles = {uploadFiles}
                        profile = {profile}
                    />}
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Profile




const AccountNav = ({tab, setTab}) => {
    return(
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'basics' && 'active'}`} onClick = {() => setTab('basics')}>Basics</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'detailed_profile' && 'active'}`} onClick = {() => setTab('detailed_profile')}>Additional Info</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'documents' && 'active'}`} onClick = {() => setTab('documents')}>Documents</div>
                    </li>
                </ul>
        </div>
    </div>
    )
}


