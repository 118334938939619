import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'
import { BeatSpinner2 } from '../../../../components/Spinners/BeatSpinner'
import moment from 'moment'
import DiscountDetails from './DiscountDetails'
import CreateDiscount from './CreateDiscount'

const Discounts = (props) => {

    const [discounts, setDiscounts] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [showDiscount, setShowDiscount] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedDiscount, setSelectedDiscount] = useState()

    useEffect(() => {
        fetchDiscounts()
        // eslint-disable-next-line
    }, [])


    const fetchDiscounts = async () =>{
        try{

            const res = await axios.get('/api/admin/discounts');
            console.log(res.data)
            formatData(res.data)

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data) => {
        setDiscounts(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(item => ({
            ...item,
            discountCode: item.discountCode,
            amount: item.discountType === 'PERCENT' ? item.percentOff + '%' : '$' + item.amountOff,
            requirement: item.discountType === 'PERCENT' ?  '$' + item.maximumAmountAllowed : '$' + item.minimumAmountRequired,
            discountType1: {value: item.discountType, label: <div class={`badge badge-${item.discountType === 'AMOUNT' ? 'success' : 'danger'}`}>{item.discountType}</div>},
            appliesTo1: {value: item.appliesTo, label: item.appliesTo === "USER" ? item.user.firstName + ' ' + item.user.lastName : 'Everyone'},
            status1: {value: item.status, label: <div class={`badge badge-${item.status === 'active' ? 'success' : 'danger'}`}>{item.status}</div>},
            redeemBy1: {value: moment(item.redeemBy).unix(), label: moment(item.redeemBy).format('Do MMM YY, hh:mm A')},
            numApplied1: item.numApplied + '/' + item.maxRedemptions,
        })))  
    }

   

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
            <div className = 'row align-items-center mt-5 border-bottom pb-3 mb-4'>
                <div className = 'col'>
                    <h3 class = 'h1'>All Discount Codes</h3>
                </div>
                <div className = 'col-auto'>
                    <div className = 'btn btn-primary' onClick = {() => setShow(true)}>Create new code</div>
                </div>
            </div>
            {discounts ? <Table 
                data = {discounts}
                isSelectable = {false}
                headers = {headers}
                isSearchable = {true}
                handleRowClick = {(id) => {
                    setSelectedDiscount(discounts.filter(item => item._id === id)[0]); 
                    setShowDiscount(true)
                }}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
            /> : <BeatSpinner2 />}

            {selectedDiscount && <DiscountDetails 
                show = {showDiscount}
                setShow = {setShowDiscount}
                data = {selectedDiscount}
                discounts = {discounts}
                setDiscounts = {setDiscounts}
            />}
            {show && <CreateDiscount 
                show = {show}
                setShow = {setShow}
                discounts = {discounts}
                setDiscounts = {setDiscounts}
                formatData = {formatData}
            />}


        </AdminDashboard>
    )
}

export default Discounts




const headers = [
    {value: 'discountCode', active: true, label: 'Discount Code'},
    {value: 'discountType1', active: true, label: 'Type'},
    {value: 'status1', active: true, label: 'Status'},
    {value: 'appliesTo1', active: true, label: 'Applies To'},
    {value: 'redeemBy1', active: true, label: 'Redeem by'},
    {value: 'amount', active: true, label: 'Amount'},
    {value: 'requirement', active: true, label: 'Requirement'},
    {value: 'numApplied1', active: true, label: '# Applied'},
]


