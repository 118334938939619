import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BiUpArrow } from 'react-icons/bi'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { MdEmail } from 'react-icons/md'
import { validateEmail } from '../../../utilis/validation'
import cogoToast from 'cogo-toast'
import submitResponse from '../../../utilis/submitResponse'
import { HashLink } from 'react-router-hash-link';
import MelodyLogo from '../../../assets/MelodyMentorLogoWhite.svg'

const Courses2 = [
  { id: "Guitar", title: "Guitar" },
  { id: "Piano", title: "Piano" },
  { id: "Violin", title: "Violin" },
  { id: "Drums", title: "Drums" },
  { id: "Flute", title: "Flute" },
]



const LgFooter = () => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [email, setEmail] = useState('');

  useEffect(() => {
    setError(false)
  }, [email])

  const subscribeNow = async () => {
    try {
      if (!validateEmail(email)) {
        setError(true);
        return;
      }
      setLoading(true);
      await submitResponse({ email: email, type: 'SUBSCRIBE' })
      setEmail('')
      cogoToast.success("Thank you for subscribing")
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err)
    }
  }

  return (
    <footer class="cs-footer pt-sm-5 pt-6  " style={{ backgroundColor: '#292C37' }}>
      <div className="container py-5 px-5 px-lg-0">
        <div className="row justify-items-center">
          <div className="col-lg-3 col-md-7 col-sm-6 order-lg-1 order-1 mb-lg-0 mb-4">
            <Link to="/" class="d-inline-block mb-2" >
              <img src="https://dhunguru-static-data-logos.s3.ap-south-1.amazonaws.com/logo_white.svg" width="100" alt="Dhunguru" />
              {/* <img src={MelodyLogo} alt="Logo" style={{ width: '200px', margin:'0px', padding:'0px' }}  class='' /> */}
            </Link>
            <p class=" font-size-xs text-light opacity-60">
              A music revolution, born to break geographical barriers
            </p>
            <div className="row no-gutters mb-sm-4 mb-3 pb-lg-3">
              <div class='col-auto'>
                <a class="btn btn-sm btn-rounded-circle btn-white">
                  <a class="fa fa-facebook" target="_blank" href="https://www.facebook.com/DhunGuru-100468982239735"></a>
                </a>
              </div>
              <div class='col-auto mx-2'>
                <a class="btn btn-sm btn-rounded-circle btn-white">
                  <a class="fa fa-instagram" target="_blank" href="https://instagram.com/dhunguru?utm_medium=copy_link"></a>
                </a>
              </div>
              <div class='col-auto'>
                <a class="btn btn-sm btn-rounded-circle btn-white">
                  <a class="fa fa-linkedin" target="_blank" href="https://www.linkedin.com/company/76812496/admin/"></a>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12 col-6 order-lg-2 order-sm-4 order-1 mb-lg-0 mb-4">
            <h3 class="h6 mb-2 pb-1 text-uppercase text-light">Site map</h3>
            <ul class="nav nav-light flex-lg-column flex-sm-row flex-column">
              <li class="nav-item mb-2">
                <Link to="/about" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">About</Link>
              </li>
              <li class="nav-item mb-2">
                <Link to="/how-it-works" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">How it works</Link>
              </li>
              <li class="nav-item mb-2">
                <Link to="/become-a-guru" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">Become a Guru</Link>
              </li>
              <li class="nav-item mb-2">
                <Link to="/faqs" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">FAQs</Link>
              </li>
              <li class="nav-item mb-2">
                <HashLink to="/legal/ml#tnc-students" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">Terms of use Music Learning</HashLink>
              </li>
              {/* <li class="nav-item mb-2">
                <HashLink to="/legal/mt#tnc-clients" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">Terms of use Music Therapy</HashLink>
              </li> */}
              <li class="nav-item mb-2">
                <HashLink to="/legal#privacy" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">Privacy policy</HashLink>
              </li>
              <li class="nav-item mb-2">
                <Link to="/refund-policy" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">Refund Policy</Link>
              </li>
              <li class="nav-item mb-2">
                <Link to="/contact" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-12 col-6 order-lg-3 order-sm-5 order-2 mb-lg-0 mb-4">
            <h3 class="h6 mb-2 pb-1 text-uppercase text-light">Subjects/Instruments</h3>
            <ul class="nav nav-light flex-lg-column flex-sm-row flex-column">
              {Courses2.map(item => <li class="nav-item mb-2">
                <Link to={{ pathname: `/instructors/`, query: item.id }} class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">{item.title}</Link>
              </li>)}
              <li class="nav-item mb-2">
                <Link to="/instructors" class="text-white mr-lg-0 mr-sm-4 p-0 font-weight-normal">more</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-6 order-lg-5 order-sm-2 order-4 mb-sm-0 mb-4">
            <h3 class="h6 mb-2 pb-1 text-uppercase text-light">Sign up to our newsletter</h3>
            <form class="d-none d-lg-block">
              <div className="input-group-overlay input-group-light form-group mb-2 pb-1">
                <input class={`form-control appended-form-control ${error && 'is-invalid'}`} type="text" placeholder="Email address*" value={email} onChange={e => setEmail(e.target.value)} />
                <div class='invalid-feedback'>Please enter a valid email address</div>
                <div className="input-group-append-overlay">
                  <span class="input-group-text">
                    <div onClick={() => subscribeNow()} class="btn btn-link bt-sm px-0 mt-n3" type="submit">
                      {!loading ? <MdEmail className='cxi-arrow-right lead mt-2' /> : <span class="cxi-arrow-right lead mt-n3 spinner-border spinner-border-sm mr-2 d-inline-flex" style={{ borderWidth: '0.15rem' }}></span>}
                    </div>
                  </span>
                </div>
              </div>
              <small class="form-text font-size-xxs text-light">*Subscribe to our newsletter to receive early discount offers, updates and new products info.</small>
            </form>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#292C37' }}>
        <div className="container py-2">
          <div className="d-flex align-items-sm-center justify-content-between py-1">
            <div className="font-size-xs text-light">
              <span class="d-sm-inline d-block mb-1">
                <span class="font-size-sm">© </span> 2024, Dhunguru Music INC,
                All rights reserved.
              </span>
            </div>
            <AnchorLink href='#top'>
              <div className="d-flex align-items-center">
                <span class="d-sm-inline-block d-none text-light font-size-sm mr-3 mb-1 align-vertical">Back to top</span>
                <div className=" position-static rounded show d-flex align-items-center justify-content-center">
                  <BiUpArrow class="text-white" />
                </div>
              </div>
            </AnchorLink>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default LgFooter;