import cogoToast from 'cogo-toast';
import React, {useState} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import axios from '../../../../utilis/axios'
import DeleteSession from './DeleteSession'

const InfoModal = ({show, setShow, data, sessions, setSessions}) => {
    
    const [loading, setLoading] = useState(false);
    
    const [response, setResponse] = useState('');
    const [error, setError] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const fetchRecordingFromZoom = async() => {
        await axios.post('/api/admin/uploadRecordingtoS3', {bookingId: data.bookingId}).then(res=>{
            if(res.status === 200){
                cogoToast.success(res.data)
            }

        })
    }


    const saveFlagResponse = async (e) => {
        e.preventDefault();
        if(response === '') {
            setError(true)
            return 
        }
        try{

            setLoading(true)
            const req_body = {
                response,
                enrollmentId: data.enrollment._id,
                scheduleId: data._id,
            }
            // console.log(req_body)
            const res = await axios.post('/api/admin/flag-meetings/response', req_body);
            
            const newSessions = [...sessions];
            const index = newSessions.findIndex(item => item._id === data._id);
            newSessions[index] = res.data;
            setSessions(newSessions)
            
            cogoToast.success("Meeting flag successfully resolved")
            setShow(false);
            setLoading(false)
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }



    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100 h-100 mt-1'>
                <div className = 'mb-5' id = 'details'>
                    <div className = 'h2'>Session - {data.bookingId}</div>
                </div>


                {data.isFlagged ? <div class = "mt-0">
                    <div class ="alert small alert-danger">
                        This meeting is flagged due to different response from instructor and student
                    </div>
                    <div class = "card mt-0">
                        <div class = "card-body">
                            <div>Select whose response is correct </div>
                            <div class = "d-flex align-items-center mt-2">
                                <input className = 'mb-0 mr-2' type = 'radio' checked = {response === 'student'} onClick = {() => setResponse('student')}/>
                                Student <span class = "small ml-2">({data.confirm_by_student})</span>
                            </div>
                            <div class = "d-flex align-items-center">
                                <input className = 'mb-0 mr-2' type = 'radio' checked = {response === 'instructor'} onClick = {() => setResponse('instructor')}/>
                                Instructor <span class = "small ml-2">({data.confirm_by_instructor})</span>
                            </div>
                            {error && <div class = 'invalid-feedback d-block'>Please select your response</div>}
                            <button class = "btn btn-sm btn-primary mt-3" onClick = {(e) => saveFlagResponse(e)}>
                                {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                                Resolve Flag
                            </button>
                        </div>
                    </div>
                </div> : <div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Student Response:</div>
                        <div class = "col-7">{data.confirm_by_student !== '' ? data.confirm_by_student : 'No response yet'}</div>
                    </div>
                    <div class = "row mt-4">
                        <div class = "col-5 small">Instructor Response:</div>
                        <div class = "col-7">{data.confirm_by_instructor !== '' ? data.confirm_by_instructor : 'No response yet'}</div>
                    </div>
                </div>}

                <div class = "row mt-4">
                    <div class = "col-5 small">Student:</div>
                    <div class = "col-7"><NameWithAvatar name = {data.student.firstName + ' ' + data.student.lastName} avatar = {data.student.avatar}/></div>
                </div>
                <div class = "row mt-4">
                    <div class = "col-5 small">Instructor:</div>
                    <div class = "col-7"><NameWithAvatar name = {data.instructor.firstName + ' ' + data.instructor.lastName} avatar = {data.instructor.avatar}/></div>
                </div>
                <div class = "row mt-4">
                    <div class = "col-5 small">Instrument:</div>
                    <div class = "col-7">{data.course.instrument}</div>
                </div>
                
                <div class = "row mt-4">
                    <div class = "col-5 small">Enrollment Id:</div>
                    <div class = "col-7">{data.enrollmentId}</div>
                </div>
                
                <div class = "row mt-4">
                    <div class = "col-5 small">Enrollment Type:</div>
                    <div class = "col-7">{data.type.label}</div>
                </div>

                <div class = "row mt-4">
                    <div class = "col-5 small">Status:</div>
                    <div class = "col-7">{data.status.label}</div>
                </div>
               
                <div class = "row mt-4">
                    <div class = "col-5 small">Price:</div>
                    <div class = "col-7">{data.price}</div>
                </div>
                
                {data.cancelled_by && <div class = "row mt-4">
                    <div class = "col-5 small">Cancelled / Rescheduled by:</div>
                    <div class = "col-7">{data.cancelled_by}</div>
                </div>}
                
                <div class = "row mt-4">
                    <div class = "col-5 small">Late charged applied:</div>
                    <div class = "col-7">{data.lateCharges} </div>
                </div>
                
                <div class = "row mt-4">
                    <div class = "col-5 small">Schedule Time:</div>
                    <div class = "col-7">{data.date.label}</div>
                </div>
                
                <div class = "row mt-4">
                    <div class = "col-5 small">
                        <div>Zoom Join Link ({data.zoom.licenseType} type):</div>
                        <div class = 'text-muted'>{data.zoom.user_id}:</div>
                    </div>
                    <div class = "col-7"><a class = "" target = "_blank" rel="noreferrer" href = {data.zoom.join_url}>Link</a></div>
                </div>
                
                <div class = "row mt-4">
                    <div class = "col-5 small">Recording allowed?</div>
                    <div class = "col-7">{data.zoom.isRecording ? 'Yes' : 'No'}</div>
                </div>

                

                


                {(data.invoice && data.invoice.length > 0) && <div class = "row mt-4">
                    <div class = "col-5 small">Invoice Amount</div>
                    <div class = "col-7">${data.invoice.reduce((a, b) => a + parseInt(b.amount), 0)}</div>
                </div>}

                {(data.joined_instructor) && <div class = "row mt-4">
                    <div class = "col-5 small">Instructor Clicked on Join Now</div>
                    <div class = "col-7">Yes</div>
                </div>}
                {(data.joined_student) && <div class = "row mt-4">
                    <div class = "col-5 small">Student Clicked on Join Now</div>
                    <div class = "col-7">Yes</div>
                </div>}

                {data.zoom.recording_links.length > 0 ? <div class = "row mt-4">
                    <div class = "col-5 small">Recordings</div>
                    <div class = "col-7">{data.recording_links.label}</div>
                </div> :  <div class = "btn btn-block btn-outline-warning mt-3 mb-4" onClick = {fetchRecordingFromZoom}>
                    Fetch Recording From Zoom
                </div>}


                <div class = "btn btn-block btn-outline-danger mt-3 mb-4" onClick = {() => setShowDelete(true)}>
                    Delete Session
                </div>

                <DeleteSession 
                    show = {showDelete} 
                    setShow = {setShowDelete}
                    sessions={sessions}
                    setSessions={setSessions}
                    data = {data}
                />
    
                
            </div>
        </RightModal>
    )
}

export default InfoModal


const NameWithAvatar = ({name, avatar}) => {
    return (
        <>
        <span class="item-name text-reset">{name}</span>
        </>
    )
}



