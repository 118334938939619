import React, {useState, useContext, useEffect} from 'react'
import { FiChevronRight } from 'react-icons/fi';
import Form from './Form'
import Package from './Package'
import Schedule from './Schedule'
import Complete from './Complete'
import axios from '../../../utilis/axios';
import { loginSuccess } from '../../../store/actions/auth';
import { loginUser, getUser } from '../../../services/auth';
import {Store} from '../../../store'
import BeatSpinner from '../../../components/Spinners/BeatSpinner'
import moment from 'moment'
import cogoToast from 'cogo-toast';
import {Link} from 'react-router-dom';
import { saveRequests } from '../../../store/actions/student';
import content from '../../../content/landingPage.json'
import logo from "../../../assets/MelodyMentorsLogo.svg"


const BookClass = (props) => {

    const id = props.match.params.id
    const {state, dispatch} = useContext(Store);
    const [step, setStep] = useState('form');
    const [loading, setLoading] = useState(false)
    const [mainLoading, setMainLoading] = useState(true)
    const [seeDetails, setSeeDetails] = useState(false)
    const [schedule, setSchedule] = useState({date: '', time: ''})
    const [isExistingUser, setIsExistingUser] = useState(false)
    const [course, setCourse] = useState()
    const [enrollment, setEnrollment] = useState({num_classes: '', discount: ''});
    const [discountCode, setDiscountCode] = useState('')
    const [appliedDiscount, setAppliedDiscount] = useState({})
    const [user, setUser] = useState({email: '', firstName: '', lastName: '', password: '', phone : ''})
    const [enrollmentId, setEnrollmentId] = useState('')

    useEffect(() => {
        checkIfLoggedIn();
        // eslint-disable-next-line
    }, [])

    const checkIfLoggedIn = async () => {
        try{
            if(state.user.isLoggedIn && state.user.data.role === 'student'){
                setStep('choose-package')
                setMainLoading(false);
            } else{
                const res = await getUser();
                if(res.role === 'student'){
                    loginSuccess(res, dispatch)
                    setMainLoading(false)
                    setStep('choose-package')
                } else{
                    cogoToast.error(content.buySessions.toasts.not_a_student)
                    setMainLoading(false);
                }
            }

        } catch(err){
            setMainLoading(false)
            console.log(err)
        }
    }

    useEffect(() => {
        fetchCourse()
        // eslint-disable-next-line
    }, [])

    const fetchCourse = async () => {
        try{
            const res = await axios.get(`/api/student/lesson/${id}`)
            setCourse(res.data)
        } catch(err){
            console.log(err)
        }
    }

    const login = async () => {
        try{
            setLoading(true);
            if(isExistingUser){
                await existingUser()
            } else{
                await registerNewUser()
            }
            setLoading(false)
        } catch(err){
            console.log(err)
        }
    }

    const existingUser = async () => {
        try{
            const fetchedUser = await loginUser(user);
            loginSuccess(fetchedUser, dispatch)
            if(fetchedUser.role === 'student'){
                setStep('choose-package')
            } else{
                cogoToast.error(content.buySessions.toasts.not_a_student)
            }
        } catch(err){
            cogoToast.error(content.buySessions.toasts.error_in_creds)
            console.log(err)
            setLoading(false)
        }
    }

    const registerNewUser = async () => {
        try{
            const res = await axios.post('/api/auth/register/student', user)
            if(res.data === 'already_exists'){
                cogoToast.error(content.buySessions.toasts.user_already_exists)
            } else{
                loginSuccess(res.data, dispatch)
                setStep('choose-package')
            }
        } catch(err){
            cogoToast.error("There was some error with the server, please try again")
            console.log(err)
            setLoading(false)
        }
    }


    const applyDiscountCode = async () => {
        try{
            console.log(discountCode)
            const res = await axios.post('/api/student/enrollment/checkDiscountEligibility', {discountCode})
            if(!res.data){
                cogoToast.error("Invalid discount code")
            }
            console.log(res.data)
            setAppliedDiscount(res.data);

        } catch(err){
            console.log(err)
        }
    }

    const purchaseNow = async (e) => {
        e.preventDefault()
        try{
            if(enrollment.num_classes === '' || enrollment.discount === ''){
                cogoToast.error(content.buySessions.toasts.no_package_selected)
                return;
            }
            setLoading(true);
            const res = await axios.post('/api/student/enrollment/paid', {enrollment, course, discountCode})
            const enrollment_id = res.data.enrollmentId
            setEnrollmentId(enrollment_id)
            const { amount, id: order_id, currency, index1 } = res.data;
            await displayRazorpay(order_id, currency, amount, enrollment_id, index1)
            setLoading(false);
        } catch(err){
            console.log(err)
            setLoading(false);
        }
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const displayRazorpay = async (order_id, currency, amount, enrollment_id, index1) => {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        const options = {
            // key: "rzp_test_2j87UbWiuxCiej", // Enter the Key ID generated from the Dashboard
            key: process.env.REACT_APP_RAZOR_PAY_KEY,
            amount: amount.toString(),
            currency: currency,
            name: "Dhunguru.",
            description: "Learn Music from the best online Music teachers",
            image: "https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/logo.png",
            order_id: order_id,
            handler: async function (response) {
                try{
                    setLoading(true)
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                        enrollmentId: enrollment_id,
                        index1: index1,
                    };
                    await axios.post("/api/student/enrollment/payment/success", data);
                    setStep('schedule')
                    setLoading(false)
                } catch(err){
                    setLoading(false)
                    console.log(err)
                }
            },
            modal: {
                "ondismiss": async function(){
                    await axios.post("/api/student/enrollment/payment/failure", {enrollment_id, index1});
                }
            },

            prefill: {
                name: state.user.data.firstName,
                email: state.user.data.email,
                contact: state.user.data.phone ? '91'+state.user.data.phone : '',
            },
            
            theme: {
                color: "#ff6700",
            },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.on('payment.failed', function (response){
            alert('Payment failed')
        });

        paymentObject.open();
    }


    const scheduleSession = async () => {
        try{
            setLoading(true);
            const scheduleISODate = moment(schedule.date + "-" + schedule.time.label.slice(0, 8), "DD/MM/YYYY-hh:mm A")
            const res = await axios.post('/api/student/schedule/request', {course, schedule: scheduleISODate, enrollmentId: enrollmentId})
            if(state.student.requests && state.student.requests.length > 0){
                const newRequests = [...state.student.requests]
                newRequests.push(res.data);
                saveRequests(newRequests, dispatch)
            }
            setLoading(false)
            setStep('complete')
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    return (
        <div>
            {(course && !mainLoading) ? <div>
                <Steps />
                {step === 'form' && (
                    <Form 
                        setStep = {setStep} 
                        user = {user} 
                        setUser = {setUser} 
                        login = {login}
                        isExistingUser = {isExistingUser} 
                        setIsExistingUser = {setIsExistingUser}
                        loading = {loading}
                    />
                )}
                {step === 'choose-package' && (
                    <Package 
                        setStep = {setStep} 
                        setEnrollment = {setEnrollment} 
                        enrollment = {enrollment} 
                        setSeeDetails = {setSeeDetails} 
                        seeDetails = {seeDetails} 
                        purchaseNow = {purchaseNow}
                        course = {course}
                        loading = {loading}
                        applyDiscountCode = {applyDiscountCode}
                        discountCode = {discountCode}
                        setDiscountCode = {setDiscountCode}
                        appliedDiscount = {appliedDiscount}
                        setAppliedDiscount = {setAppliedDiscount}
                    />
                )}
                {step === 'schedule' && (
                    <Schedule 
                        setStep = {setStep} 
                        course = {course}
                        schedule = {schedule} 
                        setSchedule = {setSchedule}
                        loading = {loading}
                        scheduleSession = {scheduleSession}
                    />
                )}
                {step === 'complete' && <Complete {...props}/>}
            </div> : <BeatSpinner />}
        </div>
    )
}

export default BookClass;



const Steps = () => {
    return (
    <div className="BookHeader">
        <div className="BookHeader_Container">
            <Link to = "/">
                {/* <img alt="" class="BookHeader_Logo" src={logo} /> */}
                <img alt="" class="BookHeader_Logo" src="https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/logo.png" />
                
            </Link>
            <ul class="BookHeader_Progress align-items-center pl-4 pl-lg-0">
                <li class="">
                    <div>        
                        <span class="BookHeader_Progress_Number">1</span>
                        <span class="BookHeader_Progress_Label">Your Details</span>
                    </div>      
                </li>
                <li>
                    <span class="text-dark small px-2 px-lg-5">
                        <FiChevronRight />
                    </span>
                </li>
                <li class="">
                    <div>        
                        <span class="BookHeader_Progress_Number">2</span>
                        <span class="BookHeader_Progress_Label">Choose Package</span>
                    </div>      
                </li>
                <li>
                    <span class="text-dark small px-2 px-lg-5">
                        <FiChevronRight />
                    </span>
                </li>
                <li class="">
                    <span class="BookHeader_Progress_Number">3</span>
                    <span class="BookHeader_Progress_Label">Schedule First Session</span>
                </li>
            </ul>
        </div>
    </div>
    )
}









