const libphonenumber = require('google-libphonenumber');
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
    const phone1 = phone + "";
    console.log("phone1 : ",phone1);
    var cleanPhone = (phone1.split(".").join("").split('-').join('').split(' ').join('').split('+').join(''));
    if(cleanPhone.length === 10 ) return true
    else if(cleanPhone.length === 11 && cleanPhone.slice(0, 1) === '0') return true;
    else if(cleanPhone.length === 12 && cleanPhone.slice(0, 2) === '91') return true;
    else return false;

}



export const cleanPhone = (phone) => {
    const phone1 = phone + '';
    var cleanPhone = (phone1.split(".").join("").split('-').join('').split(' ').join('').split('+').join(''));
    if(cleanPhone.length === 12) return cleanPhone.slice(2)
    if(cleanPhone.length === 10) return cleanPhone
    if(cleanPhone.length === 11) return cleanPhone.slice(1)
    return cleanPhone
}

export async function validatePhoneNumberForEveryCountry(countryCode, phoneNumber) {
    try {
        if(countryCode.length<1){
            return false;
        }
        const parsedNumber = phoneUtil.parse(phoneNumber, countryCode);
        
        return phoneUtil.isValidNumber(parsedNumber)
    } catch (error) {
        // Return false if there's an error (e.g., invalid country code or phone number)
        return false;
    }
}