import { useState, useEffect } from 'react';
import axios from '../../../../utilis/axios';
import moment from 'moment'
import Table from '../../../../components/Table'
import { getDistinct } from '../../../../utilis/helpers'

const Fines = () => {

  const [invoices, setInvoices] = useState([]);
  const [totalEarning, setTotalEarning] = useState()
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    fetchInvoices();
  }, [])

  const fetchInvoices = async () => {
    try {
      const res = await axios.get('/api/instructor/invoices/instructor_fines');
      const uniqueBookingIds = getDistinct(res.data.map(item => ({ bookingId: item.schedule.bookingId })), 'bookingId').map(item => item.bookingId);
      if (res.data) {
        setInvoices(uniqueBookingIds.map(item => {
          const invoice = res.data.filter(invoice => invoice.schedule.bookingId === item);
          return {
            invoiceId: invoice[0].receipt_id,
            date: { value: moment(invoice[0].date).unix(), label: moment(invoice[0].date).format('Do MMM YY') },
            amount: '$ ' + invoice.reduce((a, b) => parseInt(b.amount) + a, 0),
            student: invoice[0].schedule.student.firstName + ' ' + invoice[0].schedule.student.lastName,
            bookingId: invoice[0].schedule.bookingId,
            enrollmentId: invoice[0].enrollment?.enrollmentId,
          }
        }))
        setTotalEarning(parseInt(res.data.reduce((a, b) => a + parseInt(b.amount), 0)))
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card shadow-sm border">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 class="text-uppercase text-muted mb-2">Total</h6>
                  <span class="h2 mb-0">$ {totalEarning}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Table
        data={invoices}
        isSelectable={false}
        headers={headers}
        isSearchable={true}
        handleRowClick={(id) => { console.log(id) }}
        isHeader={true}
        isFooter={true}
        isSortable={true}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        filters={[]}
        isDownloadable={true}
        dateFilter='date'
      />
    </div>
  )
}

export default Fines;



const headers = [
  { value: 'invoiceId', active: true, label: 'Invoice ID' },
  { value: 'date', active: true, label: 'Date' },
  { value: 'amount', active: true, label: 'Amount' },
  { value: 'student', active: true, label: 'Members' },
  { value: 'bookingId', active: true, label: 'Booking Id' },
  { value: 'enrollmentId', active: true, label: 'Enrollment Id' },
]