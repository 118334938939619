import {useState, useContext, useEffect} from 'react';
import axios from '../../../../utilis/axios'
import {Store} from '../../../../store'
import { saveInstructorProfile } from '../../../../store/actions/auth';
import cogoToast from 'cogo-toast';

var ifsc = require('ifsc');

const Bank = () => {

    const {state, dispatch} = useContext(Store)
    const [bankDetails, setBankDetails] = useState({
        name: state.user.data.instructor_profile.bankDetails.name,
        bankName: state.user.data.instructor_profile.bankDetails.bankName,
        accountNum: state.user.data.instructor_profile.bankDetails.accountNum,
        ifsc: state.user.data.instructor_profile.bankDetails.ifsc,
        branch: state.user.data.instructor_profile.bankDetails.branch,
        upi: state.user.data.instructor_profile.bankDetails.upi
    });
    const [errors, setErrors] = useState(Array(5).fill(false));
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        setErrors(Array(5).fill(false))
    }, [bankDetails.accountNum, bankDetails.ifsc, bankDetails.upi])


    const saveChanges = async () => {
        if(!validate()) return;
        try{
            setLoading(true);
            const data = {
                ...bankDetails
            }
            // ifsc.fetchDetails(bankDetails.ifsc).then(async (rzpData) => {
            //     data.bankName = rzpData.BANK;
            //     data.branch = rzpData.BRANCH;
            // });
            const res = await axios.put('/api/instructor/profile/bank', {bankDetails: data})
            cogoToast.success("Your bank details has been successfully saved")
            saveInstructorProfile(state.user.data, res.data, dispatch)
            setLoading(false);

        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

    const validate = () => {
        let newErrors = Array(5).fill(false);
        if(!bankDetails.name || bankDetails.name === '') newErrors[0] = true;
        if(!ACCOUNR_REGEX.test(bankDetails.accountNum))  newErrors[1] = true;
        
        if(!ifsc) newErrors[2] = true;
        // else {
        //     ifsc.fetchDetails(bankDetails.ifsc).then(function(res) {
        //         setBankDetails(prev => ({...prev, bankName: res.BANK, branch: res.BRANCH}))
        //     });

        // }
        
        setErrors(newErrors)

        if(newErrors.includes(true)) return false;
        else return true;
    }


    const locateBank = () => {
        if(ifsc.validate(bankDetails.ifsc)) {
            ifsc.fetchDetails(bankDetails.ifsc).then(function(res) {
                setBankDetails(prev => ({
                    ...prev,
                    bankName: res.BANK,
                    branch: res.BRANCH
                }))
             });
        } else{
            let newErrors = Array(5).fill(false);
            newErrors[2] = true;
            setErrors(newErrors)
        }
    }
    

    return (
           <form>


                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className = 'form-group '>
                            <label>Account Number</label>
                            <input  
                                class = {errors[1] ? 'form-control is-invalid':'form-control' }
                                value = {bankDetails.accountNum} 
                                onChange = {e => setBankDetails(prev => ({...prev, accountNum: e.target.value}))}
                            />
                            <div class = 'invalid-feedback'>Please enter your account number</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className = 'form-group '>
                            <label>Account Name</label>
                            <input  
                                class = {errors[0] ? 'form-control is-invalid':'form-control' }
                                value = {bankDetails.name} 
                                onChange = {e => setBankDetails(prev => ({...prev, name: e.target.value}))}
                            />
                            <div class = 'invalid-feedback'>Please enter your name on the account</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className = 'form-group '>
                            <label>Swift Code</label>
                            <InputItemPost  
                                className1 = {errors[2] ? 'form-control is-invalid':'form-control ' }
                                value = {bankDetails.ifsc} 
                                setValue = {e => setBankDetails(prev => ({...prev, ifsc: e}))}
                                locateBank = {locateBank}
                            />
                        </div>
                    </div>
                </div> 


                <div className = 'form-group '>
                    <label>Paypal Id</label>
                    <input  
                        class = {errors[3] ? 'form-control is-invalid':'form-control' }
                        value = {bankDetails.upi} 
                        onChange = {e => setBankDetails(prev => ({...prev, upi: e.target.value}))}
                    />
                    <div class = 'invalid-feedback'>Please enter your Paypal Id</div>
                </div>

                {/* <div class = 'small mt-5 pt-3 mb-4'>The following fields will be auto filled based on the IFSC code that you will enter</div> */}
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className = 'form-group '>
                            <label>Bank Name</label>
                            <input  
                                class = "form-control"
                                value = {bankDetails.bankName} 
                                onChange={e => setBankDetails(prev => ({...prev, bankName: e.target.value}))}
                                // disabled = {true}
                            />
                            <div class = 'invalid-feedback'>Please enter your bank name</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className = 'form-group '>
                            <label>Branch Name</label>
                            <input  
                                class = "form-control"
                                value = {bankDetails.branch} 
                                onChange={e => setBankDetails(prev => ({...prev, branch: e.target.value}))}
                                // disabled = {true}
                            />
                            <div class = 'invalid-feedback'>Please enter your bank name</div>
                        </div>
                    </div>
                </div> 


                
                <div className="btn btn-primary" onClick = {() => saveChanges()}>
                    {loading && <span className = 'mr-3 spinner-border spinner-border-sm'></span>}
                    Save changes
                </div>
            </form>
    )
}

export default Bank;






const InputItemPost = ({value, setValue, className1, locateBank}) => {
    return (
        <div className="input-group input-group-merge">
            <input  class={`${className1} form-control-appended`} placeholder="" value = {value} onChange = {e => setValue(e.target.value)}/>
            {/* <div className="input-group-append">
                <div className="input-group-text pointer btn btn-secondary border-left pl-3" style = {{height: '41px'}} onClick = {locateBank}>
                Locate your bank
                </div>
            </div> */}
            <div class = 'invalid-feedback'>Please enter a valid Swift code</div>
        </div>
    )
}



const ACCOUNR_REGEX = /[0-9]{9,18}/