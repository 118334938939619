import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import axios from '../../../../utilis/axios'
import { Switch } from '../../../../components/Forms'


const EditUserModal = ({show, setShow, data}) => {
    
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [access, setAccess] = useState(data.access)

    useEffect(() => {
        setAccess(data.access)
        setUserId(data._id)
    }, [data])

    const saveAccess = async () => {
        try{

            setLoading(true)
            await axios.post('/api/admin/admin-accounts/access/', {
                access,
                user_id: userId
            })
            setLoading(false)
            
        } catch(err){
            setLoading(false)
            console.log(err);
        }
    }


    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100 h-100 mt-1'>
                <div className = 'mb-5' id = 'details'>
                    <div className = 'h2'>{data.firstName+ ' ' + data.lastName}</div>
                </div>

                {ACCESS_OPTIONS.map(item => <div key = {item} class = "row mb-4">
                    <div class = 'col'>
                        <div className = 'h4'>{item[0].toUpperCase() + item.slice(1)}</div>
                    </div>
                    <div class = 'col-auto'>
                        <Switch
                            value = {access[item]}
                            setValue = {value => setAccess(prev => ({...prev, [item]: value}))}
                        />
                    </div>
                </div>)}


                <div class = 'mt-6'>
                    <div class = 'btn btn-block btn-primary' onClick = {saveAccess}>
                        {loading && <span className = 'spinner-border spinner-border-sm mr-2'></span>}
                        Save
                    </div>
                </div>




                
                
            </div>
        </RightModal>
    )
}

export default EditUserModal




const ACCESS_OPTIONS = [
    'instructors',
    'students',
    'enrollments',
    'sessions',
    'requests',
    'invoices',
    'messages',
    'zoom',
    'discounts',
    'allUsers',
    'announcements',
]