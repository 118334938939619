import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import moment from 'moment'
import Table from '../../../../components/Table'
import AddEnrollment from './AddEnrollment'
import EnrollmentInfo from './Enrollment'
import {BeatSpinner2} from '../../../../components/Spinners/BeatSpinner'
import EditEnrollment from './EditEnrollment'

const Enrollments = (props) => {

    const [searchInput, setSearchInput] = useState('');
    const [tab, setTab] = useState('all')
    const [fetchedData, setFetchedData] = useState();
    const [enrollments, setEnrollments] = useState([]);
    const [filteredEnrollments, setFilteredEnrollments] = useState([]);
    const [selectedEnrollment, setSelectedEnrollment] = useState()
    const [selectedEnrollmentEdit, setSelectedEnrollmentEdit] = useState()
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);


    useEffect(() => {
        fetchEnrollments();
        // eslint-disable-next-line
    }, [])
    
    
    useEffect(() => {
        if(fetchedData){
            formatData(fetchedData)
        }
    }, [fetchedData])

    


    const fetchEnrollments = async () => {
        try{

            const res = await axios.get('/api/admin/enrollments');
            setFetchedData(res.data)

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data) => {
        const formatData = data.filter(item => item.type === 'demo' || item.payment_details.filter(item2 => item2.status !== 'not-paid').length > 0).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(item => ({
            ...item,
            studentName: {value: item.student.firstName + ' ' + item.student.lastName, label: <NameWithAvatar name = {item.student.firstName + ' ' + item.student.lastName} avatar = {item.student.avatar} />},
            instructorName: {value: item.instructor.firstName + ' ' + item.instructor.lastName, label: <NameWithAvatar name = {item.instructor.firstName + ' ' + item.instructor.lastName} avatar = {item.instructor.avatar} />},
            instrument: item.course && item.course.instrument,
            totalPaid1: '$'+ item.payment_details.reduce((a, b) => a + b.total_price, 0),
            totalPaid: item.payment_details.reduce((a, b) => a + b.total_price, 0),
            price_per_class1: '$' + item.price_per_class,
            pending_amount: item.payment_details.reduce((a, b) => a + b.pending_amount, 0),
            createdAt: moment(item.createdAt).format('Do MMM YY, hh:mm A'),
            student_email: item.student.email
        }))
        setEnrollments(formatData)
        setFilteredEnrollments(formatData);
    }

    useEffect(() => {
        if(enrollments.length === 0) return;
        if(tab === 'paid'){
            setFilteredEnrollments(enrollments.filter(item => item.type === 'paid'))
        } 
        else if(tab === 'demo'){
            setFilteredEnrollments(enrollments.filter(item => item.type === 'demo'))
        } 
        else if(tab === 'all') {
            setFilteredEnrollments(enrollments)
        }
        else if(tab === 'pending'){
            setFilteredEnrollments(enrollments.filter(item => item.pending_amount > 0))
        }
    }, [tab, enrollments])




    

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
        <div class = 'header mt-4'>
            <div class = 'header-body'>
                <div className = 'row align-items-end'>
                    <div className = 'col'>
                        <h3 class = 'h1 mb-0'>Enrollments</h3>
                    </div>
                    <div class = 'col-auto'>
                        <div class = "btn btn-primary" onClick = {() => setShowAddNew(true)}>Create Enrollment</div>
                    </div>
                </div>
                <AccountNav tab = {tab} setTab = {setTab}/>
            </div>
        </div>
            {fetchedData ? <Table 
                data = {filteredEnrollments}
                isSelectable = {false}
                headers = {tab === 'demo' ? demoHeaders : (tab === 'paid' ? paidHeaders : (tab === 'all' ? headers : pendingHeaders))}
                isSearchable = {true}
                handleRowClick = {(id) => {setSelectedEnrollment(id); setShow(true)}}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
                dateFilter = 'createdAt'
            /> : <BeatSpinner2 />}
            
            
            <AddEnrollment
                show = {showAddNew}
                setShow = {setShowAddNew}
                enrollments = {fetchedData}
                setEnrollments = {setFetchedData}
            />
            {(selectedEnrollment && fetchedData && fetchedData.length > 0 && enrollments.filter(item => item._id === selectedEnrollment).length > 0) && <EnrollmentInfo
                show = {show}
                setShow = {setShow}
                enrollmentId = {selectedEnrollment}
                enrollments = {fetchedData}
                setEnrollments = {setFetchedData}
                setSelectedEnrollment = {setSelectedEnrollment}
                setSelectedEnrollmentEdit = {setSelectedEnrollmentEdit}
                setShowEdit = {setShowEdit}
            />}
            
            {(selectedEnrollmentEdit && fetchedData && fetchedData.length > 0 && enrollments.filter(item => item._id === selectedEnrollmentEdit).length > 0) && <EditEnrollment
                show = {showEdit}
                setShow = {setShowEdit}
                enrollmentId = {selectedEnrollmentEdit}
                enrollments = {fetchedData}
                setEnrollments = {setFetchedData}
            />}
        </AdminDashboard>
    )
}

export default Enrollments



const AccountNav = ({tab, setTab}) => {
    return(
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'all' && 'active'}`} onClick = {() => setTab('all')}>All Enrollments</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'paid' && 'active'}`} onClick = {() => setTab('paid')}>Paid Enrollments</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'demo' && 'active'}`} onClick = {() => setTab('demo')}>Demo Enrollments</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'pending' && 'active'}`} onClick = {() => setTab('pending')}>Enrollments with Pending Payment</div>
                    </li>
                </ul>
        </div>
    </div>
    )
}



const headers = [
    {value: 'enrollmentId', active: true, label: 'Enrollment Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'student_email', active: true, label: 'Student Email'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'instrument', active: true, label: 'Instrument'},
    {value: 'type', active: true, label: 'Type'},
    {value: 'createdAt', active: true, label: 'Enrollment Date'},
    {value: 'cordinator', active: true, label: 'Cordinator'},


]

const paidHeaders = [
    {value: 'enrollmentId', active: true, label: 'Enrollment Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'student_email', active: true, label: 'Student Email'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'instrument', active: true, label: 'Instrument'},
    {value: 'num_classes', active: true, label: 'Total Classes'},
    // {value: 'price_per_class1', active: true, label: 'Price Per Class'},
    {value: 'totalPaid1', active: true, label: 'Total Cost'},
    {value: 'createdAt', active: true, label: 'Enrollment Date'},
    
]
const pendingHeaders = [
    {value: 'enrollmentId', active: true, label: 'Enrollment Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'student_email', active: true, label: 'Student Email'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'instrument', active: true, label: 'Instrument'},
    {value: 'num_classes', active: true, label: 'Total Classes'},
    {value: 'totalPaid1', active: true, label: 'Total Cost'},
    {value: 'pending_amount', active: true, label: 'Pending Amount'},
    
]

const demoHeaders = [
    {value: 'enrollmentId', active: true, label: 'Enrollment Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'student_email', active: true, label: 'Student Email'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'instrument', active: true, label: 'Instrument'},
    {value: 'createdAt', active: true, label: 'Enrollment Date'},
   
]



const NameWithAvatar = ({name, avatar}) => {
    return (
        <>
       
        <span class="item-name text-reset">{name}</span>
        </>
    )
}



