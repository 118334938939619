import React, { useState, useEffect } from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import moment from 'moment'
import Table from '../../../../components/Table'
import { BeatSpinner2 } from '../../../../components/Spinners/BeatSpinner'
import cogoToast from 'cogo-toast'



const Students = (props) => {

    const [searchInput, setSearchInput] = useState('');
    const [tab, setTab] = useState('all')
    const [students, setStudents] = useState();
    const [referrals, setReferrals] = useState();
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [loading, setLoading] = useState()


    useEffect(() => {
        fetchStudents();
        fetchReferrals();
        // eslint-disable-next-line
    }, [])


    const fetchStudents = async () => {
        try {

            const res = await axios.get('/api/admin/studentsInfo');
            // console.log(res.data.enrollments.filter(item => !item.student))
            formatData(res.data);

        } catch (err) {
            console.log(err)
        }
    }

    const fetchReferrals = async () => {
        try {

            const res = await axios.get('/api/admin/referrals');
            setReferrals(res.data.map(item => {
                if (item.refferedBy) {
                    return {
                        ...item,
                        referredBy: item.refferedBy.firstName + ' ' + item.refferedBy.lastName,
                        createdAt: { value: moment(item.createdAt).unix(), label: moment(item.createdAt).format('DD MMM YYYY') },
                        isSignedUp: item.isSignedUp ? moment(item.isSignedUp).format('DD MMM YYYY') : 'No',
                        isEnrolled: item.isEnrolled ? moment(item.isEnrolled).format('DD MMM YYYY') : 'No',
                    }
                } else return null;
            }).filter(item => item && item));

        } catch (err) {
            console.log(err)
        }
    }

    const getLastEnrollmentUpdateDate = (enrollments) => {
        if (enrollments.length > 0) {
            let lastUpdatedOn = new Date(enrollments[0].updatedAt);
            for (const enrollment of enrollments) {
                if (enrollment.updatedAt && moment(enrollment.updatedAt) > moment(lastUpdatedOn)) {
                    lastUpdatedOn = enrollment.updatedAt
                }
            }

            return lastUpdatedOn;
        }
        else {
            return null
        }

    }

    const formatData = (data) => {

        const student_stats = data.students.map(item => {
            const allEnrollments = data.enrollments.filter(item2 => item2.student._id === item._id);
            return {

                ...item,
                name: { value: item.firstName + ' ' + item.lastName, label: <NameWithAvatar name={item.firstName + ' ' + item.lastName} avatar={item.avatar} /> },
                birthday: item.birthday,
                total_enrollments: allEnrollments.length,
                total_paid: '$' + allEnrollments.reduce((a, b) => a + b.payment_details.reduce((a1, b1) => a1 + b1.total_price, 0), 0),
                isPaidEnrollment: allEnrollments.filter(item => item.type === 'paid').length > 0 ? true : false,
                isDemoEnrollment: allEnrollments.filter(item => item.type === 'demo').length > 0 ? true : false,
                total_classes: allEnrollments.filter(item => item.type === 'paid').reduce((a, b) => a + b.num_classes, 0),
                completed_classes: allEnrollments.filter(item => item.type === 'paid').reduce((a, b) => a + b.classes_completed, 0),
                left_classes: allEnrollments.filter(item => item.type === 'paid').reduce((a, b) => a + b.classes_left, 0),
                classes_left_to_request: allEnrollments.filter(item => item.type === 'paid').reduce((a, b) => a + b.num_classes - (b.classes_completed + b.classes_requested + b.classes_scheduled + b.classes_late_charged), 0),
                signup_date: moment(item.createdAt).format('Do MMM YY, hh:mm A'),
                num_demos: allEnrollments.filter(item => item.type === 'demo').length,
                num_paid_enrollments: allEnrollments.filter(item => item.type === 'paid').length,
                isDemo: item.hasTakenDemo ? 'No' : 'Yes',
                state: item.onboarding ? item.onboarding.state : '',
                subject: item.onboarding ? item.onboarding.instrument : '',
                age_group: item.onboarding ? item.onboarding.age_group : '',
                utm_campaign: item.onboarding ? (item.onboarding.utms ? item.onboarding.utms.utm_campaign : '') : '',
                utm_source: item.onboarding ? (item.onboarding.utms ? item.onboarding.utms.utm_source : '') : '',
                utm_medium: item.onboarding ? (item.onboarding.utms ? item.onboarding.utms.utm_medium : '') : '',
                utm_content: item.onboarding ? (item.onboarding.utms ? item.onboarding.utms.utm_content : '') : '',
                full_address: getAddress(item.fullAddress),
                refreshDemo: {
                    value: item._id, label:
                        <div class='btn btn-sm btn-primary' onClick={() => refreshDemo(item._id)}>
                            {loading === item._id.toString() ? <span class='spinner-border spinner-border-sm mx-4'></span> : "Refresh Demo"}
                        </div>
                },
                lastEnrollmentUpdateOn: getLastEnrollmentUpdateDate(allEnrollments)

            }
        }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        setStudents(student_stats.map(item => ({
            ...item,
            enrollment_type: { value: '', label: <EnrollmentType paid={item.isPaidEnrollment} demo={item.isDemoEnrollment} /> }
        })))
    }

    useEffect(() => {
        if (!students) return;
        if (tab === 'paid') {
            setFilteredStudents(students.filter(item => item.isPaidEnrollment))
        }
        else if (tab === 'demo') {
            setFilteredStudents(students.filter(item => item.isDemoEnrollment))
        }
        else if (tab === 'upcoming') {
            setFilteredStudents(students.filter(item => item.isPaidEnrollment && item.left_classes <= 2))
        }
        else if (tab === 'archived') {
            let filteredStudents = students.filter(item => item.updatedAt != null && moment(item.lastEnrollmentUpdateOn).add("7", "days") < moment() && item.isPaidEnrollment)
                .sort((a, b) => moment(b.lastEnrollmentUpdateOn) - moment(a.lastEnrollmentUpdateOn))
            filteredStudents = filteredStudents.map((data) => {
                return {
                    ...data,
                    lastEnrollmentUpdateOn: moment(data.lastEnrollmentUpdateOn).format("Do MMM YY, hh:mm A")
                }
            })
            setFilteredStudents(filteredStudents)
        }
        else {
            setFilteredStudents(students)
        }
    }, [tab, students])



    const refreshDemo = async (student_id) => {
        try {
            setLoading(student_id.toString())
            const res = await axios.get(`/api/admin/student/refresh-demo/${student_id}`)
            const newStudents = [...students]
            const index = newStudents.findIndex(item => item._id === student_id);
            newStudents[index].hasTakenDemo = false;
            newStudents[index].isDemo = 'Yes';
            setStudents(newStudents)
            cogoToast.success(`${newStudents[index].name.value} can take a assessment session class again now`)
            console.log(res.data)
            setLoading('')
        } catch (err) {
            setLoading('')
            console.log(err)
        }
    }


    return (
        <AdminDashboard showTitle={false} padding="low" {...props}>
            <div class='header mt-n4'>
                <div class='header-body'>
                    <div className='row align-items-end'>
                        <div className='col'>
                            <h3 class='h1 mb-0'>Students</h3>
                        </div>
                        <div class='col-auto'>
                            <AccountNav tab={tab} setTab={setTab} />
                        </div>
                    </div>
                </div>
            </div>
            {tab !== 'referrals' && (students ? <Table
                data={filteredStudents}
                isSelectable={false}
                headers={tab === 'demo' ? demoHeaders : (tab === 'paid' ? paidHeaders : tab === 'archived' ? archivedHeaders : tab === 'all' ? headers : renewalHeaders)}
                isSearchable={true}
                handleRowClick={(id) => { console.log('') }}
                isHeader={true}
                isFooter={true}
                isSortable={true}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                loading={loading}
                refreshDemo={refreshDemo}
                filters={[]}
                isDownloadable={true}
            /> : <BeatSpinner2 />)}

            {tab === 'referrals' && (referrals ? <Table
                data={referrals}
                isSelectable={false}
                headers={referralHeaders}
                isSearchable={true}
                handleRowClick={(id) => { console.log('') }}
                isHeader={true}
                isFooter={true}
                isSortable={true}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                loading={loading}
                refreshDemo={refreshDemo}
                filters={[]}
                isDownloadable={true}
            /> : <BeatSpinner2 />)}
        </AdminDashboard>
    )
}

export default Students



const AccountNav = ({ tab, setTab }) => {
    return (
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'all' && 'active'}`} onClick={() => setTab('all')}>All Students</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'paid' && 'active'}`} onClick={() => setTab('paid')}>Paid Students</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'demo' && 'active'}`} onClick={() => setTab('demo')}>Demo Students</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'referrals' && 'active'}`} onClick={() => setTab('referrals')}>Referred Students</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'upcoming' && 'active'}`} onClick={() => setTab('upcoming')}>Upcoming Renewals</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'archived' && 'active'}`} onClick={() => setTab('archived')}>Inactive</div>
                    </li>
                </ul>
            </div>
        </div>
    )
}



const headers = [
    { value: 'name', active: true, label: 'Name' },
    { value: 'email', active: true, label: 'Email' },
    { value: 'phone', active: true, label: 'Phone' },
    { value: 'birthday', active: true, label: 'Birthday' },
    { value: 'enrollment_type', active: true, label: 'Enrollment Type' },
    { value: 'signup_date', active: true, label: 'Sign up Date' },
    { value: 'subject', active: true, label: 'Instruments' },
    { value: 'state', active: true, label: 'State' },
    { value: 'age_group', active: true, label: 'Age Group' },
    { value: 'full_address', active: true, label: 'Address' },
    { value: 'utm_campaign', active: true, label: 'Campaign' },
    { value: 'utm_source', active: true, label: 'Source' },
    { value: 'utm_medium', active: true, label: 'Medium' },

]

const paidHeaders = [
    { value: 'name', active: true, label: 'Name' },
    { value: 'email', active: true, label: 'Email' },
    { value: 'phone', active: true, label: 'Phone' },
    { value: 'total_paid', active: true, label: 'Total Paid' },
    { value: 'num_paid_enrollments', active: true, label: 'Paid Enrollments' },
    { value: 'total_classes', active: true, label: 'Total Sessions' },
    { value: 'completed_classes', active: true, label: 'Sessions Completed' },
    { value: 'left_classes', active: true, label: 'Sessions Left' },
    { value: 'classes_left_to_request', active: true, label: 'Requests left' },
]
const renewalHeaders = [
    { value: 'name', active: true, label: 'Name' },
    { value: 'email', active: true, label: 'Email' },
    { value: 'phone', active: true, label: 'Phone' },
    { value: 'classes_left_to_request', active: true, label: 'Requests left' },
]

const demoHeaders = [
    { value: 'name', active: true, label: 'Name' },
    { value: 'email', active: true, label: 'Email' },
    { value: 'phone', active: true, label: 'Phone' },
    { value: 'num_demos', active: true, label: '# Unique Teacher Demos' },
    { value: 'isDemo', active: true, label: 'Can take demo?' },
    { value: 'refreshDemo', active: true, label: 'Refresh Demo' },
]


const referralHeaders = [
    { value: 'name', active: true, label: 'Name' },
    { value: 'email', active: true, label: 'Email' },
    { value: 'phone', active: true, label: 'Phone' },
    { value: 'referredBy', active: true, label: 'Referred By' },
    { value: 'isSignedUp', active: true, label: 'Signed up?' },
    { value: 'isEnrolled', active: true, label: 'Enrolled?' },
    { value: 'createdAt', active: true, label: 'Referred On' },
]

const archivedHeaders = [
    { value: 'name', active: true, label: 'Name' },
    { value: 'email', active: true, label: 'Email' },
    { value: 'phone', active: true, label: 'Phone' },
    { value: 'lastEnrollmentUpdateOn', active: true, label: 'Enrollment Updated On' }
]



const NameWithAvatar = ({ name, avatar }) => {
    return (
        <>

            <span class="item-name text-reset">{name}</span>
        </>
    )
}


const EnrollmentType = ({ paid, demo }) => {
    return (
        <>
            {paid && <span class='badge badge-primary mr-2'>Paid</span>}
            {demo && <span class='badge badge-primary mr-2'>Demo</span>}
        </>
    )
}


export const getDistinct = (array) => {
    const result = [];
    const map = new Map();
    for (const item of array) {
        if (!map.has(item._id)) {
            map.set(item._id, true);    // set any id to Map
            result.push(item);
        }
    }
    return result
}





const getAddress = (address) => {
    return (address.firstLine || '') + (address.firstLine ? ', ' : '') + (address.city || '') + (address.city ? ', ' : '') + (address.state || '') + (address.zipCode ? ', Pin code: ' : '') + (address.zipCode || '')
}