const Doc = () => (
    <div>
        <div className="mb-6">
            <h3 id="tnc-students">Dhunguru TERMS OF USE FOR STUDENTS (“STUDENT TERMS”)</h3>
            <p>Last Updated: May 28, 2024</p>
            <p>Welcome to Dhunguru Music INC <strong>("Dhunguru")</strong>. The following document, known as the <strong>(“Student Terms”)</strong> outlines the terms and conditions governing your access to and utilization of our online website or <strong>(“Platform”)</strong>. This platform serves as a conduit connecting students and Mentors for music and instrument lessons. By engaging with the Platform, you enter into a legally binding agreement with Dhunguru, and it is imperative that you thoroughly review and comprehend these Terms of Use. </p>

            <p>By accessing or utilizing the Platform, you hereby acknowledge and agree to adhere to the Student Terms, as well as the applicable Terms of Use for Mentors and Mentor Applicants ("Mentor Terms"), the policies, and guidelines hyperlinked within these Student Terms, and any prospective modifications thereof (collectively referred to as the <strong>"Agreement"</strong>). This commitment holds true regardless of whether you assume the role of a "Student," defined as an individual participating in one or more Sessions facilitated by a Mentor on the Platform.</p>

            <p>A <strong>(“User/Student”)</strong> shall mean any natural or legal person, who is competent to enter into a contract as per the Indian Contract Act, 1872, who uses the Platform, with or without registration, to avail the services offered, for himself or herself and/or for any other person.</p>

            <p>If you find yourself unable to comprehend or align with these Student Terms of Use, we kindly request that you refrain from using the Platform. It is important to note that we bear no obligation to actively enforce the Agreement on your behalf against another user. Nevertheless, we encourage you to communicate with us should you believe that another user has contravened the terms of the Agreement. While we appreciate your cooperation in reporting potential violations, it is imperative to understand that we retain the right to conduct investigations and take appropriate action, at our sole discretion.</p>

            <p>PLEASE CAREFULLY READ THESE TERMS OF USE AND THE PRIVACY POLICY (AS PART OF THE APPLICABLE TERMS) WITH YOUR PARENT OR LEGAL GUARDIAN IF YOU ARE UNDER EIGHTEEN (18) YEARS OF AGE OR THE AGE OF MAJORITY IN YOUR JURISDICTION. BY USING OR ACCESSING THIS SITE, YOU REPRESENT THAT YOU (OR YOUR PARENT OR LEGAL GUARDIAN ON YOUR BEHALF IF YOU ARE A MINOR) HAVE READ, UNDERSTOOD AND AGREE TO THE TERMS OF USE IN THEIR ENTIRETY, INCLUDING THE PRIVACY POLICY. IF YOU (OR YOUR PARENT OR LEGAL GUARDIAN ON YOUR BEHALF IF YOU ARE A MINOR) DO NOT AGREE WITH ANY PART OF THE TERMS OF USE, INCLUDING THE PRIVACY POLICY, THEN PLEASE DO NOT USE OR ACCESS THE SITE. </p>

            <p>All persons using the Platform at any time expressly agree not to use any aspect of the Platform for any purpose other than its intended Purpose as a Platform for (a) the purchase and/or sale of Sessions and/or (b) conducting Sessions. If you use the Platform for any other purpose, you violate this Agreement. Any violation of this Agreement may be punished by, without limitation, refusal of access to the Platform.</p>

            <p>If you object/disapprove to anything in this Agreement (including anything in our Privacy Policy or other applicable terms), please immediately terminate your use of the Platform. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS PLATFORM IS AT YOUR SOLE RISK. WE RESERVE THE RIGHT TO DISCONTINUE ANY ASPECT OF THE PLATFORM AT ANY TIME. THIS AGREEMENT MAY BE TERMINATED AT ANY TIME BY EITHER PARTY (Dhunguru OR YOU), FOR ANY REASON. ANY LICENSE YOU GRANT Dhunguru THROUGH ANY AGREEMENT WITH Dhunguru WILL SURVIVE TERMINATION OF THE AGREEMENT WITH Dhunguru. </p>

            <ol>
                <li className='my-3'>
                    <strong> Student Accounts.</strong>
                    <ul>
                        <li>You must register/create an account and provide certain information about yourself in order to use some of the features that are offered through the Platform. You are responsible for maintaining the confidentiality of your account password. You are also responsible for all activities that occur in connection with your account. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any reason. Your account is for your individual use only. You must provide complete and accurate information about yourself when creating your account. You may not impersonate someone else (e.g., adopt the identity of anyone other than yourself), create or use an account for anyone other than yourself, provide an email address other than your own, or create multiple accounts. You may not use a pseudonym. Please read the applicable Privacy Policy for information concerning how we handle your information.</li>
                        <li>Individuals who have not completed the registration process with Dhunguru and do not hold the status of either a Mentor or Student may be denied access to specific areas within the Platform designated for the exclusive use of Students and/or Mentors.</li>
                    </ul>
                </li>
                <li className='my-3'>
                    <strong>Communications from Dhunguru and other users.</strong> Upon creating an account, you hereby consent to receiving specific communications related to the Platform. These communications may include messages from Mentors or potential Mentors seeking information about your availability and related matters. You agree and acknowledge that Dhunguru retains the right to access and review any messages sent through the Platform's internal messaging service, without limitation to any reason. Dhunguru reserves the discretion to utilize the information contained in such messages for any purpose. Additionally, you may receive marketing communications from Dhunguru, and you retain the option to opt-out of such communications.
                </li>
                <li className='my-3'>
                    <strong>Proprietary Rights, Materials and Student Content.</strong> Dhunguru, along with its licensors, exclusively owns and retains all proprietary rights in the Platform. The Platform comprises content, visual interfaces, interactive features, information, graphics, design, compilation, computer code, products, software, services, and other elements (referred to as the “Dhunguru Materials”), all of which are protected by copyright, trade dress, patent, and trademark laws, international conventions, and other pertinent intellectual property and proprietary rights, as well as applicable laws. Unless information is in the public domain or has been expressly granted written permission, you are prohibited from copying, modifying, publishing, transmitting, distributing, licensing, performing, displaying, or selling any Dhunguru Materials. It is essential to note that Dhunguru Materials exclude Student Content (as defined below), or other content submitted by users. Dhunguru retains all rights not explicitly granted in this Agreement, and you shall not acquire any right, title, or interest to the Platform or Dhunguru Materials, except for the limited rights outlined in this Agreement. <br />
                    “Student Content” refers to any content or material of any kind emailed, uploaded or otherwise submitted by a student to or through the Platform or us, or provided by a Student during or in connection with a Session given through the Platform. As the Student, you bear responsibility for the accuracy and appropriateness of the submitted Student Content. By submitting Student Content, you hereby grant Dhunguru a perpetual, non-exclusive, worldwide license to use all or part of the submitted Student Content in any manner for the Purpose of the Platform. This license, granted by you, shall remain in effect beyond the termination or expiration of this Agreement. Dhunguru reserves the right, at its sole discretion and without notice to you, to moderate, remove, screen, edit, or reinstate Student Content from time to time. We do not have an obligation to retain or provide you with copies of Student Content, and we do not guarantee any confidentiality with respect to Student Content.<br />
                    This document outlines a partial list of Student Content that is deemed illegal or prohibited on the Platform. Dhunguru expressly reserves the right, at its sole discretion, to conduct investigations and take appropriate legal action against any individual who violates this provision. Such actions may include, without limitation, the removal of the offending communication from the Platform and termination of the membership of violators. The prohibited Student Content includes, but is not limited to:<br />
                    A. Content that is patently offensive to the online community, promoting racism, bigotry, hatred, or physical harm against any group or individual;:<br />
                    B. Content that harasses or advocates harassment of another person;:<br />
                    C. Involvement in the transmission of 'junk mail,' 'chain letters,' or ‘unsolicited mass mailing’, or 'spamming';:<br />
                    D. Promotion of information known to be false, misleading, or encouraging illegal activities or conduct that is abusive, threatening, obscene, profane, offensive, sexually oriented, racially offensive, defamatory, or libelous;:<br />
                    E. Promotion of an illegal or unauthorized copy of another person’s copyrighted work, including providing pirated computer programs or links to them, information to circumvent manufacture-installed copy-protection devices, or pirated images, audio, or video, or links to pirated images, audio, or video files;:<br />
                    F. Content containing restricted or password-only access pages, hidden pages, or images (those not linked to or from another accessible page);:<br />
                    G. Material that exploits people under the age of 18 in a sexual or violent manner or solicits personal information from anyone under the age of 18;:<br />
                    H. Instructional information about illegal activities such as making or buying illegal weapons, violating someone’s privacy, or providing or creating computer viruses;:<br />
                    I. Solicitation of passwords or personal identifying information for commercial or unlawful purposes from other users and:<br />
                    J. Engagement in commercial activities and/or sales without our prior written consent, such as contests, sweepstakes, barter, advertising, and pyramid schemes.

                </li>
                <li className='my-3'>
                    <strong>Reliance on Content.</strong> Advice, opinions, statements, offers, or any other information or content, including without limitation Student Content, made available through the Platform but not directly by Dhunguru, are the sole responsibility of their respective authors. Users should exercise discretion and not necessarily rely on such information. Authors bear full responsibility for the accuracy and integrity of their content. Dhunguru explicitly disclaims (i) any guarantee of the accuracy, integrity, quality, legality, safety, completeness, or usefulness of any information on the Platform and/or (ii) adopts, endorses, nor assumes responsibility for the accuracy or reliability of any opinion, advice, or statement made by any party on the Platform. Under no circumstances will Dhunguru or its affiliates be held responsible for any loss or damage resulting from reliance on information or other content posted on the Platform or transmitted to or by any Platform users.<br />
                </li>
                <li className='my-3'>
                    <strong>Recorded Sessions.</strong> All sessions facilitated by Dhunguru are automatically recorded ('Recorded Sessions') based on the consent obtained from both Mentors and Students at the time of sign-up. By using the Platform, you expressly grant Dhunguru the right to record every session, which will be stored and catalogued for later access by the Students of the Platform. Students and Mentors acknowledge and agree to the following: (1) Dhunguru owns all rights, including copyright, in and to any Recorded Sessions, which will generally be used only as explained herein, (2) Recorded Sessions are intended for the Purpose of the Platform, i.e., learning music, and (3) audio and/or video from Recorded Sessions may not be posted to any third-party media-hosting website (e.g., SoundCloud, YouTube, or Vimeo) without Dhunguru’s explicit permission.<br />
                    For clarity and avoidance of doubt:<br />
                    A. Recorded Sessions will be stored on the Platform and made accessible for viewing by Students/parents/guardians for the Purpose of the Platform, and by Dhunguru to facilitate the Purpose of the Platform.<br />
                    B. Unless otherwise agreed, Recorded Sessions are intended for private use within the Platform by respective Students, Mentors and Dhunguru for the Purpose of the Platform.<br />
                    C. Unless specified in a separate agreement, Dhunguru makes no claim to any rights in any Mentor’s curriculum present in the Recorded Sessions.<br />
                    D. Contact Dhunguru for any concerns about the creation or storage of Recorded Sessions. PLEASE DO NOT USE THE PLATFORM IF YOU DO NOT CONSENT TO SESSIONS BEING RECORDED.<br />
                    E. Dhunguru may review Recorded Sessions for quality purposes but is generally not obligated to do so, nor is it expected.<br />
                    F. Session recordings will not be furnished if a student utilizes alternative platform links to access sessions, aside from the designated Zoom meeting link provided by Dhunguru.<br />
                    G. Dhunguru does not endorse the content of Recorded Sessions, or any opinions, recommendations, or advice expressed therein, and expressly disclaims any liability with respect to Recorded Sessions.<br />
                    H. Dhunguru does not permit copyright infringing activities and infringement of intellectual property rights on the Platform and will remove Recorded Sessions if properly notified that content infringes on another's relevant intellectual property rights.<br />
                    I. Dhunguru reserves the right to expunge Recorded Sessions without prior notice, at its discretion, and without necessity to provide a specific reason.<br />
                </li>
                <li className='my-3'>
                    <strong>Adequate Equipment and Bandwidth.</strong> For the purpose of using the platform a computer and webcam is required. We cannot guarantee that the Platform will function at any given time using any particular hardware or connection. Connectivity greater than or equal to 500 kbps (upload and download speed) is required to use the Platform. Suggested minimum equipment and software requirements are provided below:<br />
                    <strong>Supported OS Windows</strong><br />
                    10, 8.1, 8, 7, XP;<br />
                    Mac: OS X 10.6 or higher;<br />
                    Linux: Debian 7.0; Fedora Core 18, 19; openSUSE 12.2, 12.3; Red Hat 6.1, 6.3; Scientific Linux (SL) 6.1, 6.3; Ubuntu 12.04, 12.10, 13.04, 13.10<br />
                    <strong>Recommended Configuration System</strong><br />
                    Core 2 Duo 2GHz; 2 GB RAM; 40 MB free disk space For HD: Graphics capabilities that support HD resolutions on one or more displays<br />
                    <strong>Webcams</strong><br />
                    Supported: Standard integrated webcams<br />
                    Preferred: External USB webcams that support 720p30 and higher<br />
                    <strong>Microphones and Speakers</strong><br />
                    Supported: Integrated system microphones and speakers, webcam microphones<br />
                    Preferred: Headsets and speakerphones with integrated echo cancellation<br /><br />

                    <strong>It is the sole responsibility of the Client to manage a good audio/video quality for taking the sessions. Dhunguru will have no liability if any discrepancy arises out of the audio or video quality from the end of the Client.</strong><br /><br />
                </li>
                <li className='my-3'>
                    <strong>Session Scheduling.</strong> A student has the option to select from the list of available session times on a Mentor’s schedule. Alternatively, a student may contact a Mentor to propose alternative availability, and the Mentor, at their discretion, may either accept or deny the student’s availability request. A student is permitted to schedule a session up to 6 hours prior to the designated time, contingent upon Mentor availability. In the event that the Mentor and student mutually agree to convene with less notice (i.e., if the Mentor extends an invitation to meet for a session at a time less than 6 hours later), such practice is generally permitted by the Platform. <br />
                    Students are not allowed to use link of any other platform (E.g. Google Meet, Microsoft Teams Meet etc.) except the Zoom meeting link provided by Dhunguru. The student cooperation in adhering to this guideline is essential for maintaining a professional and effective online learning environment. If you encounter any issues or have concerns accessing the session through Zoom Meeting Link provided by us, please reach out directly to Dhunguru for assistance.<br />

                    In instances where students choose to participate in sessions through alternative platforms, Dhunguru disclaims all responsibility for the conduct of the Mentor or the session itself. Additionally, recordings of such sessions will not be provided to the students. These sessions fall outside the purview of Dhunguru and are strictly prohibited. If a Mentor joins a session and subsequently requests cancellation, we kindly request that you promptly report such instances to us for proper documentation and resolution.
                </li>
                <li className='my-3'>
                    <strong>Re-Scheduling/Cancellation.</strong> Sessions are intended to proceed as scheduled. Sessions may be rescheduled with Appropriate Notice. In the event of rescheduling or cancelling a session, the party initiating the rescheduling must promptly contact the other party via the Platform's internal messaging service, providing notification at least 2 hours prior <strong>(‘Appropriate Notice’)</strong> to the scheduled session time. This communication should occur as soon as reasonably possible.<br />

                    If you cancel/re-schedule as stated above, the session will not be deducted from your balance. In the event of cancellation or rescheduling within 2 hours of the scheduled session time, your request will be forwarded to your Mentor, who will then have the discretion to either apply charges or waive them for the session. If the decision is to charge, the session fee will be deducted from your balance.<br />

                    If you possess only 1 session remaining, rescheduling beyond the 2-hour deadline will not be permitted. However, you may request cancellation by providing a detailed explanation, and if your Mentor opts not to apply charges, you may then avail the session at a later date and/or time.
                </li>
                <li className='my-3'>
                    <strong>Payments.</strong> Dhunguru Music India Private Limited exclusively reserves the right to receive payment from a student at the time of purchasing single or multiple sessions, and only upon completion of this payment is the transaction considered finalized. Payment by the student creates Platform credit equivalent to the number of Sessions bought, that the student may use with the Mentor(s) on the Platform (if the student seeks to switch from the originally booked Mentor/Session, at least 2 hours of notice prior is required). Dhunguru will disburse payment to Mentor in the manner prescribed in the <strong>(“Mentor Terms”)</strong>.<br />
                    The student shall remit the session price established by their selected Mentor for Platform usage through credit card/Debit Card (MasterCard, Visa, or American Express, processed via Razorpay) or Net Banking or UPI transactions. By utilizing the Platform, the student consents to the payment of the price designated by the Mentor for their chosen session. Furthermore, the student authorizes Dhunguru to charge the selected payment provider (MasterCard, Visa, or American Express) for the student’s use of the Platform in connection with each selected session, thereby committing to fulfil the corresponding payment using the chosen payment method. Dhunguru retains the right to rectify any errors or mistakes, even in cases where payment has already been requested or received. Failure to remit the session price at the time of payment processing may result in the student being denied access to the respective session through the Platform.<br />
                    (For information concerning Student’s payment information handling, please see the <strong>Privacy Policy</strong>.)
                </li>
                <li className='my-3'>
                    <strong> Refund Request Procedure.</strong> Every request for a refund of student payment for a session <strong>("Refund")</strong> will be approached with meticulous attention on a case-by-case basis. In the event of dissatisfaction following a session, we kindly request that you contact our dedicated support team at +918045683666 within forty-eight (48) hours of the session or before scheduling the subsequent session. In such instances, we are committed to offering you a complimentary replacement session with a Mentor of your choice or facilitating the selection of an alternative Mentor. Should dissatisfaction persist, we will credit a session to your balance, allowing you to redeem it for another session of equal value. Please be aware that opting for a session of higher value will necessitate covering the differential cost. It is imperative to note that the Satisfaction Guarantee does not extend to cases where a student misses a session or arrives late due solely to their own actions.<br />
                    In situations where a Mentor is unable to conduct a session due to a Student's (i) inadequate hardware/bandwidth or (ii) inappropriate conduct, Dhunguru typically does not issue a refund to the Student. If a student perceives that a Mentor's conduct makes the session unfeasible, it is essential for the Student to promptly inform Dhunguru. All instances of this nature, including any discussions related to potential refunds, will be thoroughly examined and addressed on a case-by-case basis.<br />
                    Additionally, if a Mentor cancels a session, the Student has the option to request a refund for the cancelled session. Alternatively, the Student may choose to reschedule the session with the same Mentor.
                </li>
                <li className='my-3'>
                    <strong> No-show policy.</strong> In cases where the student delays the session for more than Fifteen (15) Minutes or/and does not attend the session or/and has not rescheduled the Session and/or made other arrangements with the Mentor, will not receive any Refund for the Session. Additionally, that Session shall be deducted from your Session balance. Students are strongly encouraged to openly communicate with Mentors and, when necessary, utilize the Platform to seek rescheduling of sessions promptly and with Appropriate Notice. It is imperative that Students provide ample time for rescheduling to minimize disruption to Mentors' schedules. Students repeatedly cancelling sessions without Appropriate Notice may face consequences, including limitations on Platform access, potential prohibition from acting as a student, or denial of access to the Platform.
                </li>
                <li className='my-3'><strong>User Conduct.</strong> Students are urged to uphold a standard of respectful conduct during sessions, refraining from any form of misbehaviour, harassment, or verbal abuse directed towards Mentors. In the event that students observe inappropriate behaviour from a Mentor, it is expected that they promptly report such incidents to Dhunguru for appropriate action and resolution. Dhunguru expressly disclaims responsibility for the conduct, whether online or offline, of any user of the Platform. Under no circumstances will Dhunguru or any of its affiliates, advertisers, promoters, or distribution partners be held responsible for any loss or damage, including personal injury or death, resulting from anyone's use of the Platform, any content posted on the Platform or transmitted to users, or any interactions between users, whether online or offline. Dhunguru is not liable for any loss or damage, including personal injury or death, resulting from any events or actions or any interaction between a user of the Platform and any third party. Dhunguru cannot guarantee and does not promise any specific results from the use of the Platform.
                </li>
                <li className='my-3'><strong>Platform Use. </strong><br />
                    <strong>A.</strong> The utilization of the Platform is intended for individuals aged 18 and above, or for users under the age of 18 who have proper authorization and supervision from their parent and/or legal guardian and are otherwise allowed by the terms outlined herein.<br />
                    <strong>B.</strong>Registration and participation on the Platform are limited to individuals aged 18 and above, emancipated minors, or those with valid parental or guardian consent, who are fully capable of agreeing to the terms, conditions, obligations, affirmations, representations, and warranties outlined herein. By registering or participating in services or functions on the Platform, you affirm that you are either over 18 years of age, an emancipated minor, or possess consent from a legal parent or guardian, demonstrating the authority to enter into the terms herein.<br />
                    <strong>C.</strong> Parents and legal guardians should be cognizant/aware that, while Dhunguru mandates users under 18 to affirm through these terms that they are either emancipated or have the authorization of their parents or legal guardians, and despite Dhunguru's precautions to prevent users under 13 from interacting with the platform without parental or legal guardian consent, the ultimate responsibility/liability lies with parents and legal guardians to supervise the online activities of their children. Parental control mechanisms are widely available for those interested in regulating their children's access to online materials. Dhunguru cannot be held accountable for the activities of platform users. <br />
                    <strong>D.</strong> If your utilization of the Platform results in the exposure of any user under the age of 18 to sexually explicit material, and Dhunguru becomes aware of such conduct, we are obligated by law to report your actions to the appropriate authorities. Furthermore, other forms of conduct that violate this Agreement may be reported to the relevant authorities at our discretion, determined on a case-by-case basis.<br />
                    <strong>E.</strong> The use of this Platform must be for lawful purposes only and in accordance with the Purpose of the Platform. You are prohibited from assisting others in engaging in any activities that are not permissible under the terms of the Agreement. You must refrain from (a) submitting or transmitting any content or material through the Platform or (b) engaging in any conduct that Dhunguru, at its sole discretion, deems:<br />
                    <div className='ml-5'>
                        i. violates or infringes the rights of others, including but not limited to patent, trademark, trade secret, copyright, privacy, publicity, or other proprietary rights;<br />
                        ii. is unlawful or objectionable at our sole discretion, including but not limited to materials or conduct(s) that is threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, obscene, invasive of another’s privacy, pornographic, sexually explicit, or tortiously interferes with another;<br />
                        iii. forges email headers or disguises the origin of any communication;<br />
                        iv. sends unsolicited bulk messages within the internal messaging service of the Platform;<br />
                        v. use of automated “bots” to access or compile information posted with respect to the Platform;<br />
                        vi. victimizes, harasses, degrades, or intimidates any individual or group of individuals;<br />
                        vii. impersonates any person, business, or entity;<br />
                        viii. expresses or implies that any statements made by you are endorsed by Dhunguru without our specific prior written consent;<br />
                        ix. that any statement(s)/conduct(s) by you are endorsed by Dhunguru without our specific prior written consent;<br />
                        x. “frames” or “mirrors” any part of the Platform, without Dhunguru’s prior written authorization. Additionally, You must not use meta tags or code or other devices containing any reference to Dhunguru or the Platform in order to direct any person to other web site for any purpose;<br />
                        xi. modifies, adapts, sublicenses, translates, sells, reverse engineers, deciphers, decompiles or otherwise disassembles any portion of the Platform or any software used on or for the Platform, or cause others to do so;<br />
                        xii. encourages any conduct that constitutes a criminal act or that gives rise to a civil liability;<br />
                        xiii. promotes any business other than the Platform or your services as offered through the Platform in accordance with the Purpose of the site; or<br />
                        xiv. violates the Agreement or otherwise interferes with the rights of others.<br />
                    </div>
                    <strong>F.</strong>You additionally agree not to post or transmit any image of another person without obtaining that person's explicit consent. Furthermore, you may not utilize the Platform in any manner that has the potential to damage, disable, overburden, or impair Dhunguru's servers, or disrupt the use and enjoyment of the Platform by any other party. Unauthorized attempts to access services or information for which you have not been granted access, including password mining or any other illicit processes, are strictly prohibited.<br />
                    <strong>G.</strong>Under no circumstances are Students permitted to use recorded information from Sessions for purposes other than personal use in connection with the Platform's designated Purpose (i.e., learning music). Students are expressly prohibited from posting audio or video from recorded Sessions to any third-party media-hosting website (e.g., SoundCloud, YouTube, or Vimeo). Sessions may only be utilized for the intended Purpose of the Platform, specifically for teaching or learning music, and Students are prohibited from using the use in an unduly personal manner unrelated to its Purpose (e.g., stalking, harassing, or inquiring about merchandise/concert tickets/backstage passes, etc.). Non-compliance with this provision may result in the cancellation of Sessions without a Refund and/or the limitation or denial of access to the Platform for the offending Students.<br />
                    <strong>H.</strong> We may take any legal and technical remedies to prevent the violation of this Agreement and to otherwise enforce the Agreement at our sole discretion.
                </li>
                <li className='my-3'><strong>International Users </strong><br />The Platform is controlled, operated and administered by Dhunguru Music India Pvt Ltd from our offices within India. If you access the Platform from a location outside India, you are responsible for compliance with all local laws. You agree that you will not use the Dhunguru Content accessed through the Platform in any country or in any manner prohibited by any applicable laws, restrictions or regulations.
                </li>
                <li className='my-3'><strong>Engagement/ Interactions with other users. </strong><br />
                    <strong>A.</strong> Users of the Platform, including Mentors, Students, prospective Mentors, and prospective Students, are individually and solely responsible for their interactions. Dhunguru explicitly disclaims any representations or warranties regarding the conduct of Platform users and shall not be held liable for any user's behaviour. Users agree to exercise reasonable precautions in all interactions, especially in offline or in-person meetings. Under no circumstances should financial information, such as credit card or bank account details, be shared with any other Platform user.<br />
                    <strong>B.</strong>Release. You hereby release Dhunguru from any and all claims, demands, damages (actual, consequential, nominal, punitive, or otherwise), equitable relief, and any other legal, equitable, and administrative remedy, of every kind and nature, whether known or unknown, suspected or unsuspected, disclosed or undisclosed, past, present, or future, arising out of or in any way connected with your interaction with other users of the Platform.<br />
                    <strong>C.</strong> Dhunguru HAS NO DUTY TO MONITOR COMMUNICATIONS AND/OR INTERACTIONS TAKING PLACE ON OR THROUGH THE PLATFORM.<br />
                    <strong>D.</strong> SEXUALLY EXPLICIT MATERIAL IS NOT ALLOWED TO BE USED/TRANSMITTED THROUGH THE PLATFORM.

                </li>
                <li className='my-3'><strong>Exchange of Personal Information.</strong>
                    Exchange of contact information including but not limited to Mobile/Phone Number, Email, YouTube Account, Instagram Account, Address, between Students and Mentors is strictly prohibited. It is irrelevant whether the contact details were provided by the Student or acquired from the Mentor; any form of exchange is strictly prohibited. Students found in violation of this policy will be blocked from using the Platform and scheduling further sessions. Furthermore, in the event of a Student's breach of these Terms, including but not limited to this section, any remaining sessions in the Student's enrollment will be forfeited, and Dhunguru shall bear no liability for providing additional sessions or Refund to the Student.
                </li>
                <li className='my-3'><strong>Amendments to the Terms.</strong>
                    Dhunguru retains the right to modify, amend, add, or remove sections of this Agreement at our sole discretion. Any such changes will be published on the Platform website and communicated to you through the email address you have provided to Dhunguru. It is crucial to maintain an up-to-date email address with Dhunguru for this purpose (Please note that your submission of personal information, including your email address, is governed by our Privacy Policy). We advise you to periodically review the Agreement for any updates. Your ongoing use of the Platform following the posting of changes signifies your binding acceptance of the modified terms.
                </li>
                <li className='my-3'><strong>Disclaimer of Warranties and Limitation of Liability/Other Disclaimers. </strong><br />
                    The platform and all information, content, materials, products, and services provided through this platform are offered by us on an "as is" and "as available" basis. Dhunguru makes no representations or warranties of any kind, express or implied, regarding the operation of the platform or the information, content, materials, products, or services made available through it.<br />

                    To the maximum extent permissible under applicable law, Dhunguru disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. Dhunguru does not warrant that the platform and any information, content, materials, products, or services provided through it, are free of viruses or other harmful components. Dhunguru also does not warrant that the platform and the services offered will not utilize data causing you to exceed any applicable restrictions on data use imposed by your internet provider, mobile or otherwise.<br />

                    Except in jurisdictions where such provisions are restricted, under no legal theory, including but not limited to negligence, will Dhunguru or its affiliates, contractors, employees, agents, or third-party partners or suppliers be liable to you or any third person for damages of any kind arising from the use of the platform. This includes, without limitation, direct, indirect, incidental, consequential, special, and punitive damages related to any act or omission, such as lost profits, data, or use of the platform, even if Dhunguru has been advised of the possibility of such damages. In cases where applicable law does not allow the above release of liability, Dhunguru's liability will be limited to the extent permitted by law.<br />

                    In no event shall Dhunguru or its affiliates, contractors, employees, agents, or third-party partners, licensors, or suppliers' total liability to you for any cause whatsoever (whether in contract, tort, warranty, or otherwise), and regardless of the form of the action, exceed the amount paid by you to Dhunguru for the use of the platform in the 12 months prior to the action giving rise to the liability.

                </li>
                <li className='my-3'><strong>No Result Guarantee.</strong>
                    Dhunguru under no event guarantee any results from use of the platform. Dhunguru does not endorse any music, language, or academic tutoring program offered through the platform. Any statements, guarantees, or other information that may be provided to you by Mentors or other users of the platform are solely attributable to the Mentor or user. Dhunguru is not liable for any statements or guarantees made by Mentors or other users.
                </li>
                <li className='my-3'><strong>Operation and Content. </strong>
                    Dhunguru disclaims responsibility for any incorrect or inaccurate content posted on or in connection with the Platform, whether caused by users, members, or any equipment or programming associated with or utilized in the Platform. Dhunguru assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communication line failure, theft, or destruction, or unauthorized access to, or alteration of, user communications. Additionally, Dhunguru is not accountable for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email, or players, due to technical problems or traffic congestion on the Internet or at any website, or a combination thereof. This includes any injury or damage to users or any other person's computer related to or resulting from participating or downloading materials in connection with the web and/or in connection with the Platform.
                </li>
                <li className='my-3'><strong>Other website links. </strong>
                    The Platform may contain links to other websites. Interactions that occur between you and the third-party sites are strictly between you and such sites and are not the responsibility of Dhunguru. We are not responsible for examining or evaluating, and do not warrant the offerings of, any of these businesses or individuals or the content of their sites. Dhunguru does not assume any liability or responsibility for the actions or omissions, product, availability, or content of these outside resources. We encourage you to read those third-party sites’ applicable terms of use and privacy policies.
                </li>
                <li className='my-3'><strong>Choice of Law. </strong>
                    The Agreement shall be governed, construed, and enforced in accordance with the laws of India, including but not limited to:<br />
                    <ul>
                        <li>The Indian Contact,1872;</li>
                        <li>The (Indian) Information Technology Act, 2000;</li>
                        <li>The (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011;</li>
                        <li>the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 and</li>
                        <li>The (Indian) Digital Data Protection Act, 2023.</li>
                    </ul>
                    <br />
                    The parties mutually agree that all actions and proceedings arising out of or related to this Agreement shall be exclusively litigated in the courts located in Gurugram. Each party hereby submits to the personal jurisdiction of such courts for the purposes of such actions or proceedings. Furthermore, each party agrees to irrevocably waive, to the fullest extent permitted by applicable law, any right to a jury trial in any legal proceeding arising out of or relating to this Agreement.
                </li>
                <li className='my-3'><strong>Assignment.</strong>
                    The Agreement, and any rights and licenses granted hereunder, must not be transferred or assigned by you to any other party, but may be assigned by Dhunguru without restriction. Any assignment made or attempted to be made in violation of this Agreement shall be void.
                </li>
                <li className='my-3'><strong>Warranty.</strong>
                    You expressly warrant that you are over the age of eighteen (18) or/and in case of a minor, a parent, legal guardian and have the capacity to enter into this Agreement, that any and all information (including without limitation Mentor Content) provided by you through the Platform is accurate and does not infringe the intellectual property rights of any other party and that your conduct and use of the Platform will conform with the terms of this Agreement.
                </li>
                <li className='my-3'><strong>Indemnification.</strong>
                    You agree to indemnify and hold Dhunguru and/or any of Dhunguru’s subsidiaries, members, affiliates, officers, agents, licensors, partners, and employees harmless from any loss, liability, claim, damages, obligations, or demand, including reasonable attorney’s fees, made by any third party due to or arising out of your use or interaction with the Platform including without limitation: (i) your violation or breach of this Agreement, (ii) your Mentor/Student Content, (iii) any intellectual property infringement or other infringement of the rights of third parties, or (iv) any breach of your representations and warranties outlined above. Dhunguru reserves the right, with no obligation, to assume, at your expense, the exclusive defence and control of any matter for which you are required to indemnify us. You agree to cooperate fully with our defence of these claims and not to settle any matter without the prior written consent of Dhunguru. We will make reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.
                </li>
                <li className='my-3'><strong>Notice.</strong>
                    All notices given to you by the Company or by you to the Company shall be in writing and in the English language. Such notice shall be sent by e-mail or mailed by a prepaid internationally recognized courier service to the intended recipient at the address set out below, or any changed address that is notified by either Party:<br />
                    <strong>Notice to the Company:</strong><br />
                    {/* Dhunguru Music INC<br /> */}
                    {/* WeWork<br />
                    DLF Two Horizon Centre,<br />
                    5th Floor, DLF Phase 5,<br />
                    Gurugram, Haryana 122002<br />
                    M: +918045683666<br /> */}
                    <>Dhunguru Music INC,</><br />
                    <>1111B South Governors Avenue, STE 21731,</><br />
                    <>Dover, DE 19904,</><br />
                    <>United States</><br />
                    <>Notice to User:</><br />
                    At the email address provided by you at the time of registration on the Website.
                </li>
                <li className='my-3'><strong>Severability.</strong>
                    If any provision of this Agreement is deemed unlawful, void, or unenforceable by a judge or tribunal body, then that provision shall be considered severable from this Agreement. The invalidity or unenforceability of any provision shall not affect the validity and enforceability of the remaining provisions.
                </li>
                <li className='my-3'><strong>Claims.</strong>
                    You and Dhunguru agree that any cause of action arising out of or related to the platform must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
                </li>
                <li className='my-3'><strong>Grievance Redressal</strong>
                    If you have any questions or grievances regarding the Platform, or the contents thereof, you may reach out to ‘Aditi Raj’ the Company’s Data Protection Officer at <a href='mailto:support@dhungurus.com'>support@dhungurus.com</a> .
                </li>
                <li className='my-3'><strong>Repeat Infringer</strong>
                    Please note that Dhunguru will promptly terminate without notice any Student’s access to the Platform if that user or Student is determined by Dhunguru to be a “repeat infringer.” A “repeat infringer” is a Platform user who has been notified by Dhunguru of infringing activity violations more than twice and/or who has had their Student Content or any other user- submitted content removed from the Platform more than twice. In addition, Dhunguru accommodates and does not interfere with standard technical measures used by copyright owners to protect their materials.
                </li>
                <li className='my-3'><strong>Blocking of IP Addresses/Users.</strong>
                    In order to protect the integrity of the Platform, Dhunguru reserves the right at any time in its sole discretion to block users from certain IP addresses from accessing the Platform. Additionally, Dhunguru reserves the right to take appropriate measures to deny Platform access to any user who attempts to create a second Platform registration after being denied Platform access by Dhunguru.
                </li>
                <li className='my-3'><strong>Use outside India.</strong>
                    While the Platform may be accessible globally, the Company does not assert that the materials on the Platform are suitable or available for use in locations outside India, nor does it affirm compliance with the laws applicable in jurisdictions beyond India. Accessing the Platform from territories where the content or provision of Services is considered illegal is expressly prohibited. Users accessing the Platform from locations outside India do so at their own peril and are obligated to adhere to local laws applicable to them. Any offer for Services or information presented in connection with the Platform is null and void where prohibited.
                </li>
                <li className='my-3'><strong>International Transactions.</strong>
                    All amounts on the Platform are stated in USD, and exchange rates applicable to international transactions, which may change at any time, are determined by Dhunguru's third-party payment processors.
                </li>
                <li className='my-3'><strong>Force Majeure.</strong>
                    The Company bears no responsibility for the cessation, interruption, or delay in fulfilling its obligations herein, arising from events such as earthquakes, floods, fires, storms, pandemics, state-imposed lockdown restrictions, natural disasters, acts of God, wars, terrorism, armed conflicts, labour strikes, lockouts, or boycotts. The Company reserves the right, at its sole discretion and without prior notice, to discontinue the operation of the Platform at any time.
                </li>
                <li className='my-3'><strong>No Waiver. </strong>
                    The Company's delay or failure to exercise or enforce any right or provision stipulated in this Agreement shall not be construed as a waiver of such right or provision.
                </li>
                <li className='my-3'><strong>Termination.</strong>
                    The Company reserves the right to terminate your access to the Platform without providing notice if, at its sole discretion, there is a reasonable belief that you have violated any terms and conditions outlined in this Agreement.
                </li>
                <li className='my-3'><strong>Entire Agreement.</strong>
                    This Agreement along with the Privacy Policy comprises the entire agreement between the student and the Company with respect to the use of the Platform.
                </li>
                <li className='my-3'><strong>Contact.</strong>
                    If you have any query or comments with respect to the terms of this Agreement, drop a WhatsApp query or contact us at: <strong>+918045683666</strong> or <br />
                    <strong>Dhunguru Music INC,</strong><br />
                    {/* <strong>WeWork</strong><br /> */}
                    <strong>1111B South Governors Avenue, STE 21731,</strong><br />
                    <strong>Dover, DE 19904,</strong><br />
                    <strong>United States</strong><br />
                </li>
            </ol>
        </div>
        <hr className="mb-6" />
    </div>
)

export default Doc
