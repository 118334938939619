import React, {  useState } from 'react'
import DashboardLayout from '../index'
import Earnings from './Earning'
import ClassInvoices from '../Earnings/ClassInvoices'
import Fines from './Fines'

const Account = (props) => {

    const [tab, setTab] = useState('earnings')


    return (
        <DashboardLayout showTitle={false} {...props}>
            <div className='mt-4'>
                <div className='row justify-content-center'>

                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 class="header-pretitle">Overview</h6>
                                        <h1 class="header-title">Income</h1>
                                    </div>
                                </div>
                                <AccountNav tab={tab} setTab={setTab} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className='col-11 col-lg-10 col-xl-8'>
                    {tab === "class_invoices" && <ClassInvoices />}
                    {tab === "class_fines" && <Fines />}
                    {tab === "earnings" && <Earnings />}
                    {/* {tab === "incentives" && <Incentives />} */}
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Account



const AccountNav = ({ tab, setTab }) => {
    return (
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'earnings' && 'active'}`} onClick={() => setTab('earnings')}>Income</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'class_invoices' && 'active'}`} onClick={() => setTab('class_invoices')}>Session invoices</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'class_fines' && 'active'}`} onClick={() => setTab('class_fines')}>Deduction</div>
                    </li>
                    {/* <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'incentives' && 'active'}`} onClick = {() => setTab('incentives')}>Incentives</div>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}