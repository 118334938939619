import React, {useState} from 'react'
import MyDropzone from '../../../../components/Dropzone/MyDropzone'

const Documents = ({documents, setDocuments, uploadFiles, loading, profile}) => {

    const [errors, setErrors] = useState(Array(4).fill(false))

    const validate = () => {
        const new_errors = Array(4).fill(false)
        if(documents.aadhaar.length === 0  && !(profile.documents && profile.documents.aadhaar && profile.documents.aadhaar.url)) new_errors[0] = true;
        if(documents.education.length === 0  && !(profile.documents && profile.documents.education && profile.documents.education.url)) new_errors[1] = true;
        if(documents.panCard.length === 0  && !(profile.documents && profile.documents.panCard && profile.documents.panCard.url)) new_errors[2] = true;
        if(documents.cancelledCheque.length === 0  && !(profile.documents && profile.documents.cancelledCheque && profile.documents.cancelledCheque.url)) new_errors[3] = true;
        setErrors(new_errors)
        if(new_errors.includes(true)) return false;
        else return true;
    }

    const moveNext = async e => {
        e.preventDefault(); 
        try{
            if (!validate()) {
                window.scrollTo(0, 0)
                return;
            }
            await uploadFiles()
        } catch(err){
            console.log(err)
        }
    }


    return (
        <div class = "">
            <div class = ''>
                <UploadDocument 
                    files = {documents.aadhaar}
                    setFiles = {(file) => setDocuments(prev => ({...prev, aadhaar: file}))}
                    title = "Upload Passport (Front Side)"
                    description = ""
                    url = {(profile.documents && profile.documents.aadhaar) ? (profile.documents.aadhaar.url ? profile.documents.aadhaar.url : '') : ''}
                    errors = {errors} 
                    setErrors = {setErrors} 
                    errIndex = {0} 
                    errText = "Required document!"
                />
                <UploadDocument 
                    files = {documents.cancelledCheque}
                    setFiles = {(file) => setDocuments(prev => ({...prev, cancelledCheque: file}))}
                    title = "Upload Passport (Back Side)"
                    description = ""
                    url = {(profile.documents && profile.documents.cancelledCheque) ? (profile.documents.cancelledCheque.url ? profile.documents.cancelledCheque.url : '') : ''}
                    errors = {errors} 
                    setErrors = {setErrors} 
                    errIndex = {3} 
                    errText = "Required document!"
                />
                <UploadDocument 
                    files = {documents.education}
                    setFiles = {(file) => setDocuments(prev => ({...prev, education: file}))}
                    title = "Upload highest education proof"
                    description = ""
                    url = {(profile.documents && profile.documents.education) ? (profile.documents.education.url ? profile.documents.education.url : '') : ''}
                    errors = {errors} 
                    setErrors = {setErrors} 
                    errIndex = {1} 
                    errText = "Required document!"
                />
                <UploadDocument 
                    files = {documents.panCard}
                    setFiles = {(file) => setDocuments(prev => ({...prev, panCard: file}))}
                    title = "Upload Driver's license"
                    description = ""
                    url = {(profile.documents && profile.documents.panCard) ? (profile.documents.panCard.url ? profile.documents.panCard.url : '') : ''}
                    errors = {errors} 
                    setErrors = {setErrors} 
                    errIndex = {2} 
                    errText = "Required document!"
                />
            </div>
            <button disabled = {loading} className = 'btn btn-block my-5 btn-primary px-6' onClick = {(e) => moveNext(e)}>
                {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                Save
            </button>
        </div>
    )
}

export default Documents



const UploadDocument = ({setFiles, title, files, description, url, errors, setErrors, errIndex, errText}) => {

    const editError = () => {
        const newErr = [...errors]
        newErr[errIndex] = false;
        setErrors(newErr)
    }
    return(
        <div class = "mb-5 pb-2">
        <div className = 'form-group'>
            <label class = "bold mb-1">{title}</label>
            <div class = 'text-muted small mb-3'>{description}</div>
            {(files.length === 0  && url) && <img alt = "" src = {(url.includes('jpg') | url.includes('png')) ? url : 'https://media.kasperskydaily.com/wp-content/uploads/sites/92/2020/01/14073112/36C3-PDF-digital-signature-featured-1.jpg'} class = 'rounded mb-2' style = {{height: '50px', width: '50px', objectFit: 'cover'}}/>}
            {files.length > 0 && <img alt = "" src = {files[0].type.includes('image') ? URL.createObjectURL(files[0]) : 'https://media.kasperskydaily.com/wp-content/uploads/sites/92/2020/01/14073112/36C3-PDF-digital-signature-featured-1.jpg'} class = 'rounded mb-2' style = {{height: '50px', width: '50px', objectFit: 'cover'}}/>}
            <MyDropzone onFileChange = {(file) => {editError(); setFiles(file)}} files = {files} height = '100px' isMulti = {false} type = 'image/jpeg, image/png, .pdf' />
            {errors[errIndex] && <div class = 'invalid-feedback d-block'>{errText}</div>}
        </div>
        </div>
    )
}