const Doc = () => (
    <div>
    <div class ="mb-6">
    <h3 id = "refund">Payment and Refund Policy</h3>

        <p>Last Updated: May 28, 2024</p>
        <ol >
            <li className ='my-3'>Full payment to be made at time of purchase of session(s).</li>
            <li className ='my-3'>Once purchased, no partial or full refund of the amount spent on purchase of the session(s) will be provided in terms of money, however if the student doesn’t like a certain Mentor, we will offer the student a free replacement. Upon further dissatisfaction, we will credit a session to the student’s session balance which can be utilised for another session of the same value. If the student chooses a session of the higher value, the student will need to pay the differential.</li>
            <li className ='my-3'>Dhunguru does not have any recurring subscription charges which a student has to pay.</li>
            <li className ='my-3'>Dhunguru does not store any financial/payment information (debit card, credit card, net banking, etc.) or such other information restricted by the Reserve Bank of India (RBI) for processing payment and has partnered with payment gateways for the payment towards the services, and thus it makes it absolutely safe for the student.</li>
            <li className ='my-3'>In case of any other payment, refund or cancellation issues, decisions will be taken on a case by case basis and Dhunguru decision will be final and binding. If Dhunguru decides to refund (partially or in full) the money spent by a student, based on the facts and circumstances of a certain case, the same shall be processed and credited to the student’s account within 5-7 business days.</li>
        </ol >
    </div>
    <hr class = "mb-6"/>
    </div>
)

export default Doc
    