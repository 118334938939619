const Doc = () => (
    <div>
        <div class="mb-6">
            <h3 id="privacy">PRIVACY POLICY</h3>
            <p>Last Updated: May 28, 2024</p>
            <p>This publication is mandated by the Information Technology Act, 2000, which stipulates that 'intermediaries' must disclose the guidelines, privacy policy, and terms governing website access or usage. The key governing rule is Rule 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011, along with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.</p>
            <p>Dhunguru Music INC, ("Dhunguru" or "We", "us"), respects your privacy and we are committed to protecting your privacy through our compliance with this privacy policy (“Policy”). </p>
            <p><strong>This Privacy Policy may be subject to further changes including as may be warranted by change in law. Upon updating the Policy, we may revise the "Updated" date at the bottom of this Policy. We therefore request you to go through our Privacy Policy frequently to be updated with changes incorporated from time to time. Your continued engagement with us will imply your acceptance of such updates to this Policy.</strong></p>

            <p><strong>If you do not agree to the terms of this Privacy Policy, please do not (i) access or use our Platform, (ii) avail of services from us, and do not disclose your information to us.</strong></p>
            <p>The Policy does not apply to information collected by any third party, including through any third-party application or content (including advertising) that links to or is accessible from our websites.</p>
            <ol >
                <li className='my-3'>
                    <strong>Applicability.</strong>  The terms of this Privacy Policy are applicable to all individuals and entities that visit, access and/or avail our services via the Platform from whom we collect information, including but not limited to Students, Teachers, Clients, and Therapists.
                </li>
                <li className='my-3'>
                    <strong>Personal Identifiable Information (PII).</strong>  <sup>1</sup>To complete your registration with us and facilitate sessions and communications between students-teachers and clients-therapists, we request you to provide us with your contact information, including but not limited to:
                    <ul>
                        <li>Full name</li>
                        <li>Email address</li>
                        <li>Mobile number </li>
                        <li>Date of birth </li>
                        <li>Gender</li>
                        <li>Age</li>
                        <li>Photograph </li>
                        <li>Postal Address</li>
                        <li>Location</li>
                        <li>Language</li>
                        <li>Qualifications</li>
                    </ul>
                    The amount of PII you provide is entirely your discretion. The mandatory PII required to complete registration with the Platform includes-
                    <br />
                    <strong>I.</strong> For Students/Clients: Name, Email, Mobile number, and Location.<br />
                    <strong>II.</strong> For Teachers/Therapists: Name, Email, Mobile number, Location and Qualifications.<br />
                    PII provided by teachers/therapists through the Platform will be available to platform visitors and/or prospective students/clients to facilitate the scheduling of session. <br />
                    The e-SPI Rules 2011 further define<strong>“Sensitive Personal Data or Information”</strong><sup>2</sup> (SPDI) of a person to mean personal information about that person relating to:<br />
                    <ul>
                        <li>Passwords</li>
                        <li>financial information such as bank accounts, credit and debit card details or other payment instrument details</li>
                        <li>physical, physiological, and mental health condition</li>
                        <li>sexual orientation</li>
                        <li>medical records and history</li>
                        <li>biometric information</li>
                        <li>information received by body corporate under lawful contract or otherwise</li>
                        <li>visitor details as provided at the time of registration or thereafter; and call data records</li>
                    </ul>
                    <br />
                    You may share your information directly through the Platform with students or teachers or clients or therapists, as applicable, to confirm and facilitate sessions. We may share your PII with third parties for promotional purposes with your permission.
                </li>
                <li className='my-3'>
                    <strong>Non-Personal Information</strong>   You have the option to provide us with non-personally identifiable information, such as interests, background, sample recordings (in the case of a teacher/therapists), and profile data. Additionally, during your visit to our website, we collect certain non-personal information, including details about your operating system, browser type, the URL of the previous website you visited, your Internet service provider, and your IP address. This non-personal information cannot easily be used to identify you personally. We may share your non-personal information with third parties for promotional purposes with your permission.
                </li>
                <li className='my-3'>
                    <strong>Usage Information.</strong>       We utilize your non-personal information for various purposes, including troubleshooting connection issues, administering the website, analysing trends, gathering demographic information, understanding how visitors use our website (such as frequency and duration of visits, and pages viewed), ensuring compliance with applicable laws, and cooperating with law enforcement activities. This information is instrumental in enhancing site content and performance.<br />
                    By using our website, you agree that Dhunguru may collect usage statistics and data to evaluate your use of our services, improve our services and website content, and ensure your compliance with the terms of applicable agreements between you and Dhunguru. The gathered statistics and data may be aggregated and do not contain information that distinctly identifies you.<br />
                    In the future, we may share this information with third-party service providers or advertisers to assess the overall effectiveness of our online advertising, content, and programming, as well as for other legitimate purposes as determined by Dhunguru. By using our website, you explicitly grant Dhunguru permission to access such information for one or more purposes deemed appropriate by Dhunguru.
                </li>
                <li className='my-3'>
                    <strong>Payment Information.</strong> Personally Identifiable Information (PII), also including bank account/UPI information, Legal ID (Aaddhar, PAN, Driving License etc.) that you provide concerning the making or receiving of payments through our Platform may be shared with third parties to facilitate processing of payments.
                </li>
                <li className='my-3'>
                    <strong>Opt-Out Option.</strong>  If you receive unsolicited emails from us regarding our products or services, we are committed to providing you with an opt-out mechanism. This allows you to request no further emails of such nature in the future. We appreciate your preferences and aim to maintain a professional and considerate communication experience.
                </li>
                <li className='my-3'>
                    <strong>Consent.</strong>  <sup>3</sup>By accessing https://dhungurus.com/ <strong>(“Platform”)</strong> and using it on a continued basis, you expressly consent and confirm to Dhunguru collecting, maintaining, using, processing and disclosing your personal and other information in accordance with this Privacy Policy. Pertaining to the Digital Data Protection Act, 2023, the parent/legal guardian has to consent to personal identifiable information of a student under the age of eighteen (18)<sup>4</sup>.
                </li>
                <li className='my-3'>
                    <strong>User Rights.</strong>  The Users possess the right to request access, update, rectify, and, under specific circumstances, deletion of the information held as Personally Identifiable Information (PII) and Sensitive Personal Data or Information (SPDI).<sup>5</sup> Furthermore, individuals reserve the right to object to or restrict the processing of their personal information; however, it is important to note that such actions may impact the benefits derived from our services. Additionally, users retain the right to withdraw their consent concerning the processing of personal information.<sup>6</sup><br />
                    <p className='mt-3'><strong>Note:</strong> Verification of identity may be required from users prior to addressing such requests. In the event that students/teachers/Clients/Therapists encounter difficulty in undertaking these actions independently, we encourage them to contact our Support team for assistance.</p>
                </li>
                <li className='my-3'>
                    <strong>Recordings.</strong>       Calls/Sessions that happen between students-teachers in case of Music Sessions and Clients-Therapists in case of Music Therapy, who are a part of Dhunguru, are recorded. Such recordings are made and kept for auditing purposes to ensure that teachers/therapists are adhering to the guidelines of the platform and its associated policies.<br />
                    For the purposes of audits, even demo classes and Free Assessments will be recorded to ensure teachers/therapists are adhering to the guidelines and policies of Dhunguru. Even regular online class/therapy sessions will be recorded for the same purpose.<br />
                    No recordings will be shared with any third parties or unauthorised personnel. They will be used for internal purposes only.
                </li>
                <li className='my-3'>
                    <strong>Data Protection<sup>7</sup>.</strong>      The security of Personal Information is of utmost importance as mandated by Digital Data Protection Act, 2023, and we have employed commercially acceptable measures to safeguard it. However, it is important to note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security. We have implemented industry-leading practices, security policies, rules, and technical measures to protect data, including Personal Information, under the Company’s control, from unauthorized access, improper use or disclosure, unauthorized modification, and unlawful destruction or accidental loss.<br />
                    <p className='my-3'>While we have taken significant steps to ensure the security of data, we cannot be held liable for any data loss or theft resulting from unauthorized access to the User's electronic devices used to avail the Services. We strongly advise against sharing your password with any third party. If you suspect that your password or account has been compromised, it is imperative to change it immediately and contact our support team for assistance. For communications involving sensitive personal information, such as financial details (e.g., bank account, credit card, debit card), if electronic transmission is not preferred, please reach out to us at: +918045683666<sup>8</sup><br /></p>
                    In the event that our partners or third parties disclose Personal Information beyond the scope outlined in our agreements with them, the Company cannot be held responsible for the breach of confidentiality. Additionally, in cases of security breaches or actions, including but not limited to computer hacking, unauthorized access to computer data and storage devices, and computer crashes, you acknowledge that the Company will not be held responsible in any manner.
                </li>
                <li className='my-3'>
                    <strong>Cookies.</strong>   We may place a unique cookie on your browser and utilize such a cookie to track your non-personally identifiable information while you are on our Platform. We or our business partners may also collect anonymous information, such as your IP address (a number used to identify your computer on the Internet) or the type of browser you are using ("Anonymous Information"), through the use of cookies or other means. Through the utilization of Anonymous Information, we aim to enhance the site's usability for you and other users. We reserve the right to maintain, update, disclose, or otherwise use Anonymous Information without limitation.
                </li>
                <li className='my-3'>
                    <strong>Third Party Sites.</strong>  As an integral part of the Platform, we may establish links that enable you to access third-party sites. We want to emphasize that we are not accountable for the content displayed on these external sites, and we do not endorse them. For information regarding their treatment of user information, we recommend referring to the respective privacy policies of these sites. Our commitment is to maintain a professional and transparent user experience.
                </li>
                <li className='my-3'>
                    <strong>Governing Rules and Law.</strong>  If you choose to visit our Platform, your visit/use and any dispute over privacy is subject to this Privacy Policy and the Platform's terms of use (Music Sessions and Music Therapy). In addition to the foregoing, any disputes arising under this Privacy Policy shall be governed by the laws of India. This Privacy Policy is compliant with the provisions of all applicable Indian laws including but not limited to the Digital Personal Data Protection Act,2023; Information Technology Act, 2000 and Rules made thereunder including any modifications or amendments made thereto. Dhunguru may make necessary changes to this Privacy Policy consequent upon any changes or modification in the law. It is hence imperative that you frequently read this Privacy Policy to keep yourself updated of any changes made by us.
                </li>
                <li className='my-3'>
                    <strong>Indemnity.</strong>  Users including but not limited to Students, Teachers, Clients, Therapists agree and undertake to indemnify Dhunguru in any suit or dispute by any third party arising out of disclosure of information by you to third parties either through our Site or App or otherwise and your use and access of websites, applications and resources of third parties. Dhunguru assume no liability for any actions of third parties with regard to your personal information (PII) or Sensitive Personal Data or Information (SPDI) which you may have disclosed to such third parties.
                </li>
                <li className='my-3'>
                    <strong>Severability.</strong>  Each clause of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other clauses herein except where otherwise expressly indicated or indicated by the context of the Privacy Policy. The decision or declaration that one or more clauses are null, and void shall have no effect on remaining clauses of this Privacy Policy.
                </li>
                <li className='my-3'>
                    <strong>Updates to the Policy.</strong>       We are committed to maintaining transparency in our information practices. As part of this commitment, we will update this Policy to reflect any changes. Changes to the Policy are effective immediately upon publication.<br />
                    <p className='my-3'>We encourage you to periodically review this page for the latest information on our privacy practices. Your continued use of our services following any modifications to this Policy signifies your acceptance of the updated terms.</p>
                    Thank you for placing your trust in us. If you have any questions or concerns regarding our privacy practices, please drop a WhatsApp query or Contact us at: <strong>+918045683666</strong>
                </li>
                {/* <li className='my-3'>
                    <strong>Contact Us.</strong>  If you have any questions about this Policy or our sites in general, please contact us at: privacy@Dhunguru.com<br /><br />
                    or<br /><br />
                    Dhunguru.com<br />
                    11th Floor, Tower B,<br />
                    Emaar Digital Greens, Sector 61<br />
                    Gurugram<br />
                    Haryana - 122011<br />
                    +91-9999955542<br />
                    Comments or questions are welcome.<br />
                </li> */}
            </ol>
            <hr style={{ width: '50%', alignSelf: 'start', height: '1px', backgroundColor: 'black' }} />
            <div>
                <p className='my-0'><sup>1</sup> Section 4 of Digital Data protection Act, 2023 provides for processing of personal data for lawful purpose.</p>
                <p className='my-0'><sup>2</sup> Section 3, Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. </p>
                <p className='my-0'><sup>3</sup> Section 6(1) of Digital Data protection Act, 2023 provides for consent. </p>
                <p className='my-0'><sup>4</sup> Section 9 (1) of Digital Data protection Act, 2023 provides for consent from parent/legal guardian for processing of personal data of children. </p>
                <p className='my-0'><sup>5</sup> Section 11 (1) of Digital Data protection Act, 2023, provides for right to access information about personal information and Section 12 provides for right to correction or erasure of personal data.</p>
                <p className='my-0'><sup>6</sup> Section 6(6) of Digital Data protection Act, 2023</p>
                <p className='my-0'><sup>7</sup> Section 2 (n) defines digital personal data; section 2 (t) defines personal data; </p>
                <p className='my-0'><sup>8</sup> Section 13 of Digital Data protection Act, 2023 provides for right of grievance redressal.</p>
            </div>
        </div>
        <hr class="mb-6" />
    </div>
)

export default Doc
