import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'


import AnimateHeight from 'react-animate-height'
import { logoutUser } from '../../../services/auth';
import { logoutSuccess } from '../../../store/actions/auth';
import { Store } from '../../../store'

import MelodyLogo from "../../../assets/MelodyMentorsLogo.svg"

const NavMobile = (props) => {

  const { state, dispatch } = useContext(Store);

  const [collapseNav, setCollapseNav] = useState(false);
  const [showUserDropDown, setShowUserDropDown] = useState(false)

  const [showDashboardNav, setShowDashboardNav] = useState(true)
  const [activeLink, setActiveLink] = useState('')
  const userDropDown = useRef(null);

  useEffect(() => {
    setCollapseNav(false)
  }, [activeLink])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  useEffect(() => {
    setActiveLink(props.location.pathname)
  }, [props.location.pathname])


  const handleClickOutside = (event) => {
    if (userDropDown.current && !userDropDown.current.contains(event.target)) {
      setShowUserDropDown(false)
    }
  };


  const handleLogout = async () => {
    try {
      logoutSuccess(dispatch);
      await logoutUser();
    } catch (e) {
      console.error(e);
    }
  }


  return (
    <Fragment >
      {/* <Notifications show = {showNotifications} setShow = {setShowNotifications}/> */}

      <nav className="navbar navbar-vertical fixed-left pb-0 navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <button className="navbar-toggler" onClick={() => setCollapseNav(!collapseNav)}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-brand">
            <Link to="/">
              {/* <img src={MelodyLogo} alt="" class='navbar-brand-img mx-auto'  /> */}

              <img src='https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/logo.png' className="navbar-brand-img mx-auto" alt="..." />
            </Link>
          </div>
          {(state.user.isLoggedIn && state.user.data.role === 'student') && <div className="navbar-user d-md-none">
            <div className="dropdown">
              <div className="dropdown-toggle" >
                <div className="avatar avatar-sm" onClick={() => setShowUserDropDown(true)}>
                  <img className="avatar-img rounded-circle" src={state.user.data.avatar} alt="..." />
                </div>
              </div>
              <div ref={userDropDown} className={`dropdown-menu dropdown-menu-right ${showUserDropDown ? 'show' : ''}`} >
                <div className='small pl-4 py-2 navbar-heading'>Hi, {state.user.data.firstName}</div>
                <Link to="/my-dashboard/profile" className='dropdown-item'>My Profile</Link>
                <hr className="dropdown-divider" />
                <div onClick={handleLogout} className="dropdown-item pointer">Log out</div>
              </div>
            </div>
          </div>}
          {(state.user.isLoggedIn && state.user.data.role === 'admin') && <div className="navbar-user d-md-none">
            <div className="dropdown">
              <div className="dropdown-toggle" >
                <div className="avatar avatar-sm" onClick={() => setShowUserDropDown(true)}>
                  <img className="avatar-img rounded-circle" src={state.user.data.avatar} alt="..." />
                </div>
              </div>
              <div ref={userDropDown} className={`dropdown-menu dropdown-menu-right ${showUserDropDown ? 'show' : ''}`} >
                <div className='small pl-4 py-2 navbar-heading'>Hi, {state.user.data.firstName}</div>
                <div onClick={handleLogout} className="dropdown-item pointer">Log out</div>
              </div>
            </div>
          </div>}
          {(state.user.isLoggedIn && state.user.data.role === 'instructor') && <div className="navbar-user d-md-none">
            <Link to="/instructor" className="avatar avatar-sm">
              <img className="avatar-img rounded-circle" src={state.user.data.avatar} alt="..." />
            </Link>
          </div>}
          {(!state.user.isLoggedIn) && <div className="navbar-user d-md-none mb-2">
            <Link to="/login" class="btn btn-primary">
              Login
            </Link>
          </div>}

          <div className={`collapse navbar-collapse ${collapseNav ? 'show' : ''}`}>
            <ul className='navbar-nav'>
              <li className="nav-item">
                <Link to="/" className={`nav-link ${activeLink === '/' && 'active'}`}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/instructors" className={`nav-link ${activeLink === '/instructors' && 'active'}`}>
                  Find you guru
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/how-it-works" className={`nav-link ${activeLink === '/how-it-works' && 'active'}`}>
                  How it works?
                </Link>
              </li>
              {(state.user.isLoggedIn && state.user.data.role === 'student') ? '' : <li className="nav-item">
                <Link to="/become-a-guru" className={`nav-link ${activeLink === '/become-a-guru' && 'active'}`}>
                  Become a Guru
                </Link>
              </li>}
              {(!state.user.isLoggedIn) && <li className="ml-3 mb-4">
                <Link to="/signup" className={` btn btn-primary`}>
                  Sign Up
                </Link>
              </li>}
              {(state.user.isLoggedIn && state.user.data.role === 'instructor') && <li className="ml-3 mb-4">
                <Link to="/instructor" className={` btn btn-primary`}>
                  Back to Dashboard
                </Link>
              </li>}
            </ul>

            {(state.user.isLoggedIn && state.user.data.role === 'student') && <>
              <hr className='navbar-divider my-3' />
              <ul className='navbar-nav mb-4'>
                <li className='nav-item'>
                  <div className={`${showDashboardNav && `text-dark nav-link1-show`} nav-link nav-link1 pointer`} onClick={() => setShowDashboardNav(prev => !prev)} >
                    My Dashboard
                  </div>
                  <AnimateHeight duration={300} height={showDashboardNav ? 'auto' : 0}>
                    <div className="collapse show">
                      <ul className="nav nav-sm flex-column mt-0">
                        <li className="nav-item">
                          <Link to="/my-dashboard/" className={`nav-link pl-4 ${activeLink === '/my-dashboard/' && 'active'}`}>
                            Home
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/my-dashboard/schedule" className={`nav-link pl-4 ${activeLink === '/my-dashboard/schedule' && 'active'}`}>
                          Manage Your Sessions
                          </Link>
                        </li>
                        {(state.user && state.user.data.role === 'student' && state.user.data.isRecordingAllowed) && <li className="nav-item">
                          <Link to="/my-dashboard/classes" className={`nav-link pl-4 ${activeLink === '/my-dashboard/classes' && 'active'}`}>
                            Sessions Recordings
                          </Link>
                        </li>}
                        {(state.user && state.user.data.role === 'student' && state.user.data.isRecordingAllowed) && <li className="nav-item">
                          <Link to="/my-dashboard/resources" className={`nav-link pl-4 ${activeLink === '/my-dashboard/resources' && 'active'}`}>
                          Find Your Notes
                          </Link>
                        </li>}
                        <li className="nav-item">
                          <Link to="/my-dashboard/messages" className={`nav-link pl-4 ${activeLink === '/my-dashboard/messages' && 'active'}`}>
                            Messages
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/my-dashboard/billing" className={`nav-link pl-4 ${activeLink === '/my-dashboard/billing' && 'active'}`}>
                            Get More Sessions
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/my-dashboard/profile" className={`nav-link pl-4 ${activeLink === '/my-dashboard/profile' && 'active'}`}>
                            Profile
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </AnimateHeight>
                </li>

              </ul></>}



          </div>
        </div>
      </nav>
    </Fragment>
  )
}


export default withRouter(NavMobile);




