import React, { useState, useEffect, useRef } from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import moment from 'moment'
import Table from '../../../../components/Table'
import { getDistinct } from '../../../../utilis/helpers'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CSVLink } from "react-csv";
import { BsCloudDownload } from 'react-icons/bs'
import { AiOutlineDelete } from 'react-icons/ai'
import Swal from 'sweetalert2'
import cogoToast from 'cogo-toast'
import RecordFine from './RecordFine'
import GST from './GST'
import { InputMaskItemSm } from '../../../../components/Forms'
import { FaSlidersH } from 'react-icons/fa'

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('1234567890abcdef', 10)


const Invoices = (props) => {

    const csvRef = useRef()
    const [tab, setTab] = useState('per_class')

    const [data, setData] = useState();
    const [cashFreeData, setCashFreeData] = useState([]);

    const [invoices, setInvoices] = useState([])
    const [bulkInvoices, setBulkInvoices] = useState([])
    const [allInvoices, setAllInvoices] = useState([]);
    const [finedData, setFinedData] = useState([]);
    const [paidInvoices, setPaidInvoices] = useState([]);

    const [searchInput, setSearchInput] = useState('');

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    const [amount, setAmount] = useState();
    const [comments, setComments] = useState('')

    const [showDelete, setShowDelete] = useState();
    const [showDateFilters, setShowDateFilters] = useState(false);
    const [show, setShow] = useState(false);
    const [showFine, setShowFine] = useState(false);
    const [instructor_id, setInstructor_id] = useState();
    const [dateFilters, setDateFilters] = useState({ start: '', end: '' })

    useEffect(() => {
        fetchInvoices();
        // eslint-disable-next-line
    }, [])


    const fetchInvoices = async () => {
        try {

            const res = await axios.get('/api/admin/invoices');
            setData(res.data);

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (data) {
            formatData(data.filter(item => (item.type === 'per_class' || item.type === 'instructor_invoice' || item.type === 'instructor_fine')), null, null);
            formatBulkTransfer(data.filter(item => (item.type === 'bulk_transfer')))
            formatAllInvoices(data.filter(item => (item.type === 'per_class')));
            formatFinedData(data.filter(item => (item.type === 'instructor_fine')));
            formatPaidInvoices(data.filter(item => (item.type === 'instructor_invoice')));
        }
        // eslint-disable-next-line 
    }, [data])


    const formatData = (data1, start_date, end_date) => {
        let data = data1
        const allInstructors = getDistinct(data.filter(item => item.recipient && item.recipient._id).map(item => item.recipient), '_id')
        if (start_date) {
            data = data.filter(item => moment(item.createdAt).isSameOrAfter(start_date, 'day'))
        }
        if (end_date) {
            data = data.filter(item => moment(item.createdAt).isSameOrBefore(end_date, 'day'))
        }
        const formattedData = allInstructors.map(item => {
            const instructor_invoices_total = data.filter(item2 => (item2.recipient && item2.recipient._id === item._id && item2.type === 'per_class' && item2.status !== 'cancelled'));
            const instructor_invoices_paid = data.filter(item2 => (item2.recipient && item2.recipient._id === item._id && item2.type === 'instructor_invoice' && item2.status !== 'cancelled'));
            const instructor_invoices_fine = data.filter(item2 => (item2.recipient && item2.recipient._id === item._id && item2.type === 'instructor_fine' && item2.status !== 'cancelled'));

            const total_amount = parseFloat(instructor_invoices_total.reduce((a, b) => a + parseFloat(b.amount || 0), 0)).toFixed(2)
            const fined_amount = parseFloat(instructor_invoices_fine.reduce((a, b) => a + parseFloat(b.amount || 0), 0) * -1).toFixed(2)
            const amount_paid = parseFloat(instructor_invoices_paid.reduce((a, b) => a + parseFloat(b.amount || 0), 0)).toFixed(2)

            const amount_after_fine = total_amount - fined_amount;

            const dhun_commission = item.instructor_profile.commission || process.env.REACT_APP_DHUNGURU_PERCENT

            const total_dhunguru_earnings = parseFloat((amount_after_fine) * (parseFloat(dhun_commission) * 0.01)).toFixed(2)
            const tds = parseFloat((amount_after_fine - total_dhunguru_earnings) * 0.01).toFixed(2) > 0 ? parseFloat((amount_after_fine - total_dhunguru_earnings) * 0.01).toFixed(2) : 0

            const amount_needed_to_be_paid_before_tds = amount_after_fine - amount_paid - total_dhunguru_earnings
            const amount_after_tds = parseFloat(amount_needed_to_be_paid_before_tds - tds).toFixed(2)

            return {
                name: item?.firstName + ' ' + item?.lastName,
                phone: item.phone,
                total_amount: { value: total_amount, label: '$' + total_amount },
                amount_paid: { value: amount_paid, label: '$' + amount_paid },
                fined_amount: { value: fined_amount, label: '$' + fined_amount },
                total_dhunguru_earnings: { value: total_dhunguru_earnings, label: '$' + total_dhunguru_earnings },
                amount_before_tds: { value: amount_needed_to_be_paid_before_tds, label: '$' + amount_needed_to_be_paid_before_tds },
                tds: { value: tds, label: '$' + tds },
                amount_after_tds: { value: amount_after_tds, label: '$' + amount_after_tds },
                account: item.instructor_profile.bankDetails.accountNum,
                bank: item.instructor_profile.bankDetails.bankName,
                branch: item.instructor_profile.bankDetails.branch,
                bank_acc_name: item.instructor_profile.bankDetails.name,
                ifsc: item.instructor_profile.bankDetails.ifsc,
                instructor_id: item._id,
                instructor: item,
                pay: { value: '', label: <div class='btn btn-sm btn-primary' onClick={() => { setInstructor_id(item._id); setShow(true) }}>Pay Now</div> },
            }
        })
        setInvoices(formattedData)
    }


    const formatPaidInvoices = data => {
        setPaidInvoices(data.map(item => ({
            ...item,
            instructor_name: item.recipient?.firstName + ' ' + item.recipient?.lastName,
            amount: item.amount,
            date1: { value: moment(item.date).unix(), label: moment(item.date).format('Do MMM YY, hh:mm A') },
            comments: item.comments,
        })))
    }


    const formatBulkTransfer = (data) => {
        setBulkInvoices(data.map(item => ({
            ...item,
            instructors: item.bulk_data.length,
            date1: { value: moment(item.date).unix(), label: moment(item.date).format('Do MMM YY, hh:mm A') },
            pay1: { value: item._id, label: item.status === 'paid' ? 'Paid' : <div class='btn btn-sm btn-primary' onClick={() => payInstructorBulk(item._id)}>Pay Now</div> },
            actions1: {
                value: 'a', label: <div class='btn-group btn-group-sm'>
                    <OverlayTrigger overlay={<Tooltip>Download CSV</Tooltip>}>
                        <CSVLink class='btn btn-primary border mb-0' filename="data.csv" data={item.bulk_data} headers={csvHeaders} target="_blank">
                            <BsCloudDownload />
                        </CSVLink>
                    </OverlayTrigger>
                    {item.status !== 'paid' && <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                        <span class='btn btn-primary border mb-0 ' onClick={() => setShowDelete(item._id)}>
                            <AiOutlineDelete />
                        </span>
                    </OverlayTrigger>}
                </div>
            },
        })))
    }


    const formatAllInvoices = (data) => {
        setAllInvoices(data.map(item => ({
            ...item,
            instructor_name: item.recipient?.firstName + ' ' + item.recipient?.lastName,
            enrollment_id: (item.enrollment && item.enrollment.enrollmentId) ? item.enrollment.enrollmentId : '',
            schedule_id: (item.schedule && item.schedule.bookingId) ? item.schedule.bookingId : '',
            amount: item.amount,
            date1: { value: moment(item.date).unix(), label: moment(item.date).format('Do MMM YY, hh:mm A') },
            comments: item.comments,
        })))
    }

    const formatFinedData = (data) => {
        setFinedData(data.map(item => ({
            ...item,
            instructor_name: item.recipient?.firstName + ' ' + item.recipient?.lastName,
            enrollment_id: (item.enrollment && item.enrollment.enrollmentId) ? item.enrollment.enrollmentId : '',
            schedule_id: (item.schedule && item.schedule.bookingId) ? item.schedule.bookingId : '',
            amount: item.amount,
            date1: { value: moment(item.date).unix(), label: moment(item.date).format('Do MMM YY, hh:mm A') },
            comments: item.comments,
        })))
    }

    useEffect(() => {
        if (showDelete) {
            deleteBulkInvoice(showDelete)
        }
        // eslint-disable-next-line 
    }, [showDelete])


    const payInstructor = async () => {
        try {
            if (amount < 0) {
                cogoToast.error("Please enter a positive amount")
                return;
            }
            setLoading(true)
            const res = await axios.post('/api/admin/invoice/payInstructor', { amount, instructor_id, comments })
            const newData = [...data];
            newData.push(res.data);
            setData(newData);

            setLoading(false)
            setShow(false)
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }


    const payInstructorBulk = async (id) => {
        try {
            const response = await Swal.fire({
                title: 'Are you sure you want to delete this bulk invoice recipiet?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmed!'
            });
            if (response.isConfirmed) {
                setLoading(id)
                const res = await axios.post(`/api/admin/invoice/payInstructor/bulk/${id}`);
                const newData = [...data, ...res.data];
                const index = newData.findIndex(item => item._id === id);
                newData[index].status = 'paid'
                setData(newData);
                cogoToast.success("Payment successfully recorded, please refresh to fetch new data!")
                setLoading('')
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }


    // const downloadData = async () => {
    //     try {
    //         if (invoices.filter(item => item.amount > 0).length === 0) {
    //             cogoToast.success("You have no dues")
    //             return;
    //         }
    //         setLoading1(true)
    //         const res = await axios.post('/api/admin/invoices/bulk-invoice', invoices.filter(item => parseInt(item.amount) > 0));

    //         const newData = [...data];
    //         newData.push(res.data);
    //         setData(newData);

    //         csvRef.current.link.click();
    //         setLoading1(false)
    //     } catch (err) {
    //         setLoading1(false)
    //         console.log(err)
    //     }
    // }

    const downloadForBulkTransferCashFree = () => {
        try {
            // if (invoices.filter(item => item.amount > 0).length === 0) {
            //     cogoToast.success("You have no dues")
            //     return;
            // }
            setLoading1(true)

            const cash_free_data = invoices.map((invoices_data, index) => {
                return {
                    transferId: nanoid(),
                    bankAccount: invoices_data.account,
                    ifsc: invoices_data.ifsc,
                    name: invoices_data.bank_acc_name || "N.A.",
                    email: invoices_data.instructor.email,
                    phone: invoices_data.phone,
                    amount: invoices_data.amount_after_tds.value,
                    remarks: "Sessions From",
                    transferMode: "imps"
                }
            })
            setCashFreeData(cash_free_data);

            csvRef.current.link.click();
            setLoading1(false)
        } catch (err) {
            setLoading1(false)
            console.log(err)
        }
    }


    const deleteBulkInvoice = async (id) => {
        try {
            const response = await Swal.fire({
                title: 'Are you sure you want to delete this bulk invoice recipiet?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            if (response.isConfirmed) {
                const newBulkInvoices = [...bulkInvoices]
                // console.log(newBulkInvoices)
                const index = newBulkInvoices.findIndex(item => item._id === id);
                newBulkInvoices.splice(index, 1);
                setBulkInvoices(newBulkInvoices)
                setShowDelete()
                await axios.delete(`/api/admin/invoices/bulk-invoice/${id}`);
            }

        } catch (err) {
            console.log(err)
        }
    }


    const clearDateFilters = (e) => {
        e.preventDefault();
        formatData(data, null, null)
        setDateFilters({ start: "", end: "" })
        setShowDateFilters(false)
    }

    const applyDateFilters = (e) => {
        e.preventDefault();
        console.log(1)
        if (moment(dateFilters.start + ', 00:01 AM', 'DD/MM/YYYY, hh:mm A').isValid() && moment(dateFilters.end + ', 23:59 PM', 'DD/MM/YYYY, hh:mm A').isValid()) {
            formatData(data, moment(dateFilters.start + ', 00:01 AM', 'DD/MM/YYYY, hh:mm A'), moment(dateFilters.end + ', 23:59 PM', 'DD/MM/YYYY, hh:mm A'))
            setShowDateFilters(false)
        } else {
            alert("Date entered are not correct!")
        }
    }


    return (
        <AdminDashboard showTitle={false} padding="low" {...props}>
            <div class='header mt-3'>
                <div class='header-body'>
                    <div className='row align-items-end'>
                        <div className='col'>
                            <h3 class='h1 mb-0'>Invoices</h3>
                        </div>
                        <div class='col-auto'>
                            {tab === 'per_class' && <div class='btn btn-primary' onClick={() => downloadForBulkTransferCashFree()}>
                                {loading1 && <span class='spinner-border spinner-border-sm mr-2'></span>}
                                Download Current Data for Cash Free
                            </div>}
                        </div>
                        <div class='col-auto'>
                            {tab === 'per_class' && <div class=''>
                                <DateFilters
                                    clearDateFilters={clearDateFilters}
                                    showDateFilters={showDateFilters}
                                    setShowDateFilters={setShowDateFilters}
                                    applyDateFilters={applyDateFilters}
                                    dateFilters={dateFilters}
                                    setDateFilters={setDateFilters}
                                />
                            </div>}
                        </div>
                        <div class='col-auto'>
                            {tab === 'fines' && <div class='btn btn-primary' onClick={() => setShowFine(true)}>
                                {loading1 && <span class='spinner-border spinner-border-sm mr-2'></span>}
                                Add Fine
                            </div>}
                        </div>
                    </div>
                    <AccountNav tab={tab} setTab={setTab} />
                </div>
            </div>

            {tab === 'per_class' &&
                <Table
                    data={invoices}
                    headers={headers}
                    isSelectable={false}
                    isSearchable={true}
                    handleRowClick={(id) => { console.log('') }}
                    isHeader={true}
                    isFooter={true}
                    isSortable={true}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    loading={loading}
                    startPayment={payInstructorBulk}
                    filters={[]}
                    isDownloadable={true}
                />
            }

            {tab === 'bulk_transfer' &&
                <Table
                    data={bulkInvoices}
                    headers={bulkHeaders}
                    isSelectable={false}
                    isSearchable={true}
                    handleRowClick={(id) => { console.log('') }}
                    isHeader={true}
                    isFooter={true}
                    isSortable={true}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    loading={loading}
                    startPayment={payInstructorBulk}
                    filters={[]}
                    isDownloadable={true}
                />
            }

            {tab === 'all_invoices' &&
                <Table
                    data={allInvoices}
                    headers={allInvoicesHeaders}
                    isSelectable={false}
                    isSearchable={true}
                    handleRowClick={(id) => { console.log('') }}
                    isHeader={true}
                    isFooter={true}
                    isSortable={true}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    loading={loading}
                    startPayment={payInstructorBulk}
                    filters={['enrollment_id', 'schedule_id', 'instructor_name']}
                    dateFilter="date1"
                    isDownloadable={true}
                />
            }


            {tab === 'fines' &&
                <Table
                    data={finedData}
                    headers={finedHeaders}
                    isSelectable={false}
                    isSearchable={true}
                    handleRowClick={(id) => { console.log('') }}
                    isHeader={true}
                    isFooter={true}
                    isSortable={true}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    loading={loading}
                    startPayment={payInstructorBulk}
                    filters={['enrollment_id', 'schedule_id', 'instructor_name']}
                    dateFilter="date1"
                    isDownloadable={true}
                />
            }


            {tab === 'paid_invoices' &&
                <Table
                    data={paidInvoices}
                    headers={paidInvoicesHeader}
                    isSelectable={false}
                    isSearchable={true}
                    handleRowClick={(id) => { console.log('') }}
                    isHeader={true}
                    isFooter={true}
                    isSortable={true}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    filters={[]}
                    dateFilter="date1"
                    isDownloadable={true}
                />
            }
            {tab === 'gst_details' && <GST />}

            <RecordPayment
                show={show}
                setShow={setShow}
                amount={amount}
                setAmount={setAmount}
                comments={comments}
                setComments={setComments}
                loading={loading}
                payInstructor={payInstructor}
            />
            <RecordFine
                show={showFine}
                setShow={setShowFine}
                data={data}
                setData={setData}
            />
            {cashFreeData.length > 0 &&
                <CSVLink
                    data={cashFreeData}
                    headers={csvHeaders}
                    filename="cashfree.csv"
                    className="hidden"
                    ref={csvRef}
                    target="_blank"
                />
            }



        </AdminDashboard>
    )
}

export default Invoices





const AccountNav = ({ tab, setTab }) => {
    return (
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'per_class' && 'active'}`} onClick={() => setTab('per_class')}>Invoices</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'bulk_transfer' && 'active'}`} onClick={() => setTab('bulk_transfer')}>Bulk Transfer Data</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'all_invoices' && 'active'}`} onClick={() => setTab('all_invoices')}>Class wise Invoices</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'fines' && 'active'}`} onClick={() => setTab('fines')}>Instructor Fines</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'paid_invoices' && 'active'}`} onClick={() => setTab('paid_invoices')}>Paid Invoices</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'gst_details' && 'active'}`} onClick={() => setTab('gst_details')}>GST Details</div>
                    </li>
                </ul>
            </div>
        </div>
    )
}




const RecordPayment = ({ show, setShow, amount, comments, setComments, setAmount, loading, payInstructor }) => {
    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <div class='modal-content'>
                <div class='modal-body'>
                    <div class='form-group'>
                        <label>Please enter the amount that you will be paying</label>
                        <input class='form-control' value={amount} onChange={e => setAmount(e.target.value)} type="number" />
                    </div>
                    <div class='form-group'>
                        <label>Please enter details about the invoice in few words</label>
                        <input class='form-control' value={comments} onChange={e => setComments(e.target.value)} />
                    </div>
                    <div class='btn btn-primary' onClick={() => payInstructor()}>
                        {loading && <span class='spinner-border spinner-border-sm mr-2'></span>}
                        Record Payment
                    </div>
                </div>
            </div>
        </Modal>
    )
}






const DateFilters = ({ value, onChange, clearDateFilters, applyDateFilters, setShowDateFilters, showDateFilters, dateFilters, setDateFilters }) => {

    const dropdown = React.useRef(null);
    const handleClickOutside = event => {
        if (dropdown.current && !dropdown.current.contains(event.target)) {
            setShowDateFilters(false)
        }
    };

    // eslint-disable-next-line
    useEffect(() => document.addEventListener("mousedown", handleClickOutside), [])


    return (
        <div className="dropdown" ref={dropdown}>
            <button className="btn  btn-white border d-flex align-items-center" type="button" onClick={() => setShowDateFilters(true)}>
                <FaSlidersH className='mr-1' size={13} />
                Date Filters
            </button>
            {showDateFilters && <form className={`dropdown-menu dropdown-menu-right dropdown-menu-card ${showDateFilters ? 'show' : ''}`} >
                <div className="card-header">
                    <h4 className="card-header-title">
                        Filters
                    </h4>
                    <button className="btn btn-sm btn-link text-reset" onClick={(e) => clearDateFilters(e)}>
                        <small>Clear filters</small>
                    </button>
                </div>
                <div className="card-body">
                    <div className="list-group list-group-flush mt-n4 mb-4">
                        <div className="list-group-item" >
                            <div className="row">
                                <div className="col-auto">
                                    <InputMaskItemSm
                                        error={false}
                                        setError={() => { }}
                                        errorText=""
                                        placeholder={"DD/MM/YYYY"}
                                        mask={"99/99/9999"}
                                        value={dateFilters.start}
                                        setValue={e => setDateFilters(prev => ({ ...prev, start: e }))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="list-group-item" >
                            <div className="row">
                                <div className="col-auto">
                                    <InputMaskItemSm
                                        error={false}
                                        setError={() => { }}
                                        errorText=""
                                        placeholder={"DD/MM/YYYY"}
                                        mask={"99/99/9999"}
                                        value={dateFilters.end}
                                        setValue={e => setDateFilters(prev => ({ ...prev, end: e }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-block btn-primary" type="submit" onClick={(e) => applyDateFilters(e)}>
                        Apply Filters
                    </button>
                </div>
            </form>}
        </div>

    )

}



const headers = [
    { value: 'name', active: true, label: 'Name' },
    { value: 'total_amount', active: true, label: 'Total Amount' },
    { value: 'total_dhunguru_earnings', active: true, label: 'Dhunguru Earnings' },
    { value: 'amount_paid', active: true, label: 'Amount Paid' },
    { value: 'fined_amount', active: true, label: 'Total Fine' },
    { value: 'tds', active: true, label: 'TDS Deduced' },
    { value: 'amount_after_tds', active: true, label: 'Amount to be Paid' },
    { value: 'pay', active: true, label: 'Record Payment' },
]


const bulkHeaders = [
    { value: 'pay1', active: true, label: 'Record Payment' },
    { value: 'receipt_id', active: true, label: 'Receipt Id' },
    { value: 'amount', active: true, label: 'Amount' },
    { value: 'instructors', active: true, label: '# Instructors' },
    { value: 'date1', active: true, label: 'Date' },
    { value: 'status', active: true, label: 'Status' },
    { value: 'actions1', active: true, label: 'Actions' },
]



const allInvoicesHeaders = [
    { value: 'receipt_id', active: true, label: 'Receipt Id' },
    { value: 'instructor_name', active: true, label: 'Instructor' },
    { value: 'enrollment_id', active: true, label: 'Enrollment Id' },
    { value: 'schedule_id', active: true, label: 'Schedule Id' },
    { value: 'amount', active: true, label: 'Amount' },
    { value: 'date1', active: true, label: 'Date' },
    { value: 'comments', active: true, label: 'Comments' },
]


const finedHeaders = [
    { value: 'receipt_id', active: true, label: 'Receipt Id' },
    { value: 'instructor_name', active: true, label: 'Instructor' },
    { value: 'enrollment_id', active: true, label: 'Enrollment Id' },
    { value: 'schedule_id', active: true, label: 'Schedule Id' },
    { value: 'amount', active: true, label: 'Amount' },
    { value: 'date1', active: true, label: 'Date' },
    { value: 'comments', active: true, label: 'Comments' },
]


const paidInvoicesHeader = [
    { value: 'receipt_id', active: true, label: 'Receipt Id' },
    { value: 'instructor_name', active: true, label: 'Instructor' },
    { value: 'amount', active: true, label: 'Amount' },
    { value: 'date1', active: true, label: 'Date' },
    { value: 'comments', active: true, label: 'Comments' },
]


const csvHeaders = [
    { label: 'transferId', key: 'transferId' },
    { label: 'bankAccount', key: 'bankAccount' },
    { label: 'ifsc', key: 'ifsc' },
    { label: 'name', key: 'name' },
    { label: 'email', key: 'email' },
    { label: 'phone', key: 'phone' },
    { label: 'amount', key: 'amount' },
    { label: 'remarks', key: 'remarks' },
    { label: 'transferMode', key: 'transferMode' }
]
