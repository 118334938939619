import React from 'react'
import LgFooter from '../Footer'
import Nav from '../Nav'
import {Link} from 'react-router-dom'
import content from '../../../content/landingPage.json'
// import Swiper, {Navigation, Pagination, Autoplay, Scrollbar, Parallax, EffectFade, Controller} from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Parallax, EffectFade, Controller]);

const About = (props) => {


    return (
        <div>
            <Nav/>
            <Hero />
            <WhoWeAre />
            <ContactUs />
            <LgFooter />
        </div>
    )
}

export default About







const Hero = () => {
  return (
      <section class="py-6 d-flex align-items-center" style={{height: '80vh', backgroundImage: `url(${content.aboutUs.hero.img})`, backgroundSize: 'cover', boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0,.4)'}}>
          <div className="container my-6  text-white px-lg-6">
              <h3 class="display-1 mb-2 pt-5 px-3 px-lg-0">{content.aboutUs.hero.title}</h3>
          </div>
      </section>
  )
}


export const WhoWeAre = () => {

//   const swiper = useRef(null)
//   const swiperMe = useRef(null)

//   useEffect(() => {
//       swiperMe.current = new Swiper(swiper.current,{
//           slidesPerView: 1,
//           speed: 600,
//           loop: true,
//           parallax: true,
//           autoplay: true,
//           onSwiper: (swiper) => console.log(swiper),
//           onSlideChange: () => console.log('slide change')
//       })
//   }, [])



  return (
      <div class="py-6 mt-6 px-lg-5 px-2">
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-12 col-lg-10">
                          <div class="row  ">
                              <div class="col-12 col-md-10 col-lg-9 " >
                                  <p class="h1" >{content.aboutUs.whoWeAre.smallTitle}</p>
                                  <h3 class="h2"  > {content.aboutUs.whoWeAre.text1}</h3>
                              </div>
                              <div class="col-12 col-md-6 mt-lg-4 pr-6" >
                                  <p class="lead mb-3">
                                    <h3 class = "h1">Our Mission:</h3>
                                    We are on a mission towards eliminating geographical barriers within the widely spread musical fraternity. If you are a music enthusiast wishing to learn music, no matter where you are in the world, you can follow your passion with Dhunguru.
                                  </p>
                                  {/* <img class="w-100 " src={content.aboutUs.whoWeAre.img1} alt="" /> */}
                              </div>
                              <div class="col-12 col-md-6 mt-lg-4" >
                                 
                                  <p class="lead mb-0">
                                    Dhunguru was conceptualized when the COVID-19 pandemic shook the world. Students could not venture out of homes for their classes, and teachers lost their students due to the growing lockdown phases. <br/><br/>
                                    Students could not keep up with their practices, and teachers lost their students, losing their passion and jobs. Teachers could no longer earn this way, and it was a challenging phase for the world of music.<br/><br/>
                                    Dhunguru came up at this juncture to address this growing concern across the world.<br/><br/>
                                    At Dhunguru, we empower students to build their own musical journey by choosing all the different aspects - the type of instrument, the relevant Guru, and the number and timing of sessions - to exchange their experiences and ideas, all within the constraints of their time and budget.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  )
}



const ContactUs = () => {
  return (
    <div class="container pt-6">
        <div class="pb-6 text-center text-md-left">
            <hr class="mt-0 mb-100 pb-6" />
            <div class="row gv-1 pb-7 mb-n30 align-items-center justify-content-center mt-6 justify-content-md-between hover-container">
                <div class="col-md-10 col-lg-8 mr-md-auto">
                    <div class="interactive-links">
                        <Link to = "/contact" class="text-dark display-1 "><u>Let’s work</u><br/> together</Link>
                    </div>
                </div>
                <div class="col-auto">
                    <Link to="/contact" class="btn btn-clean mt-17 mt-md-0 mr-xl-100">
                        <svg class="icon-arrow icon-arrow-right" width="69" height="30" viewBox="0 0 69 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M54 2L67 15L54 28" stroke="currentColor" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17 15L67 15" stroke="currentColor" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}


