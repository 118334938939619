import React, { useState, useContext, useEffect, useMemo } from "react";
import { validateEmail, cleanPhone, validatePhoneNumberForEveryCountry } from '../../../utilis/validation'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { Store } from '../../../store'
import { FcGoogle } from 'react-icons/fc'
import { ImFacebook } from 'react-icons/im'
import axios from '../../../utilis/axios'
import { Link } from 'react-router-dom'
import { loginSuccess } from '../../../store/actions/auth'
import cogoToast from 'cogo-toast'
import PhoneInput from "react-phone-input-2";
import Select from 'react-select'
import countryList from 'react-select-country-list'

const RegisterTeacher = (props) => {

  const [state, setState] = useState({ firstName: "", title: "Mr", lastName: "", email: "", instrumentToTeach: "", phone: "", countryCode: "", password: "", tnc: false, tnc2: false, country: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(Store);
  const [errors, setErrors] = useState(Array(8).fill(false))
  const [phoneInput, setPhoneInput] = useState();
  const [countryCode, setCountryCode] = useState('')
  const options = useMemo(() => countryList().getData(), [])

  const changeHandler = countryValue => {
    console.log(countryValue);
    setState((prev) => ({
      ...prev,
      country: countryValue.label,
    }));
  }

  const validate = () => {
    let new_errors = Array(8).fill(false)
    if (state.firstName.length < 3) new_errors[0] = true;
    if (!validateEmail(state.email)) new_errors[1] = true;
    if (!validatePhoneNumberForEveryCountry(countryCode, state.phone)) {
      console.log('Invalid called')
      new_errors[2] = true
    };
    if (state.password.length < 8) new_errors[3] = true;
    if (state.instrumentToTeach.length === 0) new_errors[4] = true;
    if (!state.tnc) new_errors[5] = true;
    // if (!state.tnc2) new_errors[6] = true;
    if (state.country.length < 2) new_errors[7] = true;
    setErrors(new_errors)
    if (new_errors.includes(true)) return false;
    else return true;
  }


  const register = async (e) => {
    e.preventDefault()
    if (!validate()) return false;
    try {
      setLoading(true);
      const res = await axios.post('/api/auth/register/instructor', { ...state, email: state.email.toLowerCase(), phone: cleanPhone(state.phone) })
      if (res.data === 'already_exists') {
        cogoToast.error('User already exists, please login')
      } else {
        loginSuccess(res.data, dispatch)
        props.history.push({
          pathname: '/instructor/',
          query: 'new-user'
        })
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err)
    }
  };

  useEffect(() => {
    setErrors(Array(8).fill(false))
  }, [state])


  const loginGoogle = () => {
    if (!state.tnc) {
      const newErrors = Array(7).fill(false)
      if (!state.tnc) newErrors[5] = true;
      // if (!state.tnc2) newErrors[6] = true;
      setErrors(newErrors)
    } else {
      window.open(`${process.env.REACT_APP_WEBSITE}/api/auth/google/instructor`, "_self");
    }
  }

  const loginFacebook = () => {
    if (!state.tnc) {
      const newErrors = Array(7).fill(false)
      if (!state.tnc) newErrors[5] = true;
      // if (!state.tnc2) newErrors[6] = true;
      setErrors(newErrors)
    } else {
      window.open(`${process.env.REACT_APP_WEBSITE}/api/auth/facebook/instructor`, "_self");
    }
  }



  return (
    <div
      className="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
      style={{ minHeight: "100vh" }}
    >
      <div className="container-fluid">
        <div className="row justify-content-center">

          <div className="col-12 col-md-6 col-lg-6 col-xl-6 align-self-center px-xl-6 my-5 ">
            <h1 className="display-4 text-center mb-3">Register with us</h1>
            <p className="text-muted text-center mb-5">
              Free access to our dashboard.
            </p>
            <form className='mx-xl-6'>
              <div class="row no-gutters">
                <div class="col-12 col-lg-2">
                  <div className="form-group">
                    <label>Title</label>
                    <select
                      className={`form-control `}
                      value={state.title}
                      onChange={(e) => setState(prev => ({ ...prev, title: e.target.value }))}
                    >
                      <option value="Mr">Mr</option>
                      <option value="Ms">Ms</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Dr">Dr</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-lg-5">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className={`form-control ${errors[0] && 'is-invalid'} `}
                      value={state.firstName}
                      onChange={(e) => setState(prev => ({ ...prev, firstName: e.target.value }))}
                    />
                    <div class='invalid-feedback'>Name should be min 3 characters</div>
                  </div>
                </div>
                <div class="col-12 col-lg-5">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className='form-control'
                      value={state.lastName}
                      onChange={(e) => setState(prev => ({ ...prev, lastName: e.target.value }))}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  class={`form-control ${errors[1] && 'is-invalid'} `}
                  value={state.email}
                  onChange={(e) => setState(prev => ({ ...prev, email: e.target.value }))}
                  type="email"
                />
                <div class='invalid-feedback'>Enter a right email</div>
              </div>
              <div className="form-group">
                <label>Phone</label>
                {/* <input
                    className={`form-control ${errors[2] && 'is-invalid'} `}
                    value = {state.phone}
                    onChange = {(e) => setState(prev => ({...prev, phone: e.target.value}))}
                    type = "phone"
                /> */}
                <PhoneInput
                  country={"us"}
                  value={phoneInput}
                  onChange={(e, data) => {
                    console.log(data);
                    const reducedPhone = e.replace(data.dialCode, "");
                    console.log(reducedPhone, 'reducedPhone');
                    setPhoneInput(e)
                    setState((prev) => ({ ...prev, phone: reducedPhone, countryCode: data?.dialCode }))
                    setCountryCode(data?.countryCode);
                  }}
                  containerStyle={{ width: "100%" }}
                  inputClass="form-control"
                  containerClass={`${errors[2] && "is-invalid"}`}
                  inputStyle={{
                    minWidth: "100%",
                    borderColor: `${errors[2] ? "red" : ""} `,
                  }}
                />
                <div class='invalid-feedback'>Phone is invalid</div>
              </div>

              <div className='form-group '>
                <label class='bold mb-1'>Country</label>
                <Select options={options} onChange={changeHandler} class={`${errors[6] && "is-invalid"}`} />
                {errors[6] && <div style={{ color: '#e63757', fontSize: '0.8125rem' }}>Select a valid country</div>}
              </div>
              <div className="form-group">
                <label>Instrument you want to teach?</label>
                <select
                  class={`form-control ${errors[4] && 'is-invalid'} `}
                  value={state.instrumentToTeach}
                  onChange={(e) => setState(prev => ({ ...prev, instrumentToTeach: e.target.value }))}
                >
                  {instumentList.map(item => <option value={item.value}>{item.label}</option>)}
                </select>
                <div class='invalid-feedback'>Please enter at least one instrument</div>
              </div>

              <div className="form-group">
                <label>Password</label>
                <div className="input-group input-group-merge">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control form-control-append ${errors[3] && 'is-invalid'} `}
                    placeholder='Password'
                    value={state.password}
                    onChange={(e) => setState(prev => ({ ...prev, password: e.target.value }))}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                      {!showPassword ? (<AiOutlineEye className='pointer' size={15} />) : (<AiOutlineEyeInvisible className='pointer' size={15} />)}
                    </span>
                  </div>
                  <div className="invalid-feedback">Please enter a password</div>
                </div>
              </div>

              <div className='d-flex align-items-center mt-4'>
                <div className='mr-2'>
                  <input className='' type='checkbox' checked={state.tnc} onClick={() => setState(prev => ({ ...prev, tnc: !prev.tnc }))} />
                </div>
                <div className=''>I agree to the site's <Link to="/legal">terms of use</Link> and <Link to="/refund-policy">refund policy</Link>.</div>
              </div>
              {/* <div className='d-flex align-items-center mt-3 mb-4'>
                <div className='mr-2'>
                  <input className='' type='checkbox' checked={state.tnc2} onClick={() => setState(prev => ({ ...prev, tnc2: !prev.tnc2 }))} />
                </div>
                <div className=''>I allow Dhunguru to record and save all my sessions conducted via Dhunguru for business purposes.</div>
              </div> */}
              {(errors[5]) && <div class='invalid-feedback d-block'>Please go through our terms and conditions first</div>}


              <div className='d-flex align-items-center justify-content-between '>
                <div onClick={loginGoogle} className="btn btn-block btn-outline-secondary mt-2 mb-3 d-inline-flex align-items-center justify-content-center mr-3">
                  <FcGoogle class='h2 mb-0 mr-2' /> Sign up with google
                </div>
                <div onClick={loginFacebook} className="btn btn-block btn-outline-secondary mt-2 mb-3 d-inline-flex align-items-center justify-content-center">
                  <ImFacebook class='h2 mb-0 mr-2' style={{ color: '#3B5998' }} /> Sign up with Facebook
                </div>
              </div>


              <button className="btn btn-lg btn-block btn-primary mt-2 mb-3" onClick={(e) => register(e)}>
                <span className={loading ? `spinner-border spinner-border-sm mr-2` : ''}></span>
                Register
              </button>
            </form>
          </div>

          <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-none d-lg-block align-self-center">
            <div class="text-center">
              <img src="https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/login.svg" alt="..." class="img-fluid" style={{ height: '400px' }} />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default RegisterTeacher;


const instumentList = [
  { value: 'guitar', label: "Guitar" },
  { value: 'piano', label: "Piano" },
  { value: 'drums', label: "Drums" },
  { value: 'keyboard', label: "Keyboard" },
  { value: 'violin', label: "Violin" },
  { value: 'ukulele', label: "Ukulele" },
  { value: 'cajon', label: "Cajon" },
  { value: 'harmonium', label: "Harmonium" },
  { value: 'flute', label: "Flute" },
  { value: 'tabla', label: "Tabla" },
  { value: 'veena', label: "Veena" },
  { value: 'trumpet', label: "Trumpet" },
  { value: 'mridangam', label: "Mridangam" },
  { value: 'sitar', label: "Sitar" },
  { value: 'singing', label: "Singing" },
  { value: 'songWriting', label: "Song Writing" },
  { value: 'musicComposition', label: "Music Production" },
  { value: 'musicTheory', label: "Music Theory" },
  { value: 'rap', label: "Rap" },
  { value: 'musicTherapy', label: "Music Therapy" },
  { value: 'dJing', label: "DJing" },
  { value: 'dhol', label: "Dhol" },
  { value: 'harmonica', label: "Harmonica" },
  { value: 'mandolin', label: "Mandolin" },
  { value: 'doubleBass', label: "Double Bass" },
  { value: 'cello', label: "Cello" },

]
