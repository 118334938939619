import React from 'react'
import RightModal from '../../../../components/Modals/RightSide'


const EditLogsDetails = ({show, setShow, data}) => {
    

    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'mb-5' id = 'details'>
                <div className = 'h2'>Comments</div>
                <div>{data.comments}</div>
            </div>
            {(data.changes && data.changes.old) && <div className = 'w-100 h-100 mt-1'>
                {(data.changes.old &&  data.changes.new) && <div class='mb-5'>
                    <div className = 'h2'>Changes</div>
                    <pre dangerouslySetInnerHTML={{
                        __html: JSON.stringify(diff(data.changes.new, data.changes.old), null, 2),
                    }} />
                </div>}
                
                {(data.changes.old) && <div class='mb-5'>
                    {!data.changes.new ? <div className = 'h2'>Targeted Document</div> : <div className = 'h2'>Old Document</div>}
                    <pre dangerouslySetInnerHTML={{
                        __html: JSON.stringify(cleanObject(data.changes.old), null, 2),
                    }} />
                </div>}

                {(data.changes.new) && <div class='mb-5'>
                    <div className = 'h2'>New Document</div>
                    <pre dangerouslySetInnerHTML={{
                        __html: JSON.stringify(cleanObject(data.changes.new), null, 2),
                    }} />
                </div>}

                
            </div>}
        </RightModal>
    )
}

export default EditLogsDetails




function diff(obj1,obj2) {
    var r = {};

    let exclude = ['instructor',  'user', 'instructor_profile'];

    for (var prop in obj1) {
        if (obj1.hasOwnProperty(prop) && prop != '__proto__') {
            if (exclude.indexOf(prop) == -1) {
                // check if obj2 has prop
                if (!obj2.hasOwnProperty(prop)) r[prop] = obj1[prop];

                // check if prop is object and 
                // NOT a JavaScript engine object (i.e. __proto__), if so, recursive diff
                else if (obj1[prop] === Object(obj1[prop])) {
                    var difference = diff(obj1[prop], obj2[prop]);
                    if (Object.keys(difference).length > 0) r[prop] = difference;
                }

                // check if obj1 and obj2 are equal
                else if (obj1[prop] !== obj2[prop]) {
                    if (obj1[prop] === undefined)
                        r[prop] = 'undefined';
                    if (obj1[prop] === null)
                        r[prop] = null;
                    else if (typeof obj1[prop] === 'function')
                        r[prop] = 'function';
                    else if (typeof obj1[prop] === 'object')  
                        r[prop] = 'object';
                    else
                        r[prop] = obj2[prop] + " -> " + obj1[prop];
                }
            }   
        }
    
    }

    return r;
}

const cleanObject = (obj) => {
    console.log(obj)
    const excludes = ['notifications', 'availability'];
    const newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && excludes.indexOf(key) == -1) {
            if (typeof obj[key] === 'object') {
                newObj[key] = cleanObject(obj[key]);
            } else {
                newObj[key] = obj[key];
            }
        }
    }
    return newObj
}

