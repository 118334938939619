import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import { Link } from "react-router-dom";
import Notifications from "./Notifications";
import Badge from "@material-ui/core/Badge";
import {
  AiFillBell,
  AiOutlineSchedule,
  AiOutlineCalendar,
} from "react-icons/ai";
import { ImUsers } from "react-icons/im";
import { FaChalkboardTeacher, FaMoneyCheckAlt } from "react-icons/fa";
import { RiDashboardLine, RiProfileLine } from "react-icons/ri";
import { MdAccountCircle } from "react-icons/md";
import { BsChatSquareDots } from "react-icons/bs";
import AnimateHeight from "react-animate-height";
import { logoutUser } from "../../services/auth";
import { logoutSuccess } from "../../store/actions/auth";
import { Store } from "../../store";


const NavBar = (props) => {
  const { state, dispatch } = useContext(Store);
  const [collapseNav, setCollapseNav] = useState(false);
  const [showUserDropDown, setShowUserDropDown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserSettings, setShowUserSettings] = useState(
    props.location.pathname.includes("account") ? true : false
  );
  const [activeLink, setActiveLink] = useState("");
  const userDropDown = useRef(null);

  useEffect(() => {
    setCollapseNav(false);
  }, [activeLink]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    setActiveLink(props.location.pathname);
  }, [props.location.pathname]);

  const handleClickOutside = (event) => {
    if (userDropDown.current && !userDropDown.current.contains(event.target)) {
      setShowUserDropDown(false);
    }
  };

  const handleLogout = async () => {
    try {
      logoutSuccess(dispatch);
      await logoutUser();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Fragment>
      <Notifications show={showNotifications} setShow={setShowNotifications} />

      <nav className="navbar navbar-vertical fixed-left pb-0 navbar-expand-md navbar-light">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            onClick={() => setCollapseNav(!collapseNav)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-brand">
            <Link to="/">
              <img
                src="https://dhunguru-static-data-logos.s3.ap-south-1.amazonaws.com/logo.png"
                alt=""
                class="navbar-brand-img"
                style={{ maxHeight: "46px" }}
              />
              {/* <img src='https://dhunguru-static-data-logos.s3.ap-south-1.amazonaws.com/logo.png' className="navbar-brand-img mx-auto" alt="..." /> */}
              {/* <img src='https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/logo.png' className="navbar-brand-img mx-auto" alt="..." /> */}
            </Link>
          </div>
          <div className="navbar-user d-md-none">
            <div className="dropdown">
              <div className="dropdown-toggle">
                <div
                  className="avatar avatar-sm"
                  onClick={() => setShowUserDropDown(true)}
                >
                  <img
                    className="avatar-img rounded-circle"
                    src={state.user.data.avatar}
                    alt="..."
                  />
                </div>
              </div>
              <div
                ref={userDropDown}
                className={`dropdown-menu dropdown-menu-right ${showUserDropDown ? "show" : ""
                  }`}
              >
                <div className="small pl-4 py-2 navbar-heading">
                  Hi, {state.user.data.firstName}
                </div>
                <Link to="/" className="dropdown-item">
                  My Profile
                </Link>
                <hr className="dropdown-divider" />
                <div onClick={handleLogout} className="dropdown-item pointer">
                  Log out
                </div>
              </div>
            </div>
          </div>
          <div
            className={`collapse navbar-collapse ${collapseNav ? "show" : ""}`}
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  to="/instructor"
                  className={`nav-link ${activeLink === "/instructor" && "active"
                    }`}
                >
                  <RiDashboardLine className="mr-3" /> Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor/calendar/schedule"
                  className={`nav-link ${activeLink === "/instructor/calendar/schedule" && "active"
                    }`}
                >
                  <AiOutlineCalendar className="mr-3" /> Schedule
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor/calendar/availability"
                  className={`nav-link ${activeLink === "/instructor/calendar/availability" &&
                    "active"
                    }`}
                >
                  <AiOutlineSchedule className="mr-3" /> Availability
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor/students"
                  className={`nav-link ${activeLink === "/instructor/students" && "active"
                    }`}
                >
                  <ImUsers className="mr-3" /> Members
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor/messages"
                  className={`nav-link ${activeLink === "/instructor/messages" && "active"
                    }`}
                >
                  <BsChatSquareDots className="mr-3" /> Messages
                </Link>
              </li>
            </ul>

            <hr className="navbar-divider my-3" />

            <h6 className="navbar-heading">Setting</h6>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <Link to="/instructor/courses" className={`nav-link ${activeLink === '/instructor/courses' && 'active'}`}>
                  <FaChalkboardTeacher className='fa mr-3' size={17} /> My Expertise
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor/profile"
                  className={`nav-link ${activeLink === "/instructor/profile" && "active"
                    }`}
                >
                  <RiProfileLine className="fa mr-3" size={17} /> My Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor/earnings"
                  className={`nav-link ${activeLink === "/instructor/earnings" && "active"
                    }`}
                >
                  <FaMoneyCheckAlt className="fa mr-3" size={17} /> My Earnings
                </Link>
              </li>
              <li className="nav-item">
                <div
                  className={`${showUserSettings && `text-dark nav-link1-show`
                    } nav-link nav-link1 pointer`}
                  onClick={() => setShowUserSettings((prev) => !prev)}
                >
                  <MdAccountCircle className="fa mr-3" size={17} /> Account
                </div>
                <AnimateHeight
                  duration={300}
                  height={showUserSettings ? "auto" : 0}
                >
                  <div className="collapse show">
                    <ul className="nav nav-sm flex-column mt-0">
                      <li className="nav-item">
                        <Link
                          to="/instructor/account/bank_details"
                          className={`nav-link ${activeLink === "/instructor/account/bank_details" &&
                            "active"
                            }`}
                        >
                          Bank Details
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/instructor/account/security"
                          className={`nav-link ${activeLink === "/instructor/account/security" &&
                            "active"
                            }`}
                        >
                          Security
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/instructor/account/notifications"
                          className={`nav-link ${activeLink ===
                            "/instructor/account/notifications" && "active"
                            }`}
                        >
                          Notification
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AnimateHeight>
              </li>
            </ul>

            <div className="mt-auto"></div>

            <div
              className="navbar-user d-none d-md-flex py-4 navbar-light"
              style={{ position: "sticky", bottom: "0px" }}
            >
              <div className="navbar-user-link pointer">
                <span
                  className="icon"
                  onClick={() => setShowNotifications(true)}
                >
                  <Badge
                    badgeContent={
                      state.user.data.notifications.filter(
                        (item) => !item.isRead
                      ).length
                    }
                    color="error"
                    className="pointer"
                  >
                    <AiFillBell size={28} />
                  </Badge>
                </span>
              </div>
              <div className="dropup">
                <div className="dropdown-toggle">
                  <div className="avatar avatar-sm">
                    <img
                      className="avatar-img rounded-circle"
                      src={state.user.data.avatar}
                      alt="..."
                    />
                  </div>
                </div>
                <div className="dropdown-menu" style={{ zIndex: "1000" }}>
                  <div className="small pl-4 py-2 navbar-heading">
                    Hi {state.user.data.firstName}
                  </div>
                  <hr className="dropdown-divider" />
                  <div onClick={handleLogout} className="dropdown-item pointer">
                    Log out
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default NavBar;
