import React, { useState, useEffect } from 'react'
import instructor_content from '../../../../../content/instructor_content.json'

import axios from 'axios';

const Pricing = ({ course, setCourse, setTab, saveCourse, loading }) => {

    const [errors, setErrors] = useState(Array(2).fill(false))

    useEffect(() => {
        setErrors(Array(2).fill(false))
    }, [course, course.price_per_class])

    const validate = () => {
        const new_errors = Array(2).fill(false)
        for (let i = 0; i < 4; i++) {
            if (course.discount[i].discount < 0) new_errors[1] = true;
            if (course.discount[i].discount > 90) new_errors[1] = true;
        }
        setErrors(new_errors)
        if (new_errors.includes(true)) return false;
        else return true;
    }

    const moveNext = async e => {
        e.preventDefault()
        try {
            if (!validate()) return;
            saveCourse()
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                    <h6 class="mb-4 text-uppercase text-muted">
                        Step 2 of 2
                    </h6>
                    <h1 class="mb-3">
                        {instructor_content.course.add.step2.title}
                    </h1>
                    <p class="mb-5 text-muted">
                        {/* {instructor_content.course.add.step2.description} */}
                    </p>
                </div>
            </div>

            <div class='mb-6 mt-5'>
                <PricingPerClass course={course} setCourse={setCourse} errors={errors} setErrors={setErrors} errText="Please enter your pricing per class" />
                <Batches course={course} setCourse={setCourse} errors={errors} setErrors={setErrors} errText="Please fill all the inputs in a valid manner" />
            </div>


            <div className="row align-items-center">
                <div className="col-auto">
                    <div className="btn btn-lg btn-white" onClick={() => setTab('basics')}>Back</div>
                </div>
                <div className="col text-center">
                    <h6 class="text-uppercase text-muted mb-0">Step 2 of 2</h6>
                </div>
                <div className="col-auto">
                    <button class="btn btn-lg btn-primary" disabled={loading} onClick={(e) => moveNext(e)}>
                        {loading && <span class="spinner-border spinner-border-sm mr-2"></span>}
                        Add subject
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Pricing





const PricingPerClass = ({ course, setCourse, errors, setErrors, errText }) => {
    const [instructorProfile, setInstructorProfile] = useState();

    const getInstructorProfile = async () => {
        await axios.get('/api/instructor/profile').then(res => {
            if (res.status === 200) {
                setInstructorProfile(res.data)
                console.log(res.data)
            }
        })
    }

    useEffect(() => {
        getInstructorProfile()
    }, [])

    return (
        <div class="mb-5 pb-2">
            <div className='form-group'>
                <label class="bold mb-1">{instructor_content.course.pricing.price.title}</label>
                {/* <div class = 'text-muted small mb-3'>{instructor_content.course.pricing.price.description}</div> */}
                <div class='text-muted small mb-3'>
                    This price would be visible to the member and includes {instructorProfile && instructorProfile.commission ? instructorProfile.commission : 20}% Dhunguru commission
                </div>
                <InputItem icon="$" type="number" input={course.price_per_class} setValue={value => setCourse(prev => ({ ...prev, price_per_class: value }))} />
                {errors[0] && <div class="invalid-feedback d-block ">{errText}</div>}

                {course.price_per_class !== '' && <div>
                    <div class="text-dark mt-3">Your earnings after deducting Dhunguru commission, <strong>${instructorProfile && instructorProfile.commission ? course.price_per_class * ((100 - Number(instructorProfile.commission)) / 100) : course.price_per_class * (0.8)}</strong></div>
                </div>}
            </div>
        </div>
    )
}

const Batches = ({ course, setCourse, errors, setErrors, errText }) => {

    const editDiscount = (value, index, key) => {
        const new_course = { ...course }
        course.discount[index][key] = value;
        setCourse(new_course)
        const newErr = [...errors];
        newErr[0] = false;
        setErrors(newErr)
    }

    return (
        <div class="mb-5 pb-2">
            <div className='form-group'>
                <label class="bold mb-1">{instructor_content.course.pricing.batches.title}</label>
                {/* <div class='text-muted small mb-3'>{instructor_content.course.pricing.batches.description}</div> */}
                <div className='row align-items-center'>
                    <div className='col-3 bold small ml-5'>
                        Number of Sessions
                    </div>
                    <div className='col-3 bold small'>
                        Discount
                    </div>
                </div>
                <div className='row align-items-center mb-3'>
                    <div className='col-auto bold'>1.</div>
                    <div className='col-3'>
                        <input class='form-control disabled-input' disabled={true} type="number" value={course.discount[0].num_classes} />
                    </div>
                    <div className='col-3'>
                        <InputItemPost icon="%" type="number" input={course.discount[0].discount} setValue={value => editDiscount(value, 0, 'discount')} />
                    </div>
                </div>
                <div className='row align-items-center mb-3'>
                    <div className='col-auto bold'>2.</div>
                    <div className='col-3'>
                        <input class='form-control disabled-input' disabled={true} type="number" value={course.discount[1].num_classes} />
                    </div>
                    <div className='col-3'>
                        <InputItemPost icon="%" type="number" input={course.discount[1].discount} setValue={value => editDiscount(value, 1, 'discount')} />
                    </div>
                </div>
                <div className='row align-items-center mb-3'>
                    <div className='col-auto bold'>3.</div>
                    <div className='col-3'>
                        <input class='form-control disabled-input' disabled={true} type="number" value={course.discount[2].num_classes} />
                    </div>
                    <div className='col-3'>
                        <InputItemPost icon="%" type="number" input={course.discount[2].discount} setValue={value => editDiscount(value, 2, 'discount')} />
                    </div>
                </div>
                <div className='row align-items-center mb-3'>
                    <div className='col-auto bold'>4.</div>
                    <div className='col-3'>
                        <input class='form-control disabled-input' disabled={true} type="number" value={course.discount[3].num_classes} />
                    </div>
                    <div className='col-3'>
                        <InputItemPost icon="%" type="number" input={course.discount[3].discount} setValue={value => editDiscount(value, 3, 'discount')} />
                    </div>
                </div>
                {errors[1] && <div class="invalid-feedback d-block ">{errText}</div>}
            </div>
        </div>
    )
}



const InputItem = ({ icon, input, setValue }) => {
    return (
        <div className="input-group input-group-merge">
            <input type="number" class="form-control form-control-prepended" placeholder="" value={input} onChange={e => setValue(e.target.value)} />
            <div className="input-group-prepend">
                <div className="input-group-text text-muted" style={{ height: '41px' }}>
                    {icon}
                </div>
            </div>
        </div>
    )
}


const InputItemPost = ({ icon, input, setValue, type }) => {
    return (
        <div className="input-group input-group-merge">
            <input type={type ? type : 'text'} class="form-control form-control-appended" placeholder="" value={input} onChange={e => setValue(e.target.value)} />
            <div className="input-group-append">
                <div className="input-group-text text-muted" style={{ height: '41px' }}>
                    {icon}
                </div>
            </div>
        </div>
    )
}
