import React, {useState, useEffect, useRef} from 'react'
import Select from 'react-select';
import submitResponse from '../../../utilis/submitResponse'
import {validateEmail, validatePhone} from '../../../utilis/validation'
import cogoToast from 'cogo-toast'
import Swiper, {Navigation, Pagination, Autoplay, Scrollbar, Parallax, EffectFade, Controller} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Parallax, EffectFade, Controller]);


const FreeSession = (props) => {

    const [state, setState] = useState({name: '', email: '', phone: '', city: '', instrument: ''})
    const [errors, setErrors] = useState(Array(4).fill(false));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrors(Array(4).fill(false));
    }, [state])


    const validate = () => {

        const new_errors = Array(4).fill(false);
        if(state.name === '') new_errors[0] = true;
        if(!validateEmail(state.email)) new_errors[1] = true;
        if(!validatePhone(state.phone)) new_errors[2] = true;
        if(state.instrument.length === 0) new_errors[3] = true;
        if(state.city.length === 0) new_errors[4] = true;
        setErrors(new_errors)
        if(new_errors.includes(true)) return true;
        else return false;
    }


    const submitData = async (e) => {
        e.preventDefault();
        if(validate()) return;
        try{

            setLoading(true);
            await submitResponse({...state, instrument: state.instrument.map(item => item.value).join(', '), type: 'FREE_SESSION'})
            setState({name: '', email: '', phone: '', instrument: '', city: ''})
            cogoToast.success("We will reach out to you shortly")
            window.location.href = 'https://dhungurus.com/instructors'
            setLoading(false);
            
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

    return (
        <div class = "" style = {{backgroundColor: '#c8c8c8'}}>
         <div className = 'container py-sm-5 px-4 px-md-6 pt-0 ' id = "free-session">
                
                <div class="row px-md-6 px-lg-0 align-items-center py-4" style = {{}}>
                    <div class="col-lg-6 mb-md-0 mb-grid-gutter pr-lg-6">
                        <h1 class="font-weight-bolder text-center mb-2" style={{fontSize: '44px'}}>Book Your <div style = {{color: '#ff6700', display: 'inline-block'}}>FREE</div> Session</h1>
                        <div className = 'd-none d-lg-block'>
                                <ValueProps />
                        </div>
                    </div> 
                    <div class="col-lg-6 col-12"> 
                            
                        <div class = "card shadow" >
                            <div class = "card-body p-5">
                                <form class=""> 
                                    <div class = "form-group">
                                        <label>Name</label>
                                        <input className = {`form-control ${errors[0] && 'is-invalid'}`} value = {state.name} onChange = {e => setState(prev => ({...prev, name: e.target.value}))}/>
                                        <div class = 'invalid-feedback'>Please enter a valid name</div>
                                    </div>
                                    <div class = "form-group">
                                        <label>Email Address</label>
                                        <input className = {`form-control ${errors[1] && 'is-invalid'}`} value = {state.email} onChange = {e => setState(prev => ({...prev, email: e.target.value}))}/>
                                        <div class = 'invalid-feedback'>Please enter a valid email</div>
                                    </div>
                                    <div class = "form-group">
                                        <label>Phone Number</label>
                                        <input className = {`form-control ${errors[2] && 'is-invalid'}`} value = {state.phone} onChange = {e => setState(prev => ({...prev, phone: e.target.value}))}/>
                                        <div class = 'invalid-feedback'>Please enter a valid phone</div>
                                    </div>
                                    <div class = "form-group">
                                        <label>City</label>
                                        <input className = {`form-control ${errors[4] && 'is-invalid'}`} value = {state.city} onChange = {e => setState(prev => ({...prev, city: e.target.value}))}/>
                                        <div class = 'invalid-feedback'>Please enter a valid city</div>
                                    </div>
                                    <div class = "form-group">
                                        <label>Your Instrument</label>
                                        <Select
                                            value={state.instrument}
                                            onChange={(e) => {setState(prev => ({...prev, instrument: e}))}}
                                            options={instruments}
                                            isMulti = {true}
                                        />
                                        {errors[3] && <div class = 'invalid-feedback d-block'>Please select a instrument</div>}
                                    </div>

                                
                                    <button class="btn btn-primary w-50" disabled = {loading} onClick = {e => submitData(e)}>
                                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                                        Book Now
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className = 'd-block d-lg-none mt-6'>
                            <ValueProps />
                        </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}
export default FreeSession




const ValueProps = () => {

    const swiper = useRef(null)
    const swiperMe = useRef(null)

    useEffect(() => {
        swiperMe.current = new Swiper(swiper.current,{
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 600,
            loop: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: true
            },
            pagination: {
                el: '.swiper-pagination',
                disableOnInteraction: true
            },
            breakpoints: {
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 0
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 0
                }
              }
        })
    }, [])


    return (
        <div class="swiper mt-lg-6 pt-lg-2 mt-2 pt-0" >
            <div class="swiper-container" ref = {swiper}>
                <div class="swiper-wrapper mb-6">
                    <div className = 'swiper-slide ' >
                         <div className="text-center">
                            <img src =  "/assets/1.svg" alt ="" class = 'img-fluid mb-4 ' style = {{height: '220px'}}/>
                            <h3 className="h2 mb-2">Book your first session with us, absolutely FREE.</h3>
                        </div>
                    </div>
                    <div className = 'swiper-slide '>
                         <div className="text-center">
                            <img src =  "/assets/2.svg" alt ="" class = 'img-fluid mb-4 ' style = {{height: '220px'}}/>
                            <h3 className="h2 mb-2">Purchase as low as 1 session at a time. Pay as you go.</h3>
                        </div>
                    </div>
                    <div className = 'swiper-slide '>
                         <div className="text-center">
                            <img src =  "/assets/4.svg" alt ="" class = 'img-fluid mb-4 ' style = {{height: '220px'}}/>
                            <h3 className="h2 mb-2">Learn at your own pace with a customized course plan.</h3>
                        </div>
                    </div>
                    <div className = 'swiper-slide '>
                         <div className="text-center">
                            <img src =  "/assets/3.svg" alt ="" class = 'img-fluid mb-4 ' style = {{height: '220px'}}/>
                            <h3 className="h2 mb-2">Practice all you want with full access to all session recordings.</h3>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination mt-10"></div>
            </div>
        </div>
    )
}


const instruments = [
    { value: 'guitar', label: "Guitar"},
    { value: 'piano', label: "Piano"},
    { value: 'drums', label: "Drums"},
    {value: 'keyboard', label: "Keyboard" },
    {value: 'violin', label: "Violin" },
    {value: 'ukulele', label: "Ukulele" },
    {value: 'cajon', label: "Cajon" },
    {value: 'harmonium', label: "Harmonium" },
    {value: 'flute', label: "Flute" },
    {value: 'tabla', label: "Tabla" },
    {value: 'veena', label: "Veena" },
    {value: 'trumpet', label: "Trumpet" },
    {value: 'mridangam', label: "Mridangam" },
    {value: 'sitar', label: "Sitar" },
    {value: 'singing', label: "Singing" },
    {value: 'songWriting', label: "Song Writing" },
    {value: 'musicComposition', label: "Music Production" },
    {value: 'musicTheory', label: "Music Theory" },
    {value: 'rap', label: "Rap" },
    {value: 'musicTherapy', label: "Music Therapy" },
    {value: 'dJing', label: "DJing" },
    {value: 'dhol', label: "Dhol" },
    {value: 'harmonica', label: "Harmonica" },
    {value: 'mandolin', label: "Mandolin" },
    {value: 'doubleBass', label: "Double Bass" },
    {value: 'cello', label: "Cello" },

]


