import { useState, useContext, useEffect, useMemo } from 'react'
import { Store } from '../../../../store'
import Select from 'react-select'
import { Switch, DateFormInput1 } from '../../../../components/Forms'
import instructor_content from '../../../../content/instructor_content.json'
import { validatePhone } from '../../../../utilis/validation'
import Swal from 'sweetalert2'
import PhoneInput from 'react-phone-input-2'
import countryList from 'react-select-country-list'
// import axios from 'axios'

const AdditionalInfo = ({ profile, setProfile, saveProfile, loading, avatarRef, user, setUser }) => {

    const [errors, setErrors] = useState(Array(6).fill(false));

    const validate = () => {
        const new_errors = Array(6).fill(false)
        if (!validatePhone(user.phone)) new_errors[0] = true;
        if (profile.languages.length === 0) new_errors[1] = true;
        if (!profile.bio || (profile.bio).toString().length < 50) new_errors[2] = true;
        if (!profile.birthday || (profile.birthday).toString().length < 6) new_errors[3] = true;
        if (!profile.numYearExperience || profile.numYearExperience < 0) new_errors[4] = true;
        setErrors(new_errors)
        if (new_errors.includes(true)) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            return false;
        }
        else return true;
    }

    const moveNext = async e => {
        e.preventDefault();
        if (!validate()) return
        await saveProfile();
    }

    useEffect(() => {
        setErrors(Array(6).fill(false))
    }, [profile.bio, profile.birthday, profile.languages, profile.numYearExperience, user.phone])


    const deleteUser = async () => {
        const response = await Swal.fire({
            title: 'Are you sure you want to delete your account?',
            text: 'This action cannot be undone. Once deleted, all the user data will also be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if (response.isConfirmed) {
            await Swal.fire({
                title: 'Please reach out to us at support@dhungurus.com to proceed with the request',
                showCancelButton: true,
            });
        }
    }



    return (
        <div class="">
            <div class=''>
                <BioData
                    user={user}
                    setUser={setUser}
                    profile={profile}
                    setProfile={setProfile}
                    errors={errors}
                    setErrors={setErrors}
                    avatarRef={avatarRef}
                />

            </div>
            <button disabled={loading} className='btn btn-block my-5 btn-primary px-6' onClick={(e) => moveNext(e)}>
                {loading && <span class="spinner-border spinner-border-sm mr-2"></span>}
                Save
            </button>

            <hr class="mt-4 mb-5" />
            <div class="row justify-content-between mb-5">
                <div class="col-12 col-md-6">
                    <h4>Delete your account</h4>
                    <p class="small text-muted mb-md-0">
                        Note: Deleting your account is permanent and cannot be undone.
                    </p>
                </div>
                <div class="col-auto">
                    <div class="btn btn-danger" onClick={deleteUser}>
                        Delete
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdditionalInfo;



const BioData = ({ profile, setProfile, errors, setErrors, avatarRef, user, setUser }) => {

    const { state } = useContext(Store);
    const [avatar, setAvatar] = useState(state.user.data.avatar);
    const [phoneInput, setPhoneInput] = useState( '+' + user?.countryCode + user?.phone);
    const updateAvatar = async () => {
        setAvatar(URL.createObjectURL(avatarRef.current.files[0]));
    }
    const options = useMemo(() => countryList().getData(), [])
    console.log(user)


    return (
        <form class="mb-5 pb-2">
            <div className="row justify-content-between align-items-center pt-4">
                <div className="col">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="avatar avatar-xxl">
                                <img className="avatar-img rounded-circle" src={avatar} alt="..." />
                            </div>
                        </div>
                        <div className="col ml-n2">
                            <h4 class="mb-1">Your avatar</h4>
                            <small class="text-muted">Use PNG or JPG images under 1000 pixels in width and height</small>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="col-auto">
                        <form>
                            <input type="file" name="avatar" accept="image/png, image/jpeg" hidden='hidden' ref={avatarRef} onChange={e => { updateAvatar() }} />
                            <button type='button' className="btn btn-sm btn-primary" onClick={() => { avatarRef.current.click(); }}>Upload</button>
                        </form>
                    </div>
                </div>
            </div>

            <div className='row no-gutters mt-5 pt-4'>
                <div className='col-2'>
                    <div className='form-group '>
                        <label class='bold mb-1'>Title</label>
                        <input
                            className='form-control '
                            value={user.title}
                            onChange={e => { setUser(prev => ({ ...prev, title: e.target.value })) }}
                            style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
                        />
                    </div>
                </div>
                <div className='col-5'>
                    <div className='form-group '>
                        <label class='bold mb-1'>First Name</label>
                        <input
                            className='form-control '
                            value={user.firstName}
                            onChange={e => { setUser(prev => ({ ...prev, firstName: e.target.value })) }}
                            style={{ borderRadius: '0' }}
                        />
                    </div>
                </div>
                <div className='col-5'>
                    <div className='form-group '>
                        <label class='bold mb-1'>Last Name</label>
                        <input
                            className='form-control '
                            value={user.lastName}
                            onChange={e => { setUser(prev => ({ ...prev, lastName: e.target.value })) }}
                            style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                        />
                    </div>
                </div>
            </div>


            <div className='row pt-4'>
                <div className='col-12 col-lg-6'>
                    <div className='form-group '>
                        <label class='bold mb-1'>Phone Number</label>
                        {/* <input
                            className='form-control '
                            value={user.phone}
                            onChange={e => { setUser(prev => ({ ...prev, phone: e.target.value })) }}
                        /> */}

                        <PhoneInput
                            country={"us"}
                            value={phoneInput}
                            onChange={(e, data) => {
                                const reducedPhone = e?.replace(data.dialCode, "");
                                setPhoneInput(e)
                                setUser((prev) => ({ ...prev, phone: reducedPhone, countryCode: data?.dialCode }))
                            }}
                            containerStyle={{ width: "100%" }}
                            inputClass="form-control"
                            containerClass={`${errors[0] && "is-invalid"}`}
                            inputStyle={{
                                minWidth: "100%",
                                borderColor: `${errors[0] ? "red" : ""} `,
                            }}
                        />
                        {errors[0] && <div class='invalid-feedback d-block'>Please enter a valid phone number</div>}
                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    <div className='form-group '>
                        <label class='bold mb-1'>Email</label>
                        <input
                            className='form-control disabled-input'
                            value={state.user.data.email}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-12 col-lg-6'>
                    {/* <div className='form-group '>
                        <label class='bold mb-1'>Country</label>
                        <input
                            className='form-control '
                            value={user?.address}
                            onChange={e => { setUser(prev => ({ ...prev, address: e.target.value })) }}
                        />
                    </div> */}

                    <div className='form-group '>
                        <label class='bold mb-1'>Country</label>
                        <Select options={options} onChange={e => { setUser(prev => ({ ...prev, country: e.label })) }} value={options.filter(item => item.label === user?.country)[0]} />
                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    <div className='form-group '>
                        <label class='bold mb-1'>Birthday</label>
                        <DateFormInput1
                            placeholder="Birthday"
                            error={errors[3]}
                            setError={(val) => console.log(val)}
                            errorMessage={"Please enter a your birthday"}
                            label=""
                            setValue={(val) => setProfile(prev => ({ ...prev, birthday: val }))}
                            value={profile.birthday}
                        />
                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    <div className='form-group mb-5 mt-4'>
                        <label class='bold mb-1'>Gender</label>
                        <select
                            className='form-control'
                            value={profile.gender}
                            onChange={e => setProfile(prev => ({ ...prev, gender: e.target.value }))}
                        >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    <div className='form-group mb-5 mt-4'>
                        <label class='bold mb-1'>{instructor_content.profile.basics.experience}</label>
                        <input
                            className={errors[4] ? 'form-control is-invalid' : 'form-control'}
                            value={profile.numYearExperience}
                            placeholder="How many years of experience do you have?"
                            onChange={e => { setProfile(prev => ({ ...prev, numYearExperience: e.target.value })) }}
                            type="number"
                        />
                        {errors[4] && <div class='invalid-feedback d-block'>Please enter your number of experience</div>}
                    </div>
                </div>
            </div>

            <div className='form-group mb-5 pb-2'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <label class='bold mb-1'>Languages</label>
                    </div>
                </div>
                <Select
                    options={langOptions}
                    isMulti
                    value={langOptions.filter(item => profile.languages ? profile.languages.includes(item.value) : false)}
                    onChange={e => { setErrors([false, false]); setProfile(prev => ({ ...prev, languages: e.map(item => item.value) })) }}
                />
                {errors[1] && <div class='invalid-feedback d-block'>Please select at least one language</div>}
            </div>



            <div className='form-group my-5 py-3'>
                <label class='bold mb-1'>{instructor_content.profile.basics.bio.title}</label>
                {/* <div class='text-muted small mb-3'>{instructor_content.profile.basics.bio.description}</div> */}
                <textarea
                    className={errors[0] ? 'form-control is-invalid' : 'form-control'}
                    style={{ minHeight: '200px' }}
                    value={profile.bio}
                    onChange={e => { setErrors(prev => { prev[0] = false; return prev }); setProfile(prev => ({ ...prev, bio: e.target.value })) }}
                />
                {errors[2] && <div class='invalid-feedback d-block'>{instructor_content.profile.basics.bio.error}</div>}
            </div>



            <div class='row '>
                <div class='col-12 col-lg-6'>
                    <TeachesKid profile={profile} setProfile={setProfile} />
                </div>
                <div class='col-12 col-lg-6'>
                </div>
            </div>


        </form>
    )
}








const TeachesKid = ({ profile, setProfile }) => {
    return (
        <div className='card mb-5 pb-3 card-fill'>
            <div className='card-body'>
                <div className='row align-items-center'>
                    <div className='col'>
                        <label class="bold mb-1">{instructor_content.profile.basics.teaches_children.title}</label>
                        <div class='text-muted small'>{instructor_content.profile.basics.teaches_children.description}</div>
                    </div>
                    <div className='col-auto'>
                        <Switch
                            value={profile.teachesChildren}
                            setValue={value => setProfile(prev => ({ ...prev, teachesChildren: value }))}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}




const langOptions = [
    { value: 'English', label: 'English' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Mandarin', label: 'Mandarin' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Russian', label: 'Russian' },
    { value: 'Portuguese', label: 'Portuguese' },
    { value: 'Arabic', label: 'Arabic' },
    { value: 'Japanese', label: 'Japanese' },
    { value: 'Korean', label: 'Korean' },
    { value: 'Italian', label: 'Italian' },
    { value: 'Turkish', label: 'Turkish' },
    { value: 'Dutch', label: 'Dutch' },
    { value: 'Swedish', label: 'Swedish' },
    { value: 'Thai', label: 'Thai' },
    { value: 'Vietnamese', label: 'Vietnamese' },
    { value: 'Greek', label: 'Greek' },
    { value: 'Hebrew', label: 'Hebrew' },
    { value: 'Malay', label: 'Malay' },
    { value: 'Indonesian', label: 'Indonesian' },
    { value: 'Bengali', label: 'Bengali' },
    { value: 'Marathi', label: 'Marathi' },
    { value: 'Telugu', label: 'Telugu' },
    { value: 'Tamil', label: 'Tamil' },
    { value: 'Punjabi', label: 'Punjabi' },
    { value: 'Gujarati', label: 'Gujarati' },
    { value: 'Kannada', label: 'Kannada' },
    { value: 'Urdu', label: 'Urdu' },
    { value: 'Odia', label: 'Odia' }
];