import React, {useState, useEffect, useContext} from 'react'
import { Store } from '../../../../store';
import axios from '../../../../utilis/axios'
import {Link} from 'react-router-dom'
import { saveCourses } from '../../../../store/actions/landing';



const StarTeachers = () => {

    const {state, dispatch} = useContext(Store)
    const [instructors, setInstructors] = useState([])


    useEffect(() => {
        if(state.landing.courses && state.landing.courses.length > 0){
            formatData(state.landing.courses)
        } else fetchCourses();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(state.landing.courses && state.landing.courses.length > 0){
            formatData(state.landing.courses)
        } 
    }, [state.landing.courses])

    const fetchCourses = async () => {
        try{
            const res = await axios.get('/api/student/instructors');
            saveCourses(res.data, dispatch)
        } catch(err){
            console.log(err);
        }
    }


    const formatData = (data) => {
        const allInstructors = []
        for(let i=0; i < data.length; i++){
            if(!data[i].instructor_profile.isStarTeacher) continue;
            const index = allInstructors.findIndex(item => item._id === data[i].instructor._id)
            if(index > -1){
                allInstructors[index].instruments.push({
                    lesson_id: data[i]._id,
                    instrument: data[i].instrument,
                })
            } else{
                allInstructors.push({
                    _id: data[i].instructor._id, 
                    profile: data[i].instructor_profile, 
                    instructor: data[i].instructor,
                    minPrice: data[i].price_per_class,
                    instruments: [{
                        lesson_id: data[i]._id,
                        instrument: data[i].instrument,
                    }],
                    data: data[i]
                })
            }
        }
        setInstructors(allInstructors.slice(0, 4))
    }

    return (
        <div>
            <div className = "mt-5 h2 mb-2 ">Explore our starred teachers</div>
            <div className = 'row'>
                {instructors.map(item => <div className = 'col-12 col-lg-3'>
                    <TeacherCard data = {item}/>
                </div>)}
            </div>
        </div>
    )
}


export default StarTeachers




const TeacherCard = ({data}) => {
    return (
        <div className = 'card shadow border'>
            <img src={data.instructor.avatar} alt="..." class="card-img" style = {{maxHeight: '270px', objectFit: 'cover'}}/>
            <div className = 'card-body'>
                <div className = 'd-flex align-items-center justify-content-center mb-4'>
                </div>
                <h4 class = 'h3 mb-2'>{data.instructor.firstName + ' ' + data.instructor.lastName}</h4>
                <div class = 'mb-3'>
                    {data.instruments.map(item => <span class ='badge badge-primary mr-2'>{item.instrument[0].toUpperCase() + item.instrument.slice(1)}</span>)}
                </div>
                <Bio bio = {data.profile.bio ? data.profile.bio : ''}/>
            </div>
            <div class = 'card-footer py-3'>
                <div class = 'row align-items-center'>
                    <div class = 'col'>
                        <div class = 'small text-primary font-weight-bold '>Price starts from ${Math.ceil(data.minPrice + (0.18 * data.minPrice))}</div>
                    </div>
                    <div class = 'col-6 text-right'>
                        <Link to = {`/lesson/${data.instruments[0].lesson_id}`} class = 'btn btn-primary btn-sm mt-2'>Learn more</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}


const Bio = ({bio}) => {

    const [more, setMore] = useState(false)

    return (
        <p class = "small mb-0">
            {more ? bio : (bio.length > 80 ? bio.slice(0, 80) + '...' : bio)}
            {bio.length > 80 && <span class = 'btn-link pointer' onClick = {() => setMore(prev => !prev)}>{more ? 'Read less' : 'Read more'}</span>}
        </p>
    )
}