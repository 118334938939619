const Doc = () => (<>
    <h3 id="tnc_therapist">DHUNGURU TERMS OF USE FOR THERAPISTS (“THERAPIST TERMS”)</h3>
    <p>Last Updated: May 28, 2024</p>
    <p><strong>IMPORTANT NOTICE: PLEASE READ THROUGH THESE TERMS CAREFULLY.</strong> <br />
        Welcome to Dhunguru Music India Private Limited <strong>("Dhunguru")</strong>. The following document, known as the <strong>(“Therapist Terms”)</strong> outlines the terms and conditions governing your access to and utilization of our online website or application <strong>(“Platform”)</strong>. This platform serves as a conduit connecting Clients and Therapists for Music Therapy Sessions. By engaging with the Platform, you enter into a legally binding agreement with Dhunguru, and it is imperative that you thoroughly review and comprehend these Terms of Use.  </p>
    By accessing or utilizing the Platform, you hereby acknowledge and agree to adhere to the Therapists Terms, along with the Privacy Policy and guidelines hyperlinked within these Therapists Terms, and any prospective modifications thereof (collectively referred to as the <strong>"Agreement"</strong>). <br />
    A <strong>(“User”)</strong> shall mean any natural or legal person, who is competent to enter into a contract as per the Indian Contract Act, 1872, who uses the Platform, with or without registration, to avail the services offered, for himself or herself and/or for any other person.<br />
    If you find yourself unable to comprehend or align with these Therapists Terms of Use, we kindly request that you refrain from using the Platform. It is important to note that we bear no obligation to actively enforce the Agreement on your behalf against another user. Nevertheless, we encourage you to communicate with us should you believe that another user has contravened the terms of the Agreement. While we appreciate your cooperation in reporting potential violations, it is imperative to understand that we retain the right to conduct investigations and take appropriate action, at our sole discretion.
    <ol >
        <li className='my-3'>
            <strong>“Music Therapist”</strong> as used herein refers to an individual who seeks and obtains Dhunguru’s approval to offer his or her services as a therapist through the Platform. A certified music therapist must have completed a minimum of an approved Bachelor's Degree in any subject and a Certificate Course/ PG Diploma/ Masters’ or Post Graduation in Music Therapy. After graduating, music therapists should be under the supervision of a course/subject supervisor, who should be certified by the Indian Medical Association/Practicing Music Therapists/Clinical Psychologist. A certified music therapist must ensure and adhere to the by-laws, code of ethics, and standards of practice of the profession. Dhunguru’s approval generally is based on the above-mentioned qualifications. <br />
            Additionally, a certified music therapist should: <br />
            <ul>
                <li>have experience and the ability to articulate the skills and knowledge about the client population;</li>
                <li>have the ability to play at least two musical instruments and have a solid repertoire of music therapy;</li>
                <li>be aware of techniques and interventions appropriate to the needs of the people in the facility;</li>
                <li>be able to describe their philosophy of therapy including interventions, style, approach, program plans, and expected benefits of music therapy in a given setting;</li>
                <li>be able to design individualised therapy plans and goals in collaboration with caregivers to track the client's progress;</li>
                <li>not disclose any confidential information/case history unless required to do so in the official capacity of employment or contract.</li>
                <li>Also be able to understand that there is no right or ownership interest in any video content of the case study or case history;</li>
                <li>not leave a secured computer application unattended while signed on.</li>
                <li>not disclose protected health information or other information that is considered proprietary, sensitive, or confidential unless there is a need-to-know basis.</li>
            </ul><br />
            By submitting your application to assume the role of a Therapist, you hereby grant Dhunguru explicit consent to conduct a thorough background check at its discretion. Dhunguru retains the unequivocal right to decline a Therapist status for any reason within its sole discretion. In the event that the outcome of the background check yields adverse consequences for a Therapist or Therapist Applicant, leading to denial of access to the Platform, Dhunguru commits to promptly disclose the findings to the concerned Therapist or Therapist Applicant. This notification will be accompanied by a reasonable timeframe, affording the individual an opportunity to respond to or rectify any inaccuracies within the provided information. It is imperative to acknowledge and consent to these conditions as an integral part of the application process, acknowledging Dhunguru's prerogative to make determinations based on the results of the background check.<br />
            All Therapists utilizing the Platform are explicitly bound by the obligation not to employ any facet of the Platform for purposes divergent from its designated intent, specifically as a platform for conducting Sessions. Engaging in any use of the Platform contrary to its intended purpose constitutes a breach of this Agreement. Any such violation of this Agreement may result in disciplinary measures, including but not limited to the denial of access to the Platform. It is imperative for all Therapist to adhere to the stipulated terms and conditions, recognizing that any deviation from the intended purpose of the Platform is strictly prohibited and subject to appropriate consequences as determined by Dhunguru.<br />
            If you object to anything in this Agreement (including anything in our Privacy Policy or other applicable terms), please immediately terminate your use of the Platform. <br />
            YOU EXPRESSLY AGREE THAT YOUR USE OF THIS PLATFORM IS AT YOUR SOLE RISK. WE RESERVE THE RIGHT TO DISCONTINUE ANY ASPECT OF THE PLATFORM AT ANY TIME. THIS AGREEMENT IS TERMINABLE AT ANY TIME BY EITHER PARTY (DHUNGURU OR YOU) FOR ANY REASON. ANY LICENSE YOU GRANT DHUNGURU THROUGH ANY AGREEMENT WITH DHUNGURU WILL SURVIVE TERMINATION OF THE AGREEMENT WITH DHUNGURU.
        </li>
        <li className='my-3'>
            <strong>Therapist Accounts. </strong> To access certain features offered through the Platform, it is a prerequisite to register and create an account, necessitating the provision of specific personal information. The confidentiality of your account password is your sole responsibility, and you are accountable for all activities associated with your account. Prompt notification of any unauthorized use of your account is mandatory. We retain the discretion to terminate your account at any time, without limitation and for any reason. Your account is designated for individual use exclusively, and you must furnish complete and accurate information about yourself during the account creation process. Impersonation, adoption of identities other than your own, creation of accounts for entities other than yourself, provision of unauthorized email addresses, and the establishment of multiple accounts are strictly prohibited. The use of pseudonyms is not permissible. For a comprehensive understanding of how your information is handled, please refer to the applicable Privacy Policy. The term "Therapist Profile" denotes the individual webpage within the Platform where a Therapist can upload their distinct Therapist Content.
        </li>
        <li className='my-3'>
            <strong>Communications from Dhunguru and other Users</strong> Upon the creation of an account, you expressly consent to receive specific communications related to the Platform. These communications may include messages from Clients or prospective Clients seeking information about your style or availability. It is acknowledged and agreed that Dhunguru retains the right to access and review any message transmitted through the Platform's internal messaging service, exercising this right at its discretion and for any purpose or without assigning a reason. Dhunguru may utilize the information contained in such messages for any purpose or no reason. Additionally, you may receive marketing communications from Dhunguru, and you retain the option to opt-out of such communications. This acknowledgment is an integral part of your agreement with Dhunguru and underscores the importance of understanding the terms governing communication and information usage within the Platform.<br /><br />
        </li>
        <li className='my-3'>
            <strong>Proprietary Rights, Materials and Therapist Content.</strong> <br />
            Dhunguru, along with its licensors, maintains exclusive ownership and retains all proprietary rights pertaining to the Platform. The disclosure of confidential information is prohibited indefinitely, even after termination of service or business relationship, unless specifically waived in writing by Dhunguru. The Platform encompasses content, visual interfaces, interactive features, information, graphics, design, compilation, computer code, products, software, services, and other constituents of the Platform (referred to as the "Dhunguru Materials"). These Dhunguru Materials are safeguarded by copyright, trade dress, patent, trademark laws, international conventions, and other pertinent intellectual property and proprietary rights, as well as applicable laws. Unless specific information is within the public domain or express written permission has been granted, users are prohibited from engaging in activities such as copying, modifying, publishing, transmitting, distributing, licensing, performing, displaying, or selling any Dhunguru Materials. It is important to note that Dhunguru Materials exclude Therapist Content (as defined below) or other content submitted by Clients. Dhunguru expressly retains all rights not explicitly granted in this Agreement. Under this Agreement, users including but not limited to Clients and Therapists are not entitled to acquire any right, title, or interest in the Platform or Dhunguru Materials beyond the limited rights expressly stipulated herein.<br />
            "Therapists Content" herein denotes any content or material of any nature uploaded or otherwise submitted by a Therapist to or through the Platform or provided by a Therapist during or in connection with a Session conducted through the Platform. In assuming the role of the Therapist, you bear responsibility for the accuracy of the submitted Therapist Content. Through the act of submitting Therapist Content, you hereby grant Dhunguru a perpetual, non-exclusive, worldwide license to utilize all your submitted Therapist Content in any manner deemed necessary for the Purpose of the Platform. It is imperative to note that this license extends globally and persists even after the termination or expiration of this Agreement. However, in situations where you share Therapist Content privately in the context of a specific Client interaction (e.g., sharing curriculum with a particular Client or Clients via the Platform's messaging function or within the course of a specific session), Dhunguru commits not to intentionally distribute such materials to individuals other than our employees and the intended recipients unless expressly instructed or granted consent to act otherwise by you. Dhunguru reserves the right, at our sole discretion and without prior notice to you, to moderate, remove, screen, edit, or reinstate Therapist Content from time to time. For instance, we may exercise this right if we reasonably believe that Therapist Content violates the terms outlined in this Agreement. It is important to acknowledge that Dhunguru is under no obligation to retain or furnish you with copies of Therapist Content, and we do not provide any assurances of confidentiality regarding Therapist Content.<br />
            The subsequent enumeration delineates specific types of Therapist Content that are deemed illegal or prohibited on the Platform. Dhunguru expressly reserves the right to conduct investigations and, at its sole discretion, initiate appropriate legal action against any individual found in violation of this provision. Remedial actions may include, without limitation, the removal of the offending communication from the Platform and the termination of membership for such violators. Prohibited Therapist Content includes, but is not limited to:<br />
            <ol>
                <li>Content that is overtly offensive to the online community, such as material that advocates racism, bigotry, hatred, or physical harm directed at any group or individual;</li>
                <li> Material that engages in or promotes the harassment of another person;</li>
                <li>Involvement in the transmission of "junk mail," "chain letters," or unsolicited mass mailing, commonly referred to as "spamming";</li>
                <li>Information that is knowingly false, misleading, or advocates illegal activities. This encompasses conduct that is abusive, threatening, obscene, profane, offensive, sexually oriented, racially offensive, defamatory, or libelous;</li>
                <li>Promotion of an illegal or unauthorized copy of another person's copyrighted work, including the provision of pirated computer programs or links to such programs, information facilitating the circumvention of manufacturer-installed copy-protection devices, or provision of pirated images, audio, or video, or links to pirated files;</li>
                <li>Content featuring restricted or password-only access pages, hidden pages or images not linked to or from another accessible page;</li>
                <li>Material that exploits individuals under the age of 18 in a sexual or violent manner or solicits personal information from individuals under the age of 18;</li>
                <li>Instructional information pertaining to illegal activities, such as the creation or purchase of illegal weapons, invasion of privacy, or the provision or creation of computer viruses;</li>
                <li>Solicitation of passwords or personal identifying information from other users for commercial or unlawful purposes;</li>
                <li>Engagement in commercial activities and/or sales without the prior written consent of Dhunguru including but not limited to contests, sweepstakes, barter, advertising, and pyramid schemes.</li>
            </ol>
        </li>
        <li className='my-3'>
            <strong>Recorded Sessions. </strong>
            All sessions conducted by Dhunguru are automatically recorded ("Recorded Session") based on the explicit consent obtained from both Therapist and Clients during the sign-up process. By utilizing the Platform, the Therapist expressly grant Dhunguru the irrevocable right to record each session. Both Therapists and Clients acknowledge and agree to the following:<br />
            <ul>
                <li>Dhunguru is the sole owner of all rights, including copyright, associated with any Recorded Sessions. Recorded Sessions are intended to be used exclusively for the Purpose of the Platform, specifically for Music Therapy Sessions.</li>
                <li>Recorded Sessions will be utilized in accordance with the purposes outlined herein.</li>
                <li>Audio and/or video from Recorded Sessions may not be posted on any third-party media-hosting website (e.g., SoundCloud, YouTube, or Vimeo) without the express permission of Dhunguru.</li>
                <li>The sessions recordings will not be furnished to either the Clients or the Therapists.</li>
            </ul>
            <br />PLEASE DO NOT USE THE PLATFORM IF YOU DO NOT CONSENT FOR THE SESSIONS TO BE RECORDED.
        </li>
        <li className='my-3'>
            <strong>Adequate Equipment and Bandwidth. </strong>
            To access and utilize the Platform, it is a prerequisite that you possess a computer equipped with a webcam. It is important to note that we cannot ensure the consistent functionality of the Platform across all hardware configurations or network connections. A level of connectivity equal to or exceeding the standards of the computer and webcam employed during your Initial Interview with Dhunguru is mandatory. A minimum connectivity threshold of 500 kilobits per second (kbps) for both upload and download speeds is essential for proper utilization of the Platform. In the event that, during your Initial Interview, you fail to demonstrate that your system and bandwidth meet the required standards to facilitate Sessions through the Platform, Dhunguru reserves the exclusive right, at its sole discretion, to deny your authorization to provide Sessions through the Platform. Suggested minimum computer hardware requirements are below:
            <strong>Supported OS Windows</strong><br />
            10, 8.1, 8, 7, XP;<br />
            Mac: OS X 10.6 or higher;<br />
            Linux: Debian 7.0; Fedora Core 18, 19; openSUSE 12.2, 12.3; Red Hat 6.1, 6.3; Scientific Linux (SL) 6.1, 6.3; Ubuntu 12.04, 12.10, 13.04, 13.10<br />
            <strong>Recommended Configuration System</strong><br />
            Core 2 Duo 2GHz; 2 GB RAM; 40 MB free disk space For HD: Graphics capabilities that support HD resolutions on one or more displays<br />
            <strong>Devices & Accessories</strong><br />
            For details, see https://support.zoom.us/hc/en-us/articles/201362023-System-requirements-for-Windows-macOS-and-Linux<br />
            <strong> Webcams</strong><br />
            Supported: Standard integrated webcams<br />
            Preferred: External USB webcams that support 720p30 and higher<br />
            <strong>Microphones and Speakers</strong><br />
            Supported: Integrated system microphones and speakers, webcam microphones<br />
            Preferred: Headsets and speakerphones with integrated echo cancellation<br />
        </li>
        <li className='my-3'>
            <strong>Relationship between Therapists and the Platform. </strong>
            The affiliation between the Platform and the Therapist is established when the individual registers, accepts this Agreement, and receives approval from Dhunguru to act as a Therapist. Therapists acknowledged in this Agreement and throughout the Platform are independent contractors providing services to Clients and are explicitly not considered employees of Dhunguru.<br />
            Dhunguru maintains a non-supervisory role over Therapists and refrains from providing directives on the manner in which Sessions are to be taught through the Platform. Therapists do not serve as agents of Dhunguru, and Dhunguru bears no liability for the actions, errors, omissions, representations, warranties, breaches, or negligence of Therapists. Furthermore, Dhunguru is not responsible for any personal injuries, death, property damage, or other associated damages or expenses arising from the activities of Therapists. It is recommended that Therapists acquire suitable general liability and professional liability insurance before engaging with the Platform. Therapists are solely accountable for complying with applicable income tax, sales tax, and other relevant laws governing the sales of Sessions. Dhunguru emphasizes that Therapists are not employees or agents of any kind, and the provision of the Platform is contingent upon the Platform Fee. Therapists are empowered to establish their own availability and session schedules on the Platform. Dhunguru retains the right to conduct audits of Sessions for the purpose of ensuring Platform safety and stability.<br />
            Dhunguru reserves the discretionary right to delete an account, limit, or deny access to the Platform at any time and for any reason or without providing a specific reason. The punctuality of the Therapist is deemed essential for the proper use of the Platform in line with its intended Purpose of conducting Music Therapy Sessions.
        </li>
        <li className='my-3'>
            <strong> Exclusivity. </strong>
            The Therapists agrees and acknowledges that while their term as a therapist on Dhunguru Platform they will not engage/provide/avail Music Therapy Sessions on any other online platform including but not limited to- United We Care, Medi-Buddy, JB Music Therapy, Amaha Health, Talk to Angel, Manastha, Rocket Health, Felicity etc. It is hereby mandated that Music Therapy services/sessions provided by Music Therapists on Dhunguru Platform are exclusive in nature and providing Music Therapy Sessions on other similar Online Platforms is prohibited. <br />
            In the event you wish to disassociate with the Platform at any time, you shall be liable to provide the Platform a notice of minimum 45 (forty-five) days.
        </li>
        <li className='my-3'>
            <strong> Session Scheduling.</strong>
            he term “Session” or “Music Therapy Session” herein refers to the prearranged meeting or appointment between the parties facilitated by the Platform. All sessions are expected to occur as per the agreed-upon schedule. Parties involved are obligated to adhere to the agreed-upon Session time. <br />
            A Client has the option to select from the list of available session times on a Therapist’s schedule. Alternatively, a Client may contact a Therapist to propose alternative availability, and the Therapist, at their discretion, may either accept or deny the Client’s availability request. A Client is permitted to schedule a session up to 6 hours prior to the designated time, contingent upon Therapist’s availability. In the event that the Therapist and Client mutually agree to convene with less notice (i.e., if the Therapist extends an invitation to meet for a session at a time less than 6 hours later), such practice is generally permitted by the Platform.<br />
            <strong>It is mandated for the therapists to take sessions only through the Zoom Meeting provided through Dhunguru Platform.</strong> The Therapists are strictly prohibited to take sessions on any other platform (E.g. Google Meet, Microsoft Teams Meeting, etc.). In situations where a Therapist conducts a Session on an alternative platform, compensation for that session will not be provided, and Dhunguru bears no responsibility for the conduct of the Clients during such therapy Sessions.
        </li>
        <li className='my-3'>
            <strong> Rescheduling/Cancellations. </strong>
            Sessions may be rescheduled, provided that Appropriate Notice is given. Appropriate Notice, in this context, is defined as a minimum of 2 hours prior to the scheduled Session time. In the event of rescheduling, you must utilize the Platform's internal messaging/calling service to promptly notify the respective Client. This notification should be made as soon as reasonably possible and, crucially, no less than 2 hours before the originally scheduled Session time. If a cancellation or rescheduling occurs within 2 hours of the scheduled Session time, it will be deemed a no-show. In the case of a no-show, the Therapist will be subject to penalty equivalent to the cost of the missed Session and will be required to provide a compensatory Session at a mutually agreeable new date and/or time.<br />
            Therapists who repeatedly cancel Sessions without Appropriate Notice may face consequences, including but not limited to limited access or denial of access to the Dhunguru platform. Such actions will be taken in alignment with the terms and conditions of the Platform.<br />
            Therapists encountering challenges in providing a Session due to a Client's inadequate hardware or inappropriate conduct are required to promptly contact Dhunguru, providing a detailed explanation of the circumstances. Resolution of such situations and discussions related to Refunds will be handled on a case-by-case basis. If inadequate Client hardware is believed to be the cause of the problem, Therapists must include a screenshot of their computer's bandwidth rating from <a href='https://speedtest.net'>https://speedtest.net</a> along with their explanation of the circumstances.
        </li>
        <li className='my-3'>
            <strong>No Show Policy. </strong>
            The Therapists agrees and acknowledges that their use of the Dhunguru platform is at their own discretion, subject to the terms and conditions outlined in this agreement. While Therapists use is discretionary, punctuality is deemed essential. This document outlines the terms and consequences related to punctuality and attendance at Music Therapy Sessions. Both parties agree that punctuality is of the essence concerning the Therapist's use of the Platform. Timely attendance at scheduled Sessions is crucial for the effective functioning of the Dhunguru platform. Being more than fifteen (15) minutes late or not attending a Session on one or more than one occasion is considered a material breach of this Agreement. Dhunguru, at its discretion, reserves the right to limit or permanently refuse access to the Platform for the Therapist if there is a repeated pattern of tardiness or non-attendance at Music Therapy Sessions without Appropriate Notice and will be handled on a case-by-case basis. Dhunguru will assess the circumstances surrounding repeated lateness or non-attendance before making a determination.
        </li>
        <li className='my-3'>
            <strong> Session Fee.</strong>
            The Therapist shall be paid a fixed sum of $500 (Rupees Five Hundred) for each Session conducted by the Therapist on the Platform (“Session Fee”). As per the GST Policy, 1% of the Session Fee shall be deducted as Tax Deducted at Source (TDS) by Dhunguru. This deduction is a legal requirement, and both parties hereby acknowledge and agree to comply with applicable tax regulations. Dhunguru will disburse the Therapist Payment Amount through Bank Transfer/Direct deposit through digital payments. The selected disbursement method will be communicated and agreed upon between Dhunguru and the Therapist. The amount disbursed to the Therapist, after the deduction of the Platform Fee and TDS, is hereby defined as the "Therapist Payment Amount." Dhunguru reserves the right to modify the Session Fee, provided that reasonable notice is given to the Therapists. Such modifications will be communicated in writing and will be effective as of the specified date.
        </li>
        <li className='my-3'>
            <strong> Payment.</strong>
            Payment by the Client is finalized at the time of purchasing one or multiple Sessions and is remitted to Dhunguru Music India Private Limited. This payment action establishes Platform credit, commensurate with the number of Therapy Sessions acquired, which the Client may utilize with the designated Therapist or other Therapists on the Platform. <br />
            Upon successful completion of a Music Therapy Session, the Therapists is obligated to acknowledge the Session from their dashboard on the Platform to receive payment. Each Session must be acknowledged within 14 days of completion; otherwise, the corresponding funds for that Session will be remitted to Dhunguru. Therapists receive email notifications for Music Therapy Sessions pending acknowledgment for more than one-week post Music Therapy Session completion. Nonetheless, the responsibility for Session acknowledgment lies solely with the Therapist. Sessions marked by 11:59 PM IST on Friday of a given week qualify for payment on the following Monday. It is imperative that Sessions are marked by the 11:59 PM IST deadline to qualify for the upcoming payment. The payment cycle is as follows:<br />
            <ul>
                <li>Payment for Music Therapy sessions acknowledged from 1st to 15th of a month are disbursed on 20th of the same month;</li>
                <li>Payment for Music Therapy sessions acknowledged from 16th to 30th/31st of a month are disbursed on 5th of the subsequent month.</li>
            </ul>
            <br />Therapists have the option to add their direct deposit information to their Dhunguru account for payment via direct deposit or Bank Transfer through digital payments. The Session price set by the Therapist may be subject to discounts, with Therapists retaining the ability to opt-out of such discounts at any time, including during profile setup. Sessions booked at a discounted price before the Therapist opts out will be considered purchased at the lower rate.
        </li>
        <li className='my-3'>
            <strong> Performance Review. </strong>
            The performance of the Therapists on the Dhunguru platform may be subject to review by Clients who have participated in Music Therapy Sessions facilitated by the Therapists through the Platform. Clients on the Dhunguru platform reserve the right to provide feedback and reviews on the performance of the Therapists based on their experience during Music Therapy Sessions. Participation in the review process is voluntary for Clients, and their feedback is intended to contribute to the overall assessment of the Therapist's performance. Both the Therapists and Clients are expected to maintain professionalism and civility in providing and receiving feedback, respectively. Any inappropriate or malicious conduct in the review process may be subject to appropriate action. The reviews received from Clients may be visible on the Therapist's profile on the Dhunguru platform. Such reviews can contribute to the overall assessment of the Therapist's performance by potential Clients. Reviews are encouraged to be fair, constructive, and focused on the experience. Dhunguru reserves the right to moderate and address any reviews that violate platform policies.
        </li>
        <li className='my-3'>
            <strong> Platform Rights.</strong>
            Dhunguru reserves the right to delete your Therapist account or deny you access to the Platform at any time for any reason including but not limited to:<br />
            <ul>
                <li>Non-maintenance of an up-to-date calendar within your Therapist Profile;</li>
                <li>No response within 48 hours to requests for Music Therapy Sessions made by Clients through the Platform; or</li>
                <li>In the event of an extended absence or unavailability due to being out of town or any other reason, it is required that you refrain from conspicuously noting this information on your Therapist Profile.</li>
            </ul>
        </li>
        <li className='my-3'>
            <strong> Exchange of Personal Information.</strong>
            In adherence to our Terms of Use (Music Therapy), Therapists are unequivocally prohibited from sharing any contact details with Clients. Sharing contact details with Clients is grounds for immediate blocking of access to the Platform for the offending Therapist. The Therapist's Profile will be permanently deleted from the Platform. In the event of a Therapist's breach of these Terms, including but not limited to this section addressing contact detail sharing, the Therapist shall be liable to pay the Platform damages amounting to Rupees Ten Thousand ($10,000/-) per Client registered with that Therapist. The financial liability outlined herein is not exhaustive, and Dhunguru reserves the right to pursue additional legal remedies available under applicable laws. This policy is rigorously enforced to safeguard the confidentiality and privacy of users/Clients on the Dhunguru platform.
        </li>
        <li className='my-3'>
            <strong>Engagement/ Interactions with other users.  </strong><br />
            <ol>
                <li>Users of the Platform, including Therapists, Clients, prospective Therapists, and prospective Clients, are individually and solely responsible for their interactions. Dhunguru explicitly disclaims any representations or warranties regarding the conduct of Platform users and shall not be held liable for any user's behaviour. Users agree to exercise reasonable precautions in all interactions, especially in offline or in-person meetings. Under no circumstances should financial information, such as credit card or bank account details, be shared with any other Platform user.</li>
                <li>Release. You hereby release Dhunguru from any and all claims, demands, damages (actual, consequential, nominal, punitive, or otherwise), equitable relief, and any other legal, equitable, and administrative remedy, of every kind and nature, whether known or unknown, suspected or unsuspected, disclosed or undisclosed, past, present, or future, arising out of or in any way connected with your interaction with other users of the Platform.</li>
                <li>DHUNGURU HAS NO DUTY TO MONITOR COMMUNICATIONS AND/OR INTERACTIONS TAKING PLACE ON OR THROUGH THE PLATFORM.</li>
                <li>SEXUALLY EXPLICIT MATERIAL IS NOT ALLOWED TO BE USED/TRANSMITTED THROUGH THE PLATFORM. </li>
            </ol>
        </li>
        <li className='my-3'>
            <strong> Initial Interview. </strong><br />
            <ol>
                <li>Before initiating your inaugural session on the Platform, it is imperative that you undergo one or more assessments or interviews through videoconference with Dhunguru (the "Interview"). These assessments are designed to ascertain and validate your technical competence and proficiency. It is a prerequisite to ensure that the quality and standard of services provided through the Platform meet the specified criteria. By participating in the Interview, you acknowledge and consent to the evaluation of your skills and capabilities as relevant to the intended scope of your engagement on the Platform. This process aims to uphold the integrity and reliability of the services offered, promoting a high standard of professional competence within the Dhunguru community.</li>
                <li>In its sole discretion, Dhunguru may choose to record the Initial Interview, and in such instances, Dhunguru will seek your explicit consent for the recording and subsequent utilization thereof in connection with the promotion of the Platform. Your consent to the recording encompasses the use of your name, likeness, and any performance exhibited during the Initial Interview for promotional purposes related to your Profile and/or the Platform. It is expressly clarified that Dhunguru holds no obligation to record, edit, or utilize any recording of the Therapists, or any portion thereof. Should the Therapist object to the recording, edited recording, or its use, it is incumbent upon the Therapist to promptly communicate such objection to Dhunguru. In the event of such objection, Dhunguru will discontinue the use of the recording within a reasonable period.</li>
            </ol>
        </li>
        <li className='my-3'>
            <strong> Music Therapy with Children.</strong>
            Dhunguru agrees and acknowledges the participation of children in Music Therapy Sessions through the Platform. In the event that your utilization of the Platform results in the exposure of any user under the age of 18 to sexually explicit material, and Dhunguru becomes aware of such conduct, we are obligated by law to report such instances to the appropriate authorities. Furthermore, any other conduct deemed as a violation of this Agreement may also be reported to the appropriate authorities at the discretion of Dhunguru on a case-by-case basis. This commitment to reporting is in alignment with our commitment to maintaining a safe and secure environment for all users, especially minors, utilizing the Platform.
        </li>
        <li className='my-3'>
            <strong>Platform Use.  </strong>
            The use of this Platform must be for lawful purposes only and in accordance with the Purpose of the Platform. You are prohibited from assisting others in engaging in any activities that are not permissible under the terms of the Agreement. You must refrain from (a) submitting or transmitting any content or material through the Platform or (b) engaging in any conduct that Dhunguru, at its sole discretion, deems: <br />
            <ul>
                <li>violates or infringes the rights of others, including but not limited to patent, trademark, trade secret, copyright, privacy, publicity, that any statement(s)/conduct(s) by you are endorsed by Dhunguru without our specific prior written consent;or other proprietary rights;</li>
                <li>is unlawful or objectionable at our sole discretion, including but not limited to materials or conduct(s) that is threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, obscene, invasive of another’s privacy, pornographic, sexually explicit, or tortiously interferes with another;</li>
                <li>forges email headers or disguises the origin of any communication;</li>
                <li>sends unsolicited bulk messages within the internal messaging service of the Platform;</li>
                <li>use of automated “bots” to access or compile information posted with respect to the Platform;</li>
                <li>victimizes, harasses, degrades, or intimidates any individual or group of individuals;</li>
                <li>impersonates any person, business, or entity;</li>
                <li>expresses or implies that any statements made by you are endorsed by Dhunguru without our specific prior written consent;</li>
                <li>that any statement(s)/conduct(s) by you are endorsed by Dhunguru without our specific prior written consent;</li>
                <li>“frames” or “mirrors” any part of the Platform, without Dhunguru’s prior written authorization. Additionally, You must not use meta tags or code or other devices containing any reference to Dhunguru or the Platform in order to direct any person to other web site for any purpose;</li>
                <li>modifies, adapts, sublicenses, translates, sells, reverse engineers, deciphers, decompiles or otherwise disassembles any portion of the Platform or any software used on or for the Platform, or cause others to do so;</li>
                <li>encourages any conduct that constitutes a criminal act or that gives rise to a civil liability;</li>
                <li>promotes any business other than the Platform or your services as offered through the Platform in accordance with the Purpose of the site; or</li>
                <li>violates the Agreement or otherwise interferes with the rights of others.</li><br /><br />
                You additionally agree not to post or transmit any image of another person without obtaining that person's explicit consent. Furthermore, you may not utilize the Platform in any manner that has the potential to damage, disable, overburden, or impair Dhunguru's servers, or disrupt the use and enjoyment of the Platform by any other party. Unauthorized attempts to access services or information for which you have not been granted access, including password mining or any other illicit processes, are strictly prohibited.<br />
                We may take any legal and technical remedies to prevent the violation of this Agreement and to otherwise enforce the Agreement at our sole discretion.
            </ul>
        </li>
        <li className='my-3'>
            <strong> Client Confidentiality.</strong>
            The Therapists agrees and acknowledges to maintain confidentiality of all, or any information provided to them in capacity of a Music Therapist. They are bound to maintain professional, ethical and fiduciary relationship with the Client and agree that all information shared during the course of therapy, including but not limited to:<br />
            <ul>
                <li>Verbal communications during therapy sessions</li>
                <li>Written communications such as notes</li>
                <li>Information about Client's personal life, background, and family</li>
                <li>Assessments, diagnoses, and treatment plans</li>
                <li>Progress notes and session recordings</li>
            </ul><br />
            will be held in strict confidence. Therapist will not disclose any such information to any third party without Client's express written consent.<br />
            The Therapist may disclose confidential information without Client's consent in the following limited circumstances:<br />
            <ul>
                <li><strong>Imminent Danger: </strong>Where Therapist has a reasonable belief that Client poses a serious and imminent threat of harm to self or others, Therapist may disclose information necessary to prevent such harm. This may include notifying law enforcement or emergency services.</li>
                <li><strong>Court Order:</strong>If Therapist is subpoenaed or otherwise ordered by a court to disclose confidential information, Therapist will attempt to notify Client and seek to protect the information as allowed by law.</li>
                <li><strong>Supervision and Consultation:</strong>Therapist may consult with a supervisor or other qualified professional about Client's case but will only disclose information necessary for supervision or consultation and will ensure the consultant maintains confidentiality.</li>
                <li><strong>Reporting Requirements:</strong>Therapist may be required to report certain information to authorities as mandated by law, such as suspected child abuse or neglect. In such cases, Therapist will disclose the minimum amount of information necessary to comply with the law.</li>
                <li><strong>Client Consent:</strong>Client may authorize Therapist to release specific information to designated individuals or entities. Such authorization may be provided in writing and may be revoked at any time by Client.</li>
            </ul><br />
            Dhunguru is not barred by this clause and will be privy to all communications and information between Clients and Therapists.
        </li>
        <li className='my-3'>
            <strong> Amendments to the Terms. </strong>
            Dhunguru retains the right to modify, amend, add, or remove sections of this Agreement at our sole discretion. Any such changes will be published on the Platform website and communicated to you through the email address you have provided to Dhunguru. It is crucial to maintain an up-to-date email address with Dhunguru for this purpose (Please note that your submission of personal information, including your email address, is governed by our Privacy Policy). We advise you to periodically review the Agreement for any updates. Your ongoing use of the Platform following the posting of changes signifies your binding acceptance of the modified terms.
        </li>
        <li className='my-3'>
            <strong> Disclaimer of Warranties and Limitation of Liability/Other Disclaimers.</strong>
            The platform and all information, content, materials, products, and services provided through this platform are offered by us on an "as is" and "as available" basis. Dhunguru makes no representations or warranties of any kind, express or implied, regarding the operation of the platform or the information, content, materials, products, or services made available through it.<br />
            To the maximum extent permissible under applicable law, Dhunguru disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. Dhunguru does not warrant that the platform and any information, content, materials, products, or services provided through it, are free of viruses or other harmful components. Dhunguru also does not warrant that the platform and the services offered will not utilize data causing you to exceed any applicable restrictions on data use imposed by your internet provider, mobile or otherwise. Except in jurisdictions where such provisions are restricted, under no legal theory, including but not limited to negligence, will Dhunguru or its affiliates, contractors, employees, agents, or third-party partners or suppliers be liable to you or any third person for damages of any kind arising from the use of the platform. This includes, without limitation, direct, indirect, incidental, consequential, special, and punitive damages related to any act or omission, such as lost profits, data, or use of the platform, even if Dhunguru has been advised of the possibility of such damages. In cases where applicable law does not allow the above release of liability, Dhunguru's liability will be limited to the extent permitted by law.<br />
            In no event shall Dhunguru or its affiliates, contractors, employees, agents, or third-party partners, licensors, or suppliers' total liability to you for any cause whatsoever (whether in contract, tort, warranty, or otherwise), and regardless of the form of the action, exceed the amount paid by you to Dhunguru for the use of the platform in the 12 months prior to the action giving rise to the liability.
        </li>
        <li className='my-3'>
            <strong> Operation and Content. </strong>
            Dhunguru disclaims responsibility for any incorrect or inaccurate content posted on or in connection with the Platform, whether caused by users, members, or any equipment or programming associated with or utilized in the Platform. Dhunguru assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communication line failure, theft, or destruction, or unauthorized access to, or alteration of, user communications. Additionally, Dhunguru is not accountable for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email, or players, due to technical problems or traffic congestion on the Internet or at any website, or a combination thereof. This includes any injury or damage to users or any other person's computer related to or resulting from participating or downloading materials in connection with the web and/or in connection with the Platform.
        </li>
        <li className='my-3'>
            <strong> Other website links.</strong>
            The Platform may contain links to other websites. Interactions that occur between you and the third-party sites are strictly between you and such sites, and are not the responsibility of Dhunguru. We are not responsible for examining or evaluating, and do not warrant the offerings of, any of these businesses or individuals or the content of their sites. Dhunguru does not assume any liability or responsibility for the actions or omissions, product, availability, or content of these outside resources. We encourage you to read those third-party sites’ applicable terms of use and privacy policies.
        </li>
        <li className='my-3'>
            <strong> Choice of Law. </strong>
            The Agreement shall be governed, construed, and enforced in accordance with the laws of India, including but not limited to:<br />
            <ol>
                <li>The Indian Contact,1872;</li>
                <li>The (Indian) Information Technology Act, 2000;</li>
                <li>The (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011; </li>
                <li>The (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 and</li>
                <li>The (Indian) Digital Data Protection Act, 2023.</li>
            </ol><br />
            The parties mutually agree that all actions and proceedings arising out of or related to this Agreement shall be exclusively litigated in the courts located in Gurugram. Each party hereby submits to the personal jurisdiction of such courts for the purposes of such actions or proceedings. Furthermore, each party agrees to irrevocably waive, to the fullest extent permitted by applicable law, any right to a jury trial in any legal proceeding arising out of or relating to this Agreement.
        </li>
        <li className='my-3'>
            <strong> Assignment.</strong>
            The Agreement, and any rights and licenses granted hereunder, must not be transferred or assigned by you to any other party, but may be assigned by Dhunguru without restriction. Any assignment made or attempted to be made in violation of this Agreement shall be void.
        </li>
        <li className='my-3'>
            <strong>Warranty.  </strong>
            You expressly warrant that you are over the age of eighteen (18) and have the capacity to enter into this Agreement, that any and all information (including without limitation Therapist Content) provided by you through the Platform is accurate and does not infringe the intellectual property rights of any other party and that your conduct and use of the Platform will conform with the terms of this Agreement.
        </li>
        <li className='my-3'>
            <strong> Indemnification. </strong>
            You agree to indemnify and hold Dhunguru and/or any of Dhunguru’s subsidiaries, members, affiliates, officers, agents, licensors, partners, and employees harmless from any loss, liability, claim, damages, obligations, or demand, including reasonable attorney’s fees, made by any third party due to or arising out of your use or interaction with the Platform including without limitation: (i) your violation or breach of this Agreement, (ii) your Therapist/Client Content, (iii) any intellectual property infringement or other infringement of the rights of third parties, or (iv) any breach of your representations and warranties outlined above. Dhunguru reserves the right, with no obligation, to assume, at your expense, the exclusive defense and control of any matter for which you are required to indemnify us. You agree to cooperate fully with our defense of these claims and not to settle any matter without the prior written consent of Dhunguru. We will make reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.
        </li>
        <li className='my-3'>
            <strong>Notice.  </strong>
            All notices given to you by the Company or by you to the Company shall be in writing and in the English language. Such notice shall be sent by e-mail or mailed by a prepaid internationally recognized courier service to the intended recipient at the address set out below, or any changed address that is notified by either Party:<br />
            <strong> Notice to the Company:</strong><br />
            Dhunguru Music India Pvt Ltd<br />
            WeWork<br />
            DLF Two Horizon Centre,<br />
            5th Floor, DLF Phase 5,<br />
            Gurugram, Haryana 122002<br />
            M: +918045683666<br />
            <strong>Notice to User:</strong><br />
            At the email address provided by you at the time of registration on the Website.
        </li>
        <li className='my-3'>
            <strong>Severability. </strong>
            If any provision of this Agreement is deemed unlawful, void, or unenforceable by a judge or tribunal body, then that provision shall be considered severable from this Agreement. The invalidity or unenforceability of any provision shall not affect the validity and enforceability of the remaining provisions.
        </li>
        <li className='my-3'>
            <strong> Claims.</strong>
            You and Dhunguru agree that any cause of action arising out of or related to the platform must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
        </li>
        <li className='my-3'>
            <strong> Grievance Redressal.</strong>
            If you have any questions or grievances regarding the Platform, or the contents thereof, you may reach out to ‘Aditi Raj’ the Company’s <strong>Data Protection Officer</strong> at <a href='support@dhungurus.com'>support@dhungurus.com</a> .
        </li>
        <li className='my-3'>
            <strong>Repeat Infringer.  </strong>
            Please note that Dhunguru will promptly terminate without notice any Therapist’s access to the Platform if that User or Therapist is determined by Dhunguru to be a “repeat infringer.” A “repeat infringer” is a Platform User who has been notified by Dhunguru of infringing activity violations more than twice and/or who has had their Therapist Content or any other user- submitted content removed from the Platform more than twice. In addition, Dhunguru accommodates and does not interfere with standard technical measures used by copyright owners to protect their materials.
        </li>
        <li className='my-3'>
            <strong> Blocking of IP Addresses/Users.</strong>
            In order to protect the integrity of the Platform, Dhunguru reserves the right at any time in its sole discretion to block users from certain IP addresses from accessing the Platform. Additionally, Dhunguru reserves the right to take appropriate measures to deny Platform access to any user who attempts to create a second Platform registration after being denied Platform access by Dhunguru.
        </li>
        <li className='my-3'>
            <strong> Use outside India.</strong>
            While the Platform may be accessible globally, the Company does not assert that the materials on the Platform are suitable or available for use in locations outside India, nor does it affirm compliance with the laws applicable in jurisdictions beyond India. Accessing the Platform from territories where the content or provision of Services is considered illegal is expressly prohibited. Users accessing the Platform from locations outside India do so at their own peril and are obligated to adhere to local laws applicable to them. Any offer for Services or information presented in connection with the Platform is null and void where prohibited.
        </li>
        <li className='my-3'>
            <strong> International Transactions. </strong>
            All amounts on the Platform are stated in USD, and exchange rates applicable to international transactions, which may change at any time, are determined by Dhunguru's third-party payment processors.
        </li>
        <li className='my-3'>
            <strong> Force Majeure. </strong>
            The Company bears no responsibility for the cessation, interruption, or delay in fulfilling its obligations herein, arising from events such as earthquakes, floods, fires, storms, pandemics, state-imposed lockdown restrictions, natural disasters, acts of God, wars, terrorism, armed conflicts, labour strikes, lockouts, or boycotts. The Company reserves the right, at its sole discretion and without prior notice, to discontinue the operation of the Platform at any time.
        </li>
        <li className='my-3'>
            <strong>No Waiver.  </strong>
            The Company's delay or failure to exercise or enforce any right or provision stipulated in this Agreement shall not be construed as a waiver of such right or provision.
        </li>
        <li className='my-3'>
            <strong>Termination. </strong>
            The Company reserves the right to terminate your access to the Platform without providing notice if, at its sole discretion, there is a reasonable belief that you have violated any terms and conditions outlined in this Agreement.
        </li>
        <li className='my-3'>
            <strong> Entire Agreement.</strong>
            This Agreement along with the Privacy Policy comprises the entire agreement between the Therapist and the Company with respect to the use of the Platform.
        </li>
        <li className='my-3'>
            <strong> Contact. </strong>
            If you have any query or comments with respect to the terms of this Agreement, drop a WhatsApp query or contact us at: +918045683666<br />
            or<br />
            <strong>Dhunguru Music India Pvt Ltd</strong><br />
            <strong>WeWork</strong> <br />
            <strong>DLF Two Horizon Centre,</strong><br />
            <strong>5th Floor, DLF Phase 5,</strong><br />
            <strong> Gurugram, Haryana 122002</strong><br /><br />
            <strong>THE PARTIES AGREE TO THE TERMS AND CONDITIONS SET FORTH ABOVE AS DEMONSTRATED BY THEIR SIGNATURES AS FOLLOWS:</strong>
            <strong>Client : </strong><br />
            Therapist: _____________________________________<br />
            Name: _____________________________________<br />
            Date: _____________________________________<br /><br />
            <strong>Dhunguru : </strong><br />
            Signed: _____________________________________<br />
            Name: _____________________________________<br />
            Date: _____________________________________<br />
        </li>
        <br /><br />
    </ol>
    <hr />
</>)



export default Doc