import React, { useState, useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {validateEmail, cleanPhone} from '../../../utilis/validation'
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import {loginSuccess} from '../../../store/actions/auth'
import {Store} from '../../../store'
import {FcGoogle} from 'react-icons/fc'
import {ImFacebook} from 'react-icons/im'
import axios from '../../../utilis/axios'
import cogoToast from "cogo-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Select from 'react-select'
import countryList from 'react-select-country-list'

const RegisterStudent = (props) => {

  
    const {dispatch} = useContext(Store);
    const [user, setUser] = useState({title: "Mr", firstName: "", lastName: "", email: "", phone: "",countryCode:"", password: "", tnc: false, tnc2: false, country: ""});
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(Array(7).fill(false));
    const [phoneInput,setPhoneInput] = useState();
    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = countryValue => {
      console.log(countryValue);
      setUser((prev) => ({
        ...prev, 
        country: countryValue.label,
      }));
    }

    console.log(user,'user')
    const validate = () => {
      let new_errors = Array(7).fill(false)
      if(user.firstName.length < 3) new_errors[0] = true;
      if(!validateEmail(user.email)) new_errors[1] = true;
      if(!user.phone) new_errors[2] = true;
      if(user.password.length < 8) new_errors[3] = true;
      if(!user.tnc) new_errors[4] = true;
      // if(!user.tnc2) new_errors[5] = true;
      if(user.country.length<2) new_errors[6] = true;
      setErrors(new_errors)
      if(new_errors.includes(true)) return false;
      else return true;
    }

    useEffect(() => {
      setErrors(Array(7).fill(false))
    }, [user])


  const registerStudent = async () => {
    if(validate()){
      try{
          setLoading(true);
          const res = await axios.post('/api/auth/register/student', {...user, email: user.email.toLowerCase(), phone: cleanPhone(user.phone)})
          if(res.data === 'already_exists'){
              cogoToast.error('User already exists, please login')
          } else{
              loginSuccess(res.data, dispatch)
              if(props.location && props.location.query && props.location.query.message) {
                props.history.push({
                  pathname: props.location.query.pathname,
                  search: props.location.query.search,
                  query: { instructor: props.location.query.instructor }
                })
              } else props.history.push('/')
            }
            setLoading(false)
          } catch(err){
            setLoading(false)
          }
        }
      }
        
  const loginGoogle = () => {
    if(!user.tnc){
      const newErrors = Array(6).fill(false)
      if(!user.tnc) newErrors[4] = true;
      // if(!user.tnc2) newErrors[5] = true;
      setErrors(newErrors)
    }else{
      window.open(`${process.env.REACT_APP_WEBSITE}/api/auth/google`, "_self");
    }
  }

  const loginFacebook = () => {
    if(!user.tnc){
      const newErrors = Array(6).fill(false)
      if(!user.tnc) newErrors[4] = true;
      // if(!user.tnc2) newErrors[5] = true;
      setErrors(newErrors)
    }else{
      window.open(`${process.env.REACT_APP_WEBSITE}/api/auth/facebook`, "_self");
    }
  }


  return (
    <div
      className="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
      style={{ minHeight: "100vh" }}
    >
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 align-self-center px-xl-6 my-5 ">
            <h1 className="display-4 text-center mb-3">Register with us</h1>
            <p className="text-muted text-center mb-5">
              Free access to our dashboard.
            </p>
            <form className="mx-xl-6">
              <div class="row no-gutters">
                <div class="col-12 col-lg-2">
                  <div className="form-group">
                    <label>Title</label>
                    <select
                      className={`form-control `}
                      value={user.title}
                      onChange={(e) =>
                        setUser((prev) => ({ ...prev, title: e.target.value }))
                      }
                    >
                      <option value="Mr">Mr</option>
                      <option value="Ms">Ms</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Dr">Dr</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-lg-5">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className={`form-control ${errors[0] && "is-invalid"} `}
                      value={user.firstName}
                      onChange={(e) =>
                        setUser((prev) => ({
                          ...prev,
                          firstName: e.target.value,
                        }))
                      }
                    />
                    <div class="invalid-feedback">
                      Name should be min 3 characters
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-5">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      value={user.lastName}
                      onChange={(e) =>
                        setUser((prev) => ({
                          ...prev,
                          lastName: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  class={`form-control ${errors[1] && "is-invalid"} `}
                  value={user.email}
                  onChange={(e) =>
                    setUser((prev) => ({ ...prev, email: e.target.value }))
                  }
                  autoComplete="email"
                />
                <div class="invalid-feedback">Enter a right email</div>
              </div>

              <div className="form-group">
                <label>Phone</label>
                {/* <input
                  className={`form-control ${errors[2] && "is-invalid"} `}
                  value={user.phone}
                  onChange={(e) =>
                    setUser((prev) => ({ ...prev, phone: e.target.value }))
                  }
                  autoComplete="new-password"
                /> */}
                <PhoneInput
                  country={"in"}
                  value={phoneInput}
                  onChange={(e,data) =>{console.log(data);
                    const reducedPhone = e.replace(data.dialCode, "");
                    console.log(reducedPhone,'reducedPhone');
                    setPhoneInput(e)
                    setUser((prev) => ({ ...prev, phone: reducedPhone,countryCode: data?.dialCode}))}}
                  containerStyle={{ width: "100%" }}
                  inputClass="form-control"
                  containerClass={`${errors[2] && "is-invalid"}`}
                  inputStyle={{
                    minWidth: "100%",
                    borderColor: `${errors[2] ? "red":""} `,
                  }}
                />
                <div class="invalid-feedback">Phone is invalid</div>
              </div>
              <div className="mb-4">
                <label>Country</label>
                <Select options={options} onChange={changeHandler} class={`${errors[6] && "is-invalid"}`} />
                {errors[6] && <div style={{color:'#e63757', fontSize:'0.8125rem'}}>Select a valid country</div>}
              </div>

              <div className="form-group">
                <label>Password</label>
                <div className="input-group input-group-merge">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control form-control-append ${
                      errors[3] && "is-invalid"
                    } `}
                    placeholder="Password"
                    value={user.password}
                    onChange={(e) =>
                      setUser((prev) => ({ ...prev, password: e.target.value }))
                    }
                    autoComplete="new-password"
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? (
                        <AiOutlineEye className="pointer" size={15} />
                      ) : (
                        <AiOutlineEyeInvisible className="pointer" size={15} />
                      )}
                    </span>
                  </div>
                  <div className="invalid-feedback">
                    Please enter a password
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mt-4">
                <div className="mr-2">
                  <input
                    className=""
                    type="checkbox"
                    checked={user.tnc}
                    onClick={() =>
                      setUser((prev) => ({ ...prev, tnc: !prev.tnc }))
                    }
                  />
                </div>
                <div className="">
                  I agree to the site's <Link to="/legal">terms of use</Link>{" "}
                  and <Link to="/refund-policy">refund policy</Link>.
                </div>
              </div>
              {/* <div className="d-flex align-items-center mt-3 mb-4">
                <div className="mr-2">
                  <input
                    className=""
                    type="checkbox"
                    checked={user.tnc2}
                    onClick={() =>
                      setUser((prev) => ({ ...prev, tnc2: !prev.tnc2 }))
                    }
                  />
                </div>
                <div className="">
                  I allow Dhunguru to record and save all my sessions conducted
                  via Dhunguru for business purposes.
                </div>
              </div> */}
              {(errors[4]) && (
                <div class="invalid-feedback d-block">
                  Please go through our terms and conditions first
                </div>
              )}

              <div className="d-flex align-items-center justify-content-between">
                <div
                  onClick={loginGoogle}
                  className="btn btn-block btn-outline-secondary mt-2 mb-3 d-inline-flex align-items-center justify-content-center mr-3"
                >
                  <FcGoogle class="h2 mb-0 mr-2" /> Sign up with google
                </div>
                <div
                  onClick={loginFacebook}
                  className="btn btn-block btn-outline-secondary mt-2 mb-3 d-inline-flex align-items-center justify-content-center"
                >
                  <ImFacebook
                    class="h2 mb-0 mr-2"
                    style={{ color: "#3B5998" }}
                  />{" "}
                  Sign up with Facebook
                </div>
              </div>

              <div
                className="btn btn-lg btn-block btn-primary mt-3 mb-3"
                onClick={() => registerStudent()}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Register
              </div>

              <div className="text-center">
                <small className="text-muted text-center">
                  Already have an account <Link to="/login">Login</Link>.
                </small>
              </div>

              <div className="text-center">
                <small className="text-muted text-center"></small>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-none d-lg-block align-self-center">
            <div class="text-center">
              <img
                src="https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/login.svg"
                alt="..."
                class="img-fluid"
                style={{ height: "400px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterStudent;
