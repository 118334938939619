import {useState, useEffect} from 'react'
import Select from 'react-select'
import instructor_content from '../../../../../content/instructor_content.json'
import axios from 'axios'
import cogoToast from 'cogo-toast'




const BioData = ({profile, setProfile}) => {

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(Array(6).fill(false));
    
    const validate = () => {
        const new_errors = Array(6).fill(false)
        if(profile.languages.length === 0) new_errors[1] = true;
        if(!profile.bio || (profile.bio).toString().length < 50) new_errors[2] = true;
        if(!profile.numYearExperience || profile.numYearExperience < 0) new_errors[4] = true;
        setErrors(new_errors)
        if(new_errors.includes(true)) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            return false;
        }
        else return true;
    }


    useEffect(() => {
        setErrors(Array(6).fill(false))
    }, [profile.bio, profile.languages, profile.numYearExperience])

    
    const saveProfile = async (e) => {
        e.preventDefault()
        if(!validate()) return;
        try{
            setLoading(true);
            const profile1 = {
                bio: profile.bio,
                languages: profile.languages,
                numYearExperience: profile.numYearExperience
            }
            await axios.put(`/api/admin/instructor/profile/${profile.user._id}`, {profile: profile1})
            cogoToast.success(instructor_content.profile.toast.success)

            setLoading(false);

        } catch(err){
            console.log(err)
            setLoading(false)
            throw err;
        }
    }
    
    return (
        <form class = "">
            <div className="row justify-content-between align-items-center ">
                <div className="col">
                    <div className="row align-items-center">
                        <div className="col-auto pr-5">
                            <div className="avatar avatar-xxl">
                            <img className="avatar-img rounded-circle"  src={profile.avatar} alt="..." />
                            </div>
                        </div>
                        <div className="col ml-n2">
                            <div className = 'row mt-5'>
                                <div className = 'col'>
                                    <div className = 'form-group '>
                                        <label class= 'bold mb-1'>Email</label>
                                        <div class = 'mb-3'>{profile.email} </div>
                                    </div>
                                </div>
                                <div className = 'col'>
                                    <div className = 'form-group '>
                                        <label class= 'bold mb-1'>Phone Number</label>
                                        <div class = 'mb-3'>{profile.phone} </div>
                                    </div>
                                </div>
                                <div class = 'col'>
                                    <div className = 'form-group '>
                                        <label class= 'bold mb-1'>Gender</label>
                                        <div class = 'mb-3'>{profile.gender} </div>
                                    </div>
                                </div>
                            </div>
                            <div class = 'row '>
                                <div className = 'col-4'>
                                    <div className = 'form-group '>
                                        <label class= 'bold mb-1'>Instrument to teach</label>
                                        <div class = 'mb-3'>{profile.user.instrumentToTeach} </div>
                                    </div>
                                </div>
                                <div class = 'col'>
                                    <div className = 'form-group '>
                                        <label class= 'bold mb-1'>Teaches Kids</label>
                                        <div class = 'mb-3'>{profile.teachesChildren? 'Yes' : 'No'} </div>
                                    </div>
                                </div>
                                <div class = 'col'>
                                    <div className = 'form-group '>
                                        <label class= 'bold mb-1'>Mapping</label><br/>
                                        {profile.isBackgroundChecked && <span class = 'badge badge-primary mr-2'>Background Approved</span>}
                                        {profile.isStarTeacher && <span class = 'badge badge-primary'>Star Teacher</span>}
                                        {profile.isBulkDiscount && <span class = 'badge badge-primary'>Bulk Discount Available</span>}
                                    </div>
                                </div>
                            </div>
                            <div className = 'row '>
                                <div className = 'col-4'>
                                    <div className = 'form-group '>
                                        <label class= 'bold mb-1'>Experience (Num years)</label>
                                        <input 
                                            className = {errors[4]  ? 'form-control is-invalid' : 'form-control'}
                                            value = {profile.numYearExperience} 
                                            placeholder = "How many years of experience do you have?"
                                            onChange = {e => {setProfile(prev => ({...prev, numYearExperience: e.target.value}))}}
                                            type = "number"
                                        />
                                    </div>
                                </div>
                                <div class = 'col'>
                                    <div className = 'form-group '>
                                        <label class= 'bold mb-1'>Languages</label>
                                        <Select 
                                            options = {langOptions} 
                                            isMulti
                                            value = {langOptions.filter(item => profile.languages ? profile.languages.includes(item.value) : false)}
                                            onChange = {e => {setErrors([false, false]); setProfile(prev => ({...prev, languages: e.map(item => item.value)}))}}
                                        />
                                        {errors[1] && <div class = 'invalid-feedback d-block'>Please select at least one language</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div> 

           
           
            <div className = 'form-group mt-3'>
                <label class= 'bold mb-1'>Instructor bio</label>
                <textarea 
                    className = {errors[0]  ? 'form-control is-invalid' : 'form-control'}
                    style = {{minHeight: '200px'}} 
                    value = {profile.bio} 
                    onChange = {e => {setErrors(prev => {prev[0] = false; return prev}); setProfile(prev => ({...prev, bio: e.target.value}))}}
                /> 
                {errors[2] && <div class = 'invalid-feedback d-block'>{instructor_content.profile.basics.bio.error}</div>}
            </div>

            <button disabled = {loading} className = 'btn btn-block my-5 btn-primary px-6' onClick = {(e) => saveProfile(e)}>
                {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                Save
            </button>
        </form>
    )
}


export default BioData;

const langOptions = [
    { value: 'English', label: 'English' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Mandarin', label: 'Mandarin' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Russian', label: 'Russian' },
    { value: 'Portuguese', label: 'Portuguese' },
    { value: 'Arabic', label: 'Arabic' },
    { value: 'Japanese', label: 'Japanese' },
    { value: 'Korean', label: 'Korean' },
    { value: 'Italian', label: 'Italian' },
    { value: 'Turkish', label: 'Turkish' },
    { value: 'Dutch', label: 'Dutch' },
    { value: 'Swedish', label: 'Swedish' },
    { value: 'Thai', label: 'Thai' },
    { value: 'Vietnamese', label: 'Vietnamese' },
    { value: 'Greek', label: 'Greek' },
    { value: 'Hebrew', label: 'Hebrew' },
    { value: 'Malay', label: 'Malay' },
    { value: 'Indonesian', label: 'Indonesian' },
    { value: 'Bengali', label: 'Bengali' },
    { value: 'Marathi', label: 'Marathi' },
    { value: 'Telugu', label: 'Telugu' },
    { value: 'Tamil', label: 'Tamil' },
    { value: 'Punjabi', label: 'Punjabi' },
    { value: 'Gujarati', label: 'Gujarati' },
    { value: 'Kannada', label: 'Kannada' },
    { value: 'Urdu', label: 'Urdu' },
    { value: 'Odia', label: 'Odia' }
];