import React, { useState, useContext, useEffect } from 'react'
import DashboardLayout from '../index'
import Table from '../../../../components/Table'
import axios from '../../../../utilis/axios'
import moment from 'moment'
import { BsChatSquareDotsFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { Store } from '../../../../store'
import Skeleton from 'react-loading-skeleton'
import Resources from './Resources'
import { saveAllStudents } from '../../../../store/actions/instructor'


const Students = (props) => {

    const { state, dispatch } = useContext(Store)
    const [tab, setTab] = useState('enrolled');
    const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const [enrollments, setEnrollments] = useState();
    const [selected, setSelected] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        if (state.instructor.students && state.instructor.students.length > 0) {
            setEnrollments(state.instructor.students);
            formatData(state.instructor.students)
            fetchEnrollments();
        } else fetchEnrollments();
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        setShow(true);
    }, [selected])

    useEffect(() => {
        if (!show) setSelected(null)
    }, [show])

    const fetchEnrollments = async () => {
        try {
            const res = await axios.get('/api/instructor/enrollments');
            // console.log(res.data)
            setEnrollments(res.data.enrollments);
            saveAllStudents(res.data.enrollments, dispatch)
            formatData(res.data.enrollments)
        } catch (err) {
            console.log(err)
        }
    }

    const formatData = (data1) => {
        const newData = (data1.map(item => {
            if (item.type === 'demo') {
                return {
                    enrollmentId: item.enrollmentId,
                    name: { value: item.student.firstName + ' ' + item.student.lastName, label: <NameWithAvatar name={item.student.firstName + ' ' + item.student.lastName} avatar={item.student.avatar} /> },
                    course_name: item.course?.instrument,
                    billing_status: 'Demo Account',
                    chat: { label: <Link to={(item.type=='demo' || item.classes_left==0)?'#':`/instructor/messages/?user=${item.student._id}`}><BsChatSquareDotsFill class='h3 mb-0' style={{color:"gray"}} /></Link>, value: '' },
                    type: 'demo',
                    enrolled_date: moment(item.createdAt).format('DD MMMM YY')
                }
            } else {
                return {
                    enrollmentId: item.enrollmentId,
                    name: { value: item.student.firstName + ' ' + item.student.lastName, label: <NameWithAvatar name={item.student.firstName + ' ' + item.student.lastName} avatar={item.student.avatar} /> },
                    course_name: item.course?.instrument,
                    billing_status: '$' + item.payment_details.filter(item => (item.status === "booked" || item.status === "admin-created" || item.status === "razorpay-created")).reduce((a, b) => a + parseInt(b.total_price), 0).toLocaleString(),
                    classes_left: { label: <div class='badge badge-primary p text-uppercase'>{item.classes_left + '/' + item.num_classes}</div>, value: item.classes_left + '/' + item.num_classes },
                    enrolledDate: moment(item.enrollment_date).format('DD MMMM YY'),
                    type: 'paid',
                    classes_scheduled: item.classes_scheduled,
                    chat: { label: <Link  to={(item.type=='demo' || item.classes_left==0)?'#':`/instructor/messages/?user=${item.student._id}`} className='disabled-link'><BsChatSquareDotsFill class='h3 mb-0' style={{color:`${item.classes_left==0 ? "gray":""}`}} /></Link>, value: '' },
                    share_resources: { label: <div class='btn btn-sm btn-primary' onClick={() => setSelected(item._id)}>Share</div>, value: '' },
                }
            }
        }))
        console.log("New Data : ",newData);
        setData(newData)
    }

    // console.log("Data: ",data);

    return (
        <DashboardLayout
            showTitle={false}
            {...props}
        >
            <div className='mt-2'>
                <div className='row justify-content-center'>

                    <div className='col-12 col-xl-11'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 class="header-pretitle">Overview</h6>
                                        <h1 class="header-title">Your members</h1>
                                    </div>
                                </div>
                                <AccountNav tab={tab} setTab={setTab} />

                            </div>
                        </div>
                        {(tab === 'enrolled' && data) &&
                            <Table
                                data={data.filter(item => item.type === 'paid')}
                                isSelectable={false}
                                headers={enrolledHeaders}
                                isSearchable={true}
                                handleRowClick={(id) => console.log(id)}
                                isHeader={true}
                                isFooter={true}
                                isSortable={true}
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                filters={['course_name', 'name']}

                            />}
                        {(tab === 'demo' && data) &&
                            <Table
                                data={data.filter(item => item.type === 'demo')}
                                isSelectable={false}
                                headers={demoHeaders}
                                isSearchable={true}
                                handleRowClick={(id) => console.log(id)}
                                isHeader={true}
                                isFooter={true}
                                isSortable={true}
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                filters={[]}
                            />}
                        {!data && <LoadingSkeleton />}
                    </div>
                </div>
            </div>
            {(enrollments && selected) && <Resources
                show={show}
                setShow={setShow}
                enrollment={enrollments.filter(item => item._id === selected)[0]}
            />}

        </DashboardLayout>
    )
}

export default Students



const AccountNav = ({ tab, setTab }) => {
    return (
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'enrolled' && 'active'}`} onClick={() => setTab('enrolled')}>Enrolled Members</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'demo' && 'active'}`} onClick={() => setTab('demo')}>Assessment Members</div>
                    </li>
                </ul>
            </div>
        </div>
    )
}


const enrolledHeaders = [
    { value: 'enrollmentId', active: true, label: 'Membership ID' },
    { value: 'name', active: true, label: 'Name' },
    { value: 'course_name', active: true, label: 'Membership' },
    { value: 'billing_status', active: true, label: 'Amount Paid' },
    { value: 'classes_left', active: true, label: 'Sessions Left' },
    { value: 'enrolledDate', active: true, label: 'Enrollment Date' },
    { value: 'chat', active: true, label: 'Chats' },
    { value: 'share_resources', active: true, label: 'Resources' },
]



const demoHeaders = [
    { value: 'enrollmentId', active: true, label: 'Membership ID' },
    { value: 'name', active: true, label: 'Name' },
    { value: 'course_name', active: true, label: 'Membership' },
    { value: 'enrolled_date', active: true, label: 'Enrollement Date' },
    { value: 'chat', active: true, label: 'Chats' },
]


const LoadingSkeleton = () => {
    return (
        <Skeleton height={600} />
    )
}


const NameWithAvatar = ({ name, avatar }) => {
    return (
        <>
            <div className="avatar avatar-xs align-middle mr-2">
                <img class="avatar-img rounded-circle" src={avatar} alt="..." />
            </div>
            <span class="item-name text-reset">{name}</span>
        </>
    )
}


