import React, {useState, useEffect, useContext} from 'react'
import Form from './Form'
import Schedule from './Schedule'
import Complete from './Complete'
import axios from '../../../utilis/axios'
import { Store } from '../../../store'
import { loginSuccess } from '../../../store/actions/auth';
import { loginUser, getUser } from '../../../services/auth';
import BeatSpinner from '../../../components/Spinners/BeatSpinner'
import { FiChevronRight } from 'react-icons/fi';
import moment from 'moment'
import { useHistory } from "react-router-dom";
import cogoToast from 'cogo-toast'
import {Link} from 'react-router-dom';
import { saveRequests } from '../../../store/actions/student'
import content from '../../../content/landingPage.json'
import logo from '../../../assets/MelodyMentorsLogo.svg'


const FreeSession = (props) => {

    const id = props.match.params.id;
    let history = useHistory();
    const {state, dispatch} = useContext(Store);
    const [step, setStep] = useState('form')
    const [loading, setLoading] = useState(false);
    const [mainLoading, setMainLoading] = useState(true)
    const [schedule, setSchedule] = useState({date: '', time: ''})
    const [course, setCourse] = useState()
    const [isExistingUser, setIsExistingUser] = useState(false)
    const [user, setUser] = useState({email: '', firstName: '', lastName: '', password: '', phone : ''})

    useEffect(() => {
        checkIfLoggedIn();
        // eslint-disable-next-line
    }, [])

    const checkIfLoggedIn = async () => {
        try{
            if(state.user.isLoggedIn && state.user.data.role === 'student'){
                if(state.user.data.hasTakenDemo){
                    history.goBack()
                    cogoToast.error(content.bookFreeSession.toasts.already_registered)
                }
                setStep('schedule')
                setMainLoading(false);
            } else{

                const res = await getUser();
                if(res.hasTakenDemo){
                    history.goBack()
                    cogoToast.error(content.bookFreeSession.toasts.already_registered)
                }
                else if(res.role === 'student'){
                    loginSuccess(res, dispatch)
                    setMainLoading(false)
                    setStep('schedule')
                } else{
                    setMainLoading(false);
                }
            }

        } catch(err){
            setMainLoading(false)
            console.log(err)
        }
    }
    
    
    useEffect(() => {
        fetchCourse()
        // eslint-disable-next-line
    }, [])
    
    const fetchCourse = async () => {
        try{
            const res = await axios.get(`/api/student/lesson/${id}`)
            setCourse(res.data)
        } catch(err){
            console.log(err)
        }
    }

    const login = async () => {
        try{
            setLoading(true);
            if(isExistingUser){
                await signIn()
            } else{
                await registerNewUser()
            }
            setLoading(false)
        } catch(err){
            console.log(err)
        }
    }

    const signIn = async () => {
        try{
            const fetchedUser = await loginUser(user);
            if(fetchedUser.role === 'student'){
                loginSuccess(fetchedUser, dispatch)
                if(fetchedUser.hasTakenDemo){
                    history.goBack()
                    cogoToast.error(content.bookFreeSession.toasts.already_registered)
                } else setStep('schedule')
            } else{
                cogoToast.error(content.bookFreeSession.toasts.not_a_student)
            }
        } catch(err){
            cogoToast.error("Some error occured while loggin in.")
            console.log(err)
            setLoading(false)
        }
    }

    const registerNewUser = async () => {
        try{
            const res = await axios.post('/api/auth/register/student', user)
            if(res.data === 'already_exists'){
                cogoToast.error(content.bookFreeSession.toasts.user_already_exists)
            } else{
                loginSuccess(res.data, dispatch)
                setStep('schedule')
            }
        } catch(err){
            //Throw server error for registration
            console.log(err)
            setLoading(false)
        }
    }

    const scheduleFreeSession = async () => {
        try{
            setLoading(true);
            const scheduleISODate = moment(schedule.date + "-" + schedule.time.label.slice(0, 8), "DD/MM/YYYY-hh:mm A")
            const res = await axios.post(`/api/student/enrollment/demo/`, {course, schedule: scheduleISODate})
            
            const newUser = {...state.user.data};
            newUser.hasTakenDemo = true;
            loginSuccess(newUser, dispatch)
            
            if(state.student.requests && state.student.requests.length > 0){
                const newRequests = {...state.student.requests}
                newRequests.push(res.data);
                saveRequests(newRequests, dispatch)
            }

            setStep('complete')
            setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }


    return (
        <div>
            {(course && !mainLoading) ? <div>
                <Steps />
                {step === 'form' && (
                    <Form 
                        setStep = {setStep} 
                        user = {user} 
                        setUser = {setUser} 
                        login = {login}
                        isExistingUser = {isExistingUser} 
                        setIsExistingUser = {setIsExistingUser}
                        loading = {loading}
                    />
                )}
                {step === 'schedule' && (
                    <Schedule 
                        setStep = {setStep} 
                        course = {course}
                        schedule = {schedule} 
                        setSchedule = {setSchedule}
                        scheduleFreeSession = {scheduleFreeSession}
                        loading = {loading}
                    />
                )}
                {step === 'complete' && <Complete {...props}/>}
            </div> : <BeatSpinner />}
        </div>
    )
}


export default FreeSession;



const Steps = () => {
    return (
    <div className="BookHeader">
        <div className="BookHeader_Container">
            <Link to = "/">
                <img alt="" class="BookHeader_Logo" src="https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/logo.png" />
            </Link>            <ul class="BookHeader_Progress align-items-center pl-4 pl-lg-0">
                <li class="">
                    <div>        
                        <span class="BookHeader_Progress_Number">1</span>
                        <span class="BookHeader_Progress_Label">Your Details</span>
                    </div>      
                </li>
                <li>
                    <span class="text-dark small px-2 px-lg-5">
                        <FiChevronRight />
                    </span>
                </li>
                <li class="">
                    <div>        
                        <span class="BookHeader_Progress_Number">2</span>
                        <span class="BookHeader_Progress_Label">Schedule Your Session</span>
                    </div>      
                </li>
            </ul>
        </div>
    </div>
    )
}